.components {
  h1 {
    border-bottom: 2px solid white;
    padding-bottom: 5px;
    display: inline-block; }
  p.component-title {
    margin: 20px 0 10px 0; }
  .component-section {
    margin: 40px 0; }
  .inline-spacer {
    margin: 0 10px; } }

// raise
.raise {
  -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1); }
.t-raise {
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2); }

// raise button
.button-raise {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
  -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1); }

.button-raise:hover {
  -ms-transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
  -webkit-box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.16)!important;
  -moz-box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.16)!important;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.16)!important; }

// social button
.social-button {
  border-radius: 5px;
  background-color: #2c42ee;
  color: white;
  height: 50px;
  padding: 7px 25px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  p {
    display: inline-block;
    margin-right: 10px;
    font-family: GT-Medium, sans-serif; } }

// download
.download-button {
  padding-left: 10px;
  padding-right: 10px; }

// like
.like-button.liked {
  background-color: #14239a; }

// purchase button
.purchase-button {
  border-radius: 5px;
  background-color: transparent;
  color: white;
  height: 50px;
  padding: 3px 20px 0 25px;
  text-align: center;
  border: 2px solid white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: GT-Medium, sans-serif;
  letter-spacing: 0.15rem;
  text-shadow: 0px 6px 10px rgba(0, 0, 0, 0.3); }

// info button
.info-button {
  background-color: #14239a;
  font-family: GT-Medium, sans-serif;
  font-size: 0.8rem;
  padding: 6px 10px;
  cursor: default;
  border-radius: 5px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  p {
    display: inline-block; }
  img {
    height: 18px;
    margin-right: 5px; } }

// step
.step-button {
  font-family: GT-Bold;
  background-color: rgba(0, 0, 0, 0.6); }

// blog button
.blog-button {
  border-radius: 5px;
  background-color: rgba(20, 35, 154, 0.20);
  color: white;
  height: 45px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 20px;
  padding-right: 20px;
  min-width: 100px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 0.1rem;
  img {
    margin-right: -3px;
    margin-left: 3px; }
  p {
    font-family: GT-Medium, sans-serif;
    margin-top: 2px; }
  img, p {
    opacity: 0.6;
    pointer-events: none; } }
.blog-button.active-filter {
  background-color: rgba(20, 35, 154, 0.60);
  img, p {
    opacity: 1; } }

// input
.input {
  height: 50px;
  border-radius: 5px;
  border: 0;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  font-family: GT-Medium, sans-serif;
  font-size: 1.2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 15px 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }
.input:focus {
  -ms-transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px); }
.input::placeholder {
  color: #4758da; }
.input.input-center {
  text-align: center; }
.input.input-month, .input.input-day {
  width: 70px; }
.input.input-year {
  width: 90px; }

// option
.option {
  height: 50px;
  border-radius: 5px;
  border: 0;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  font-family: GT-Medium, sans-serif;
  font-size: 1.2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 15px 0;
  min-width: 60px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }
.option:hover {
  -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1); }
.option.active-option {
  background-color: rgba(0, 0, 0, 0.6); }

// textarea
.textarea {
  height: 150px;
  border-radius: 5px;
  border: 0;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  font-size: 1.2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  width: 100%;
  font-family: Poppins, GT-Regular, sans-serif;
  resize: none; }
.textarea::placeholder {
  color: #4758da;
  font-family: Poppins, GT-Regular, sans-serif; }

// routine tags
.routine-tags {
  margin: 10px 0;
  p {
    margin-right: 10px;
    font-family: Poppins, sans-serif;
    background-color: #4458f9;
    padding: 4px 10px 3px 0;
    border-radius: 5px;
    font-size: 0.8rem;
    height: 30px;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    color: #deddff; }
  p:before {
    content: '#';
    padding: 4px 8px;
    color: #8592fb; } }

// activity blocks
.activity-block, .notif-block {
  margin: 30px 0;
  p {
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 1.2rem;
    span {
      font-style: italic;
      font-weight: 500; }
    a {
      font-weight: 700;
      display: inline-block;
      position: relative;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease; }
    a:hover {
      transform: translateX(5px); }
    a:after {
      content: '';
      width: 100%;
      height: 3px;
      background: #5c8aff;
      left: 0;
      bottom: -2px;
      position: absolute;
      border-radius: 500px; } } }

// items-block
.items-block {
  margin-bottom: 15px;
  .program {
    p {
      font-family: GT-Bold, sans-serif;
      text-transform: uppercase;
      font-size: 0.9rem;
      letter-spacing: -0.4px;
      text-shadow: 0px 2px 8px rgb(0, 0, 0); }
    small {
      display: block;
      margin-top: 10px;
      font-family: GT-Bold, sans-serif;
      text-shadow: 0px 1px 8px rgb(0, 0, 0);
      text-transform: uppercase;
      font-size: 0.75rem; }
    .hypertrophy {
      background: rgba(166,102,60,1);
      background: -moz-linear-gradient(top, rgba(166,102,60,1) 0%, rgba(194,79,64,1) 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(166,102,60,1)), color-stop(100%, rgba(194,79,64,1)));
      background: -webkit-linear-gradient(top, rgba(166,102,60,1) 0%, rgba(194,79,64,1) 100%);
      background: -o-linear-gradient(top, rgba(166,102,60,1) 0%, rgba(194,79,64,1) 100%);
      background: -ms-linear-gradient(top, rgba(166,102,60,1) 0%, rgba(194,79,64,1) 100%);
      background: linear-gradient(to bottom, rgba(166,102,60,1) 0%, rgba(194,79,64,1) 100%);
      border-radius: 5px;
      width: 100%;
      padding: 23px 25px 20px; }
    .endurance {
      background: #3c8ea6;
      background: -moz-linear-gradient(top, rgba(166,102,60,1) 0%, #4068c2 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, #3c8ea6), color-stop(100%, #4068c2));
      background: -webkit-linear-gradient(top, #3c8ea6 0%, #4068c2 100%);
      background: -o-linear-gradient(top, #3c8ea6 0%, #4068c2 100%);
      background: -ms-linear-gradient(top, #3c8ea6 0%, #4068c2 100%);
      background: linear-gradient(to bottom, #3c8ea6 0%, #4068c2 100%);
      border-radius: 5px;
      width: 100%;
      padding: 23px 25px 20px; }
    .strength {
      background: #d64545;
      background: -moz-linear-gradient(top, #d64545 0%, #a23535 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, #d64545), color-stop(100%, #a23535));
      background: -webkit-linear-gradient(top, #d64545 0%, #a23535 100%);
      background: -o-linear-gradient(top, #d64545 0%, #a23535 100%);
      background: -ms-linear-gradient(top, #d64545 0%, #a23535 100%);
      background: linear-gradient(to bottom, #d64545 0%, #a23535 100%);
      border-radius: 5px;
      width: 100%;
      padding: 23px 25px 20px; } }
  .routine {
    > div {
      position: relative;
      border-radius: 5px;
      height: 130px; }
    .routine-bg {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 5px;
      background-color: #223bff;
      display: flex;
      align-items: flex-end;
      padding: 20px;
      p {
        font-family: GT-Bold, sans-serif;
        text-transform: uppercase;
        font-size: 1rem;
        letter-spacing: -0.4px;
        text-shadow: 0px 2px 8px rgb(0, 0, 0); } } } }

// reviews
.review-block {
  margin-bottom: 30px;
  font-family: Poppins, sans-serif;
  .rating {
    i {
      opacity: 0.5;
      margin-right: 5px;
      font-size: 1.4rem; }
    i.yellow-star {
      color: #ffff0a;
      opacity: 1; } }
  .timeago {
    font-family: Poppins, sans-serif;
    font-size: 0.9rem;
    margin-top: 0px;
    opacity: 0.8; }
  .title {
    font-weight: 600;
    font-size: 2rem; }
  .the-review {
    font-size: 1.2rem; }
  .reviewer {
    font-size: 0.9rem;
    opacity: 0.8;
    margin-top: 10px;
    a {
      font-weight: 600;
      display: inline-block;
      transition: all 0.3s ease; }
    a:hover {
      transform: translateX(3px); } } }

// notification blocks
.unseen {
  .notif-block {
    background: rgba(0, 0, 0, 0.30); } }
.notif-block {
  background: rgba(0, 0, 0, 0.10);
  padding: 10px 15px;
  margin: 15px 0;
  border-radius: 5px;
  cursor: default;
  .notif-user {
    font-weight: 500;
    font-style: italic; }
  .notif-user:hover {
    transform: none; }
  .notif-user:after {
    content: none; }
  .program {
    span {
      text-transform: capitalize;
      font-weight: 700;
      font-style: normal;
      font-size: 110%; } } }

// focus area blocks
.focus-area {
  border: 0;
  background: transparent;
  color: white;
  font-family: Poppins, sans-serif;
  font-size: 0.9rem;
  cursor: pointer;
  padding: 0;
  display: inline-flex;
  margin-bottom: 8px;
  margin-right: 8px;
  div {
    display: inline-flex;
    align-items: center; }
  img {
    background: #3a4cf9;
    padding: 5px 10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; }
  span {
    padding: 5px 12px;
    display: flex;
    height: 42px;
    align-items: center;
    background: #18267b;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px; } }
