.cR {
  .rB {
    margin-bottom: 40px; }
  .cR-title {
    display: flex;
    align-items: center;
    .r-back svg {
      height: 30px;
      width: 30px;
      cursor: pointer;
      margin-right: 20px; } } }
