.s {
  min-height: calc(100vh - 60px);
  margin-bottom: 80px;
  .title {
    font-weight: bold;
    font-size: 30pt;
  }
  .sub {
    font-size: 16pt;
    margin-top: 20px;
    color: #304354;
    opacity: 0.6;
  }
  .s_container {
    margin-top: 60px;
    display: flex;
    box-shadow: 0 0 100px #ececec;
    border-radius: 5px;
    width: 100%;
    max-width: 1000px;
    .tabs {
      margin: 0;
      list-style: none;
      width: 230px;
      padding: 40px 30px;
      border-right: 1px solid #ececec;
      .tab {
        margin-bottom: 30px;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        align-items: center;
        color: #111;
        opacity: 0.6;
        * {
          pointer-events: none;
        }
        svg {
          margin-right: 12px;
          width: 20px;
          height: 20px;
        }
        &.active {
          color: #111;
          opacity: 1;
          svg {
            path {
              fill: #406aff;
            }
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .content {
      padding: 40px 30px;
      // width: calc(100% - 230px);
      width: 100%;
      .section {
        display: none;
        h3 {
          margin-bottom: 40px;
          font-weight: 500;
        }
        &.active {
          display: block;
        }
      }
      .group {
        .label {
          text-transform: uppercase;
          font-weight: 700;
          font-size: 9pt;
          opacity: 0.6;
          letter-spacing: 0.4pt;
          margin-bottom: 10px;
        }
        input,
        textarea,
        button,
        select {
          border: 1px solid #cfcfcf;
          border-radius: 5px;
          padding: 10px;
          font-size: 12pt;
          width: 100%;
        }
        select {
          height: 40px;
        }
        .wrap {
          display: flex;
          align-items: center;
        }
        .img-block {
          width: 40px;
          height: 40px;
          margin-right: 10px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 500px;
          }
        }
        .choose_pic {
          font-size: 10pt;
          border-bottom: 1px solid grey;
        }
        button {
          width: 70px;
          background: #e8ebf5;
          cursor: pointer;
          border-color: #cfd5e4;
          color: #8e96a9;
          box-shadow: 0 3px 5px #eef0f5;
          &:hover {
            color: #406aff;
            border-color: #406aff;
          }
          &.active {
            color: white;
            border-color: #3357d8;
            background: #406aff;
            box-shadow: 0 3px 5px #c3cfee;
          }
        }
        .cats-list {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          button {
            width: auto;
            padding-left: 20px;
            padding-right: 20px;
            margin-right: 10px;
            margin-bottom: 10px;
          }
        }
        .filepond--wrapper {
          width: calc(100% - 50px);
          height: 40px;
          background: #f1f0ef;
          border-radius: 5px;
          button {
            display: none;
          }
          .filepond--root .filepond--drop-label {
            min-height: 40px;
          }
          .filepond--file .filepond--progress-indicator {
            opacity: 1 !important;
            visibility: visible !important;
          }
        }
        &#height,
        &#weight {
          .wrap {
            justify-content: space-between;
          }
          input {
            width: calc(100% - 160px);
            text-align: center;
          }
        }
      }
      .halfs-group {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 30px;
        .group {
          width: calc(50% - 20px);
        }
        &.special {
          >.group:nth-of-type(1) {
            width: 150px;
            .value {
              font-size: 20pt;
              font-weight: 600;
            }
          }
          >.group:nth-of-type(2) {
            width: auto;
          }
        }
      }
      .full-group {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 30px;
        .group {
          width: 100%;
        }
      }
      .three-group {
        display: flex;
        width: 100%;
        justify-content: space-between;
        input {
          width: calc(33.33% - 10px);
          text-align: center;
        }
      }
      .msg-container {
        display: flex;
        align-items: center;
        .msg {
          display: block;
          margin-left: 20px;
          color: #2ca965;
          opacity: 0;
          visibility: hidden;
          &.active {
            opacity: 1;
            visibility: visible;
          }
        }
      }
      .saveData,
      .savePassword {
        border-radius: 5px;
        font-size: 14pt;
        padding: 10px 30px;
        background: #2ca965;
        color: white;
        border: #369662;
        box-shadow: 0 3px 5px #b8e2cb;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
          transform: scale(1.05);
          box-shadow: 0 3px 10px 0 #b8e2cb;
        }
      }
      .tooltip.fade:after,
      .tooltip.fade:before {
        transform: translate3d(0, -10px, 0);
        transition: all 0.15s ease-in-out;
      }

      .tooltip.fade.showTooltip:after,
      .tooltip.fade.showTooltip:before {
        opacity: 1;
        transform: translate3d(0, -28px, 0);
      }
    }
  }
  .divider {
    width: 100%;
    margin: 50px 0;
    border: 1px solid #ececec;
    height: 1px;
    opacity: 0.5;
  }
  .cancel {
    display: inline-block;
    margin-top: 20px;
    border-radius: 5px;
    font-size: 12pt;
    padding: 8px 15px;
    background: #cf901a;
    color: white;
    border: #cf901a;
    box-shadow: 0 3px 5px #f5e9d4;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .logout {
    color: #e41c47;
    display: flex;
    font-size: 12pt;
    cursor: pointer;
    line-height: 2;
    opacity: 0.87;
    transition: all 0.3s ease;
    &:hover {
      opacity: 1;
    }
  }
  .delete {
    background: #e41c47;
    color: white;
    margin-top: 20px;
    display: inline-flex;
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 3px 5px #eeccd4;
  }
}
