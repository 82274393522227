.exercise-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  .exercise-block-left {
    display: flex;
    align-items: center;
    > img {
      height: 80px;
      border-radius: 5px;
      margin-right: 30px; }
    > div {
      cursor: pointer;
      p {
        text-transform: uppercase;
        font-family: GT-Medium, sans-serif;
        pointer-events: none; }
      small {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-size: 0.7rem;
        letter-spacing: 0.05rem;
        pointer-events: none;
        img {
          margin-left: 10px;
          pointer-events: none; } } }
    > div:hover {
      small {
        text-decoration: underline; } } }
  .exercise-block-right {
    p {
      color: rgba(255, 255, 255, 0.6);
      font-family: GT-Light, sans-serif;
      font-size: 0.9rem; } } }
