@import url(/assets/font/inter.css);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

*:focus {
  outline: none !important; }

::-webkit-scrollbar {
  width: 0; }

html, body {
  background: #fff;
  color: #111;
  font-family: 'inter', sans-serif; }

input, button {
  font-family: 'inter', sans-serif;
  -webkit-appearance: none; }

html, body, h1, h2, h3, h4, h5, h6, p, span, a {
  margin: 0;
  padding: 0;
  font-weight: normal; }

p {
  font-weight: 400;
  font-size: 11pt; }

h2 {
  font-weight: 700;
  font-size: 30pt; }

hr {
  margin: 30px 40px 40px;
  border-color: white;
  opacity: 0.3; }

.small {
  font-size: 0.8rem; }

a {
  text-decoration: none;
  color: inherit;
  transition: all 0.3s ease; }

.p-l_40 {
  padding-left: 40px; }

.p-r_40 {
  padding-right: 40px; }

.p-tb_40 {
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100%; }

.p-tb_80 {
  padding-top: 80px;
  padding-bottom: 80px;
  width: 100%; }

.disabledLink {
  pointer-events: none !important; }

.comingSoon {
  background: #406aff;
  padding: 8px 12px;
  border-radius: 10px;
  color: #fff;
  line-height: 1;
  font-size: 11pt;
  font-weight: 700;
  margin-top: 30px;
  display: inline-block; }

.behind {
  z-index: -1;
  bottom: -10px;
  left: 10px;
  position: absolute;
  opacity: 0.3; }

.hidden {
  display: none !important; }

.shown {
  display: block; }

.inline-block {
  display: inline-block; }

.background {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.contain {
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px !important;
  background: #f7f9fa;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
  padding: 20px; }

.tooltip {
  position: relative;
  display: block; }

.tooltip:before,
.tooltip:after {
  display: block;
  opacity: 0;
  pointer-events: none;
  position: absolute; }

.tooltip:before {
  background: #d64545;
  border-radius: 5px;
  color: #ffffff;
  content: attr(data-title);
  font-size: 13px;
  padding: 5px 5px 3px;
  top: 0;
  white-space: nowrap; }

.horizontal-scroll {
  list-style-type: none;
  white-space: nowrap;
  overflow-x: auto;
  padding-bottom: 10px;
  padding-top: 10px;
  display: block !important; }

.app-container {
  width: 100%;
  min-height: 100vh;
  max-height: 100vh; }
  .app-container .app-right {
    width: calc(100% - 80px); }
  .app-container .app-max {
    max-width: 100%;
    min-width: 320px;
    min-height: calc(100% - 70px);
    height: calc(100% - 70px);
    max-height: calc(100% - 70px);
    overflow-y: scroll;
    padding-left: 40px; }
    .app-container .app-max .main-content {
      display: flex;
      position: relative;
      height: 100%;
      margin-top: 70px; }
      .app-container .app-max .main-content > .content {
        min-width: 0;
        padding-right: 0;
        display: flex;
        margin-left: 170px;
        width: calc(100% - 170px);
        flex-wrap: wrap; }
        .app-container .app-max .main-content > .content .main-container {
          margin-top: 30px;
          padding: 85px 30px 30px;
          min-height: calc(100vh - 30px);
          width: calc(100% - 130px); }

.app-container.at_join .app-max, .app-container.at_on-boarding .app-max {
  height: 100vh;
  max-height: 100vh;
  padding-left: 0; }
  .app-container.at_join .app-max .main-content, .app-container.at_on-boarding .app-max .main-content {
    margin-top: 0; }
    .app-container.at_join .app-max .main-content .content, .app-container.at_on-boarding .app-max .main-content .content {
      width: 100%;
      margin-left: 0;
      align-items: center;
      justify-content: center; }

.cR-title {
  display: flex;
  align-items: center; }
  .cR-title .r-back svg {
    height: 30px;
    width: 30px;
    cursor: pointer;
    margin-right: 20px; }

.noLeft .app-max {
  padding-left: 0; }

.noLeft .left-sidebar {
  display: none !important; }

.noLeft .app-container .app-max .main-content > .content {
  margin-left: 0;
  width: 100%; }

.page-not-found {
  text-align: center;
  padding-right: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .page-not-found h1 {
    font-weight: bold;
    margin-bottom: 30px; }
  .page-not-found a {
    color: #406aff;
    text-transform: capitalize; }

.login {
  max-width: 800px;
  min-width: 320px;
  padding: 80px 15px 40px;
  margin: 0px auto 0; }
  .login .login-top {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .login .login-top img {
      max-width: 70px; }
    .login .login-top ul {
      display: flex;
      align-items: center;
      list-style: none;
      margin: 0;
      padding: 0; }
      .login .login-top ul li {
        margin-left: 40px;
        font-size: 0.85rem; }
  .login .login-container {
    border-radius: 10px;
    background: #1d33cd;
    width: 100%;
    height: 380px;
    margin-top: 40px;
    box-shadow: 0px 10px 20px 0px rgba(0, 51, 153, 0.26);
    display: flex; }
    .login .login-container .login-signin {
      height: 100%;
      width: 63%;
      padding: 80px 120px;
      text-align: center; }
      .login .login-container .login-signin h2 {
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 2.2rem;
        margin-bottom: 20px; }
        .login .login-container .login-signin h2 span {
          font-weight: 600;
          color: #ffcd22; }
      .login .login-container .login-signin .signin-email {
        border-radius: 500px;
        background: #e5eaff;
        padding: 7px;
        cursor: pointer;
        margin-bottom: 10px; }
        .login .login-container .login-signin .signin-email p {
          color: #1d33cd;
          font-size: 0.95rem;
          font-weight: 500; }
      .login .login-container .login-signin .facebook-login {
        border-radius: 500px;
        background: #3a4cf9;
        padding: 7px;
        cursor: pointer;
        margin-bottom: 8px;
        font-size: 0.95rem;
        width: 100%;
        border: 0;
        color: #dbe1fd;
        font-weight: 500; }
      .login .login-container .login-signin .fb-disclaimer {
        font-size: 0.8rem;
        color: #ccd1ff; }
    .login .login-container .login-signup {
      background: #3a4cf9;
      height: 100%;
      width: 37%;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      overflow: hidden;
      position: relative;
      padding: 15px; }
      .login .login-container .login-signup .step {
        position: absolute;
        margin: 35px;
        width: 215px;
        top: 0;
        left: 0;
        bottom: 0;
        transition: all 0.3s ease; }
      .login .login-container .login-signup #step-1 {
        text-align: center; }
        .login .login-container .login-signup #step-1 p {
          font-weight: 700;
          line-height: 1.2rem; }
        .login .login-container .login-signup #step-1 h2 {
          margin: 20px 0;
          font-weight: 500; }
        .login .login-container .login-signup #step-1 div.button-raise {
          display: block;
          width: 80%;
          margin: 10px auto;
          background: transparent;
          color: white;
          border-radius: 500px;
          padding: 7px 5px 5px;
          font-size: 0.9rem;
          font-weight: 500;
          border: 1px solid;
          border-color: rgba(255, 255, 255, 0.3); }
        .login .login-container .login-signup #step-1 div.button-raise.active, .login .login-container .login-signup #step-1 div.button-raise:hover {
          border-color: white; }
        .login .login-container .login-signup #step-1 div.button-raise.chosen {
          border-color: #212dab;
          background: rgba(0, 0, 0, 0.25); }
      .login .login-container .login-signup #step-2 {
        left: 285px;
        text-align: center; }
        .login .login-container .login-signup #step-2 h2 {
          display: flex;
          align-items: center;
          line-height: 0;
          font-size: 1.4rem;
          font-weight: 500;
          justify-content: space-evenly; }
          .login .login-container .login-signup #step-2 h2 img {
            margin-right: 10px;
            cursor: pointer; }
        .login .login-container .login-signup #step-2 p {
          margin: 15px 0 30px;
          font-size: 0.8rem; }
        .login .login-container .login-signup #step-2 input {
          display: block;
          width: 80%;
          margin: 10px auto;
          background: transparent;
          color: white;
          border-radius: 500px;
          padding: 7px 5px 5px;
          font-size: 1rem;
          font-weight: 500;
          border: 1px solid rgba(255, 255, 255, 0.3);
          text-align: center;
          transition: all 0.3s ease;
          box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1); }
        .login .login-container .login-signup #step-2 input::-webkit-input-placeholder {
          color: rgba(255, 255, 255, 0.6); }
        .login .login-container .login-signup #step-2 input::-ms-input-placeholder {
          color: rgba(255, 255, 255, 0.6); }
        .login .login-container .login-signup #step-2 input::placeholder {
          color: rgba(255, 255, 255, 0.6); }
        .login .login-container .login-signup #step-2 input.active, .login .login-container .login-signup #step-2 input:hover, .login .login-container .login-signup #step-2 input:focus {
          border-color: white; }
        .login .login-container .login-signup #step-2 button {
          height: auto;
          font-size: 1rem;
          width: 80%;
          border-radius: 500px;
          padding: 7px 5px 7px;
          font-weight: 500;
          margin-top: 6px; }
        .login .login-container .login-signup #step-2 button.disabled {
          color: rgba(255, 255, 255, 0.38);
          background-color: rgba(56, 69, 197, 0.67);
          cursor: default;
          -webkit-transform: none !important;
          transform: none !important;
          box-shadow: none !important; }
      .login .login-container .login-signup #step-3 {
        left: 570px; }
        .login .login-container .login-signup #step-3 h2 {
          display: flex;
          align-items: center;
          line-height: 0;
          font-size: 1.4rem;
          font-weight: 500;
          margin-bottom: 20px; }
          .login .login-container .login-signup #step-3 h2 img {
            margin-right: 10px;
            cursor: pointer; }
        .login .login-container .login-signup #step-3 p {
          margin: 3px 0 10px;
          font-size: 0.65rem;
          text-align: center;
          opacity: 0.7; }
        .login .login-container .login-signup #step-3 input[name='name'] {
          text-transform: capitalize; }
        .login .login-container .login-signup #step-3 input {
          display: block;
          width: 100%;
          margin: 7px auto;
          background: transparent;
          color: white;
          border-radius: 5px;
          padding: 6px 10px 4px;
          font-size: 0.9rem;
          border: 1px solid rgba(255, 255, 255, 0.3);
          transition: all 0.3s ease;
          box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1); }
        .login .login-container .login-signup #step-3 input::-webkit-input-placeholder {
          color: rgba(255, 255, 255, 0.6); }
        .login .login-container .login-signup #step-3 input::-ms-input-placeholder {
          color: rgba(255, 255, 255, 0.6); }
        .login .login-container .login-signup #step-3 input::placeholder {
          color: rgba(255, 255, 255, 0.6); }
        .login .login-container .login-signup #step-3 input.active, .login .login-container .login-signup #step-3 input:hover, .login .login-container .login-signup #step-3 input:focus {
          border-color: white; }
        .login .login-container .login-signup #step-3 input.error {
          border-color: #f13333; }
        .login .login-container .login-signup #step-3 button {
          font-size: 1rem;
          width: 100%;
          font-weight: 500;
          height: auto;
          padding: 10px 10px; }
        .login .login-container .login-signup #step-3 .tooltip.fade:after,
        .login .login-container .login-signup #step-3 .tooltip.fade:before {
          -webkit-transform: translate3d(0, -10px, 0);
                  transform: translate3d(0, -10px, 0);
          transition: all .15s ease-in-out; }
        .login .login-container .login-signup #step-3 .tooltip.fade.showTooltip:after,
        .login .login-container .login-signup #step-3 .tooltip.fade.showTooltip:before {
          opacity: 1;
          -webkit-transform: translate3d(0, -35px, 0);
                  transform: translate3d(0, -35px, 0); }
      .login .login-container .login-signup .step-indicators {
        position: absolute;
        bottom: 10px;
        text-align: center;
        display: block;
        width: calc(100% - 30px); }
        .login .login-container .login-signup .step-indicators .indicator {
          height: 8px;
          width: 8px;
          background: white;
          border-radius: 500px;
          display: inline-block;
          margin: 0 3px;
          opacity: 0.2; }
        .login .login-container .login-signup .step-indicators .indicator.active {
          opacity: 1; }

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #3535ff;
  opacity: 0;
  visibility: hidden;
  z-index: 40;
  -moz-transition: opacity 0.25s ease 0s, visibility 0.35s linear;
  -o-transition: opacity 0.25s ease 0s, visibility 0.35s linear;
  -webkit-transition: opacity 0.25s ease, visibility 0.35s linear;
  -webkit-transition-delay: 0s, 0s;
  transition: opacity 0.25s ease 0s, visibility 0.35s linear; }
  .modal-overlay.state-show {
    opacity: .7;
    visibility: visible;
    transition-delay: 0s;
    transition-duration: 0.2s, 0s; }

.modal-frame {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 50;
  display: flex;
  align-items: center;
  -moz-box-align: center;
  justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  width: 100%;
  text-align: center;
  visibility: hidden;
  color: black; }
  .modal-frame.state-appear {
    visibility: visible; }
    .modal-frame.state-appear .modal-inset {
      -webkit-animation: modalComeIn 0.25s ease;
      animation: modalComeIn 0.25s ease;
      visibility: visible; }
    .modal-frame.state-appear .modal-body {
      opacity: 1;
      -webkit-transform: translateY(0) scale(1, 1);
      transform: translateY(0) scale(1, 1); }
  .modal-frame.state-leave {
    visibility: visible; }
    .modal-frame.state-leave .modal-inset {
      -webkit-animation: modalHeadOut 0.35s ease 0.1s;
      animation: modalHeadOut 0.35s ease 0.1s;
      visibility: visible; }
    .modal-frame.state-leave .modal-body {
      opacity: 0;
      transition-delay: 0s;
      transition-duration: 0.35s;
      transition-timing-function: ease;
      -webkit-transform: translateY(25px);
      transform: translateY(25px); }

@-moz-document url-prefix() {
  .modal-frame {
    height: calc(100% - 55px); } }

.modal {
  display: block;
  vertical-align: middle;
  text-align: center; }

.modal-inset {
  position: relative;
  padding: 60px;
  background-color: #1a2dc2;
  min-width: 320px;
  min-height: 126px;
  max-width: 400px;
  margin: auto;
  border-radius: 5px;
  visibility: hidden;
  box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }
  .modal-inset .close {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    opacity: .4; }
    .modal-inset .close:hover {
      opacity: 1; }

.modal-body {
  margin: auto;
  opacity: 0;
  -webkit-transform: translateY(0) scale(0.8, 0.8);
  transform: translateY(0) scale(0.8, 0.8);
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
  transition-duration: 0.25s;
  transition-delay: 0.1s; }
  .modal-body h3 {
    font-weight: 700;
    padding-bottom: 22px;
    display: block;
    color: #ffcd22;
    font-size: 2rem;
    line-height: 2rem;
    text-align: left; }
  .modal-body input {
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #606fef;
    background: transparent;
    border-radius: 5px;
    padding: 7px 10px;
    color: white;
    font-size: 1rem;
    transition: all 0.3s ease; }
  .modal-body input:focus {
    border-color: white; }
  .modal-body input::-webkit-input-placeholder {
    color: #606fef; }
  .modal-body input::-ms-input-placeholder {
    color: #606fef; }
  .modal-body input::placeholder {
    color: #606fef; }
  .modal-body button {
    border-radius: 5px;
    background: #3d51ec;
    color: white;
    font-size: 1rem;
    font-weight: 500;
    border: 0;
    width: 100%;
    padding: 7px 10px; }
  .modal-body p.errMsg {
    margin-top: 10px;
    color: #ff7575;
    opacity: 0;
    visibility: hidden; }
  .modal-body p.errMsg.show {
    opacity: 1;
    visibility: visible; }
  .modal-body .ps {
    font-size: 12px;
    opacity: .3; }

@-webkit-keyframes modalComeIn {
  0% {
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8); }
  65.5% {
    -webkit-transform: scale(1.03, 1.03);
    transform: scale(1.03, 1.03); }
  100% {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); } }

@keyframes modalComeIn {
  0% {
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8); }
  65.5% {
    -webkit-transform: scale(1.03, 1.03);
    transform: scale(1.03, 1.03); }
  100% {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); } }

@-webkit-keyframes modalHeadOut {
  0% {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0) scale(1, 1);
    transform: translateY(0) scale(1, 1); }
  100% {
    visibility: hidden;
    opacity: 0;
    -webkit-transform: translateY(35px) scale(0.97, 0.97);
    transform: translateY(35px) scale(0.97, 0.97); } }

@keyframes modalHeadOut {
  0% {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0) scale(1, 1);
    transform: translateY(0) scale(1, 1); }
  100% {
    visibility: hidden;
    opacity: 0;
    -webkit-transform: translateY(35px) scale(0.97, 0.97);
    transform: translateY(35px) scale(0.97, 0.97); } }

.j {
  width: 800px; }
  .j .j-top {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 20px; }
    .j .j-top img {
      max-height: 50px; }
    .j .j-top ul {
      display: flex;
      align-items: center;
      list-style: none;
      margin: 0;
      padding: 0; }
      .j .j-top ul li {
        margin-left: 30px;
        font-size: 10pt; }
  .j .j-container {
    border-radius: 10px;
    background: #4a60ff;
    width: 100%;
    max-width: 800px;
    height: 380px;
    margin-bottom: 30px;
    box-shadow: 0px 10px 20px 0px rgba(85, 68, 255, 0.26);
    display: flex;
    color: white; }
    .j .j-container .login-signin {
      height: 100%;
      width: 63%;
      padding: 80px 120px;
      text-align: center;
      background: #3b4fe0;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px; }
      .j .j-container .login-signin h2 {
        font-size: 1.4rem;
        line-height: 2.2rem;
        margin-bottom: 40px; }
        .j .j-container .login-signin h2 span {
          color: #ffcd22; }
      .j .j-container .login-signin .signin-email {
        border-radius: 500px;
        background: #e5eaff;
        padding: 15px 7px;
        cursor: pointer;
        margin-bottom: 10px; }
        .j .j-container .login-signin .signin-email p {
          color: #1d33cd;
          font-size: 1rem; }
      .j .j-container .login-signin .signup-mobile {
        display: none;
        border-radius: 500px;
        background: #1c3388;
        padding: 15px 7px;
        cursor: pointer;
        margin-top: 15px;
        margin-bottom: 30px; }
        .j .j-container .login-signin .signup-mobile p {
          color: white;
          font-size: 1rem; }
      .j .j-container .login-signin .facebook-login {
        border-radius: 500px;
        background: #5165f5;
        padding: 8px 7px 7px;
        cursor: pointer;
        margin-bottom: 8px;
        font-size: 0.95rem;
        width: 100%;
        border: 0;
        color: #dbe1fd; }
      .j .j-container .login-signin .fb-disclaimer {
        font-size: 0.8rem;
        color: #ccd1ff; }
      .j .j-container .login-signin .modal {
        position: relative; }
        .j .j-container .login-signin .modal a {
          text-decoration: underline;
          position: absolute;
          bottom: 20px;
          right: 25px;
          color: white;
          font-size: 9.5pt;
          opacity: 0.6; }
    .j .j-container .login-signup {
      background: #406aff;
      height: 100%;
      width: 37%;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      overflow: hidden;
      position: relative;
      padding: 15px; }
      .j .j-container .login-signup .signin-mobile {
        display: none;
        position: absolute;
        top: 20px;
        left: calc(50% - 50px);
        z-index: 2; }
      .j .j-container .login-signup .step {
        position: absolute;
        margin: 35px;
        width: 215px;
        top: 0;
        left: 0;
        bottom: 0;
        transition: all 0.3s ease; }
      .j .j-container .login-signup #step-1 {
        text-align: center; }
        .j .j-container .login-signup #step-1 p {
          line-height: 1.2rem;
          font-weight: 600; }
        .j .j-container .login-signup #step-1 h2 {
          margin: 20px 0;
          font-size: 16pt; }
        .j .j-container .login-signup #step-1 div.button-raise {
          display: block;
          width: 80%;
          margin: 10px auto;
          background: transparent;
          color: white;
          border-radius: 500px;
          padding: 7px 5px 5px;
          font-size: 0.9rem;
          border: 1px solid;
          border-color: rgba(255, 255, 255, 0.3); }
        .j .j-container .login-signup #step-1 div.button-raise.active, .j .j-container .login-signup #step-1 div.button-raise:hover {
          border-color: white; }
        .j .j-container .login-signup #step-1 div.button-raise.chosen {
          border-color: #212dab;
          background: rgba(0, 0, 0, 0.25); }
      .j .j-container .login-signup #step-2 {
        left: 285px;
        text-align: center; }
        .j .j-container .login-signup #step-2 h2 {
          display: flex;
          align-items: center;
          line-height: 0;
          justify-content: center;
          font-size: 16pt; }
          .j .j-container .login-signup #step-2 h2 img {
            margin-right: 10px;
            cursor: pointer;
            width: 28px;
            height: 28px; }
        .j .j-container .login-signup #step-2 p {
          margin: 15px 0 30px;
          font-size: 0.8rem; }
        .j .j-container .login-signup #step-2 input {
          display: block;
          width: 80%;
          margin: 10px auto;
          background: transparent;
          color: white;
          border-radius: 500px;
          padding: 7px 5px 5px;
          font-size: 1rem;
          border: 1px solid rgba(255, 255, 255, 0.3);
          text-align: center;
          transition: all 0.3s ease;
          box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1); }
        .j .j-container .login-signup #step-2 input::-webkit-input-placeholder {
          color: rgba(255, 255, 255, 0.6); }
        .j .j-container .login-signup #step-2 input::-ms-input-placeholder {
          color: rgba(255, 255, 255, 0.6); }
        .j .j-container .login-signup #step-2 input::placeholder {
          color: rgba(255, 255, 255, 0.6); }
        .j .j-container .login-signup #step-2 input.active, .j .j-container .login-signup #step-2 input:hover, .j .j-container .login-signup #step-2 input:focus {
          border-color: white; }
        .j .j-container .login-signup #step-2 button {
          height: auto;
          font-size: 1rem;
          width: 80%;
          border-radius: 500px;
          padding: 7px 5px 7px;
          margin-top: 6px;
          background-color: #3b4fe0;
          border: 0;
          color: white;
          cursor: pointer; }
        .j .j-container .login-signup #step-2 button.disabled {
          color: rgba(255, 255, 255, 0.38);
          background-color: rgba(51, 64, 206, 0.5);
          cursor: default;
          -webkit-transform: none !important;
          transform: none !important;
          box-shadow: none !important; }
      .j .j-container .login-signup #step-3 {
        left: 570px; }
        .j .j-container .login-signup #step-3 h2 {
          display: flex;
          align-items: center;
          line-height: 0;
          margin-bottom: 20px;
          font-size: 16pt;
          justify-content: center; }
          .j .j-container .login-signup #step-3 h2 img {
            margin-right: 10px;
            cursor: pointer;
            width: 28px;
            height: 28px; }
        .j .j-container .login-signup #step-3 p {
          margin: 3px 0 10px;
          font-size: 0.65rem;
          text-align: center;
          opacity: 0.7; }
        .j .j-container .login-signup #step-3 input[name='name'] {
          text-transform: capitalize; }
        .j .j-container .login-signup #step-3 input {
          display: block;
          width: 100%;
          margin: 7px auto;
          background: transparent;
          color: white;
          border-radius: 5px;
          padding: 6px 10px 4px;
          font-size: 0.9rem;
          border: 1px solid rgba(255, 255, 255, 0.3);
          transition: all 0.3s ease;
          box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1); }
        .j .j-container .login-signup #step-3 input::-webkit-input-placeholder {
          color: rgba(255, 255, 255, 0.6); }
        .j .j-container .login-signup #step-3 input::-ms-input-placeholder {
          color: rgba(255, 255, 255, 0.6); }
        .j .j-container .login-signup #step-3 input::placeholder {
          color: rgba(255, 255, 255, 0.6); }
        .j .j-container .login-signup #step-3 input.active, .j .j-container .login-signup #step-3 input:hover, .j .j-container .login-signup #step-3 input:focus {
          border-color: white; }
        .j .j-container .login-signup #step-3 input.error {
          border-color: #f13333; }
        .j .j-container .login-signup #step-3 button {
          font-size: 1rem;
          width: 100%;
          height: auto;
          padding: 10px 10px;
          border: 0;
          border-radius: 500px;
          cursor: pointer;
          background-color: #fff; }
        .j .j-container .login-signup #step-3 .tooltip.fade:after,
        .j .j-container .login-signup #step-3 .tooltip.fade:before {
          -webkit-transform: translate3d(0, -10px, 0);
                  transform: translate3d(0, -10px, 0);
          transition: all .15s ease-in-out; }
        .j .j-container .login-signup #step-3 .tooltip.fade.showTooltip:after,
        .j .j-container .login-signup #step-3 .tooltip.fade.showTooltip:before {
          opacity: 1;
          -webkit-transform: translate3d(0, -25px, 0);
                  transform: translate3d(0, -25px, 0); }
      .j .j-container .login-signup .step-indicators {
        position: absolute;
        bottom: 10px;
        text-align: center;
        display: block;
        width: calc(100% - 30px); }
        .j .j-container .login-signup .step-indicators .indicator {
          height: 8px;
          width: 8px;
          background: white;
          border-radius: 500px;
          display: inline-block;
          margin: 0 3px;
          opacity: 0.2; }
        .j .j-container .login-signup .step-indicators .indicator.active {
          opacity: 1; }
  .j .modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: #000;
    opacity: 0;
    visibility: hidden;
    z-index: 40;
    -moz-transition: opacity 0.25s ease 0s, visibility 0.35s linear;
    -o-transition: opacity 0.25s ease 0s, visibility 0.35s linear;
    -webkit-transition: opacity 0.25s ease, visibility 0.35s linear;
    -webkit-transition-delay: 0s, 0s;
    transition: opacity 0.25s ease 0s, visibility 0.35s linear; }
    .j .modal-overlay.state-show {
      opacity: .7;
      visibility: visible;
      transition-delay: 0s;
      transition-duration: 0.2s, 0s; }
  .j .modal-frame {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 50; }

.oB {
  width: 750px; }
  .oB .get-started {
    padding: 40px 40px 10px !important;
    min-height: 0 !important;
    height: 400px;
    background: #4a60ff;
    border-radius: 10px;
    box-shadow: -3px 7px 20px 2px rgba(0, 0, 0, 0.05);
    position: relative;
    text-align: center;
    width: 100% !important; }
    .oB .get-started .getstarted-inner > h2 {
      color: white;
      font-size: 16pt;
      margin: 0 auto 10px;
      max-width: 400px; }
    .oB .get-started .getstarted-inner > p {
      color: #80b0ff;
      font-size: 11pt; }
    .oB .get-started .getstarted-inner .getstarted-steps {
      margin: 40px auto 50px;
      height: 160px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      position: relative; }
      .oB .get-started .getstarted-inner .getstarted-steps #step-2 {
        left: 670px; }
      .oB .get-started .getstarted-inner .getstarted-steps #step-3 {
        left: 1340px; }
      .oB .get-started .getstarted-inner .getstarted-steps #step-4 {
        left: 2010px; }
        .oB .get-started .getstarted-inner .getstarted-steps #step-4 .step-container {
          justify-content: center; }
          .oB .get-started .getstarted-inner .getstarted-steps #step-4 .step-container .step-buttons {
            margin: 7px; }
            .oB .get-started .getstarted-inner .getstarted-steps #step-4 .step-container .step-buttons span {
              text-align: center;
              justify-content: center; }
      .oB .get-started .getstarted-inner .getstarted-steps #step-5 {
        left: 2680px; }
      .oB .get-started .getstarted-inner .getstarted-steps #step-6 {
        left: 3350px; }
        .oB .get-started .getstarted-inner .getstarted-steps #step-6 input.step-inputs {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px; }
        .oB .get-started .getstarted-inner .getstarted-steps #step-6 span::before {
          -webkit-transform: translate3d(0, -56px, 0);
                  transform: translate3d(0, -56px, 0); }
      .oB .get-started .getstarted-inner .getstarted-steps .profile-picture .step-container {
        flex-direction: column;
        width: 60%; }
        .oB .get-started .getstarted-inner .getstarted-steps .profile-picture .step-container .filepond--wrapper {
          width: 100%; }
      .oB .get-started .getstarted-inner .getstarted-steps .profile-picture .filepond {
        width: 170px;
        height: 170px;
        padding: 0;
        overflow: hidden; }
      .oB .get-started .getstarted-inner .getstarted-steps .step {
        position: absolute;
        width: 670px;
        top: 0;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease; }
        .oB .get-started .getstarted-inner .getstarted-steps .step .tooltip.fade:after,
        .oB .get-started .getstarted-inner .getstarted-steps .step .tooltip.fade:before {
          -webkit-transform: translate3d(0, -30px, 0);
                  transform: translate3d(0, -30px, 0);
          transition: all .15s ease-in-out; }
        .oB .get-started .getstarted-inner .getstarted-steps .step .tooltip.fade.showTooltip:after,
        .oB .get-started .getstarted-inner .getstarted-steps .step .tooltip.fade.showTooltip:before {
          opacity: 1;
          -webkit-transform: translate3d(0, -50px, 0);
                  transform: translate3d(0, -50px, 0); }
        .oB .get-started .getstarted-inner .getstarted-steps .step .step-nav {
          display: none; }
        .oB .get-started .getstarted-inner .getstarted-steps .step .step-arrow {
          cursor: pointer;
          transition: all 0.3s ease; }
        .oB .get-started .getstarted-inner .getstarted-steps .step .step-arrow-right:hover {
          -webkit-transform: translateX(4px);
                  transform: translateX(4px); }
        .oB .get-started .getstarted-inner .getstarted-steps .step .step-arrow-left:hover {
          -webkit-transform: translateX(-4px);
                  transform: translateX(-4px); }
        .oB .get-started .getstarted-inner .getstarted-steps .step .step-container {
          margin: 0 20px;
          display: flex;
          align-items: center;
          flex-wrap: wrap; }
          .oB .get-started .getstarted-inner .getstarted-steps .step .step-container .step-inputs {
            background: #d1d7ff;
            border: 0;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            font-size: 1.2rem;
            padding: 9px 20px;
            color: black;
            height: 49px;
            box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); }
          .oB .get-started .getstarted-inner .getstarted-steps .step .step-container .step-inputs::-webkit-input-placeholder {
            color: #9da1ff; }
          .oB .get-started .getstarted-inner .getstarted-steps .step .step-container .step-inputs::-ms-input-placeholder {
            color: #9da1ff; }
          .oB .get-started .getstarted-inner .getstarted-steps .step .step-container .step-inputs::placeholder {
            color: #9da1ff; }
          .oB .get-started .getstarted-inner .getstarted-steps .step .step-container input[name="height"], .oB .get-started .getstarted-inner .getstarted-steps .step .step-container input[name="weight"] {
            width: 160px; }
          .oB .get-started .getstarted-inner .getstarted-steps .step .step-container .step-units {
            height: 49px;
            border: 0;
            padding: 10px 10px;
            font-size: 1.2rem;
            background: #1c286b;
            color: rgba(255, 255, 255, 0.3);
            width: 60px;
            transition: all 0.3s ease;
            cursor: pointer;
            box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); }
          .oB .get-started .getstarted-inner .getstarted-steps .step .step-container .step-units.selected, .oB .get-started .getstarted-inner .getstarted-steps .step .step-container .step-units:hover {
            background: #162158;
            color: white; }
          .oB .get-started .getstarted-inner .getstarted-steps .step .step-container .step-units:last-of-type {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px; }
          .oB .get-started .getstarted-inner .getstarted-steps .step .step-container .step-buttons {
            border: 0;
            display: flex;
            align-items: center;
            background: transparent;
            opacity: 0.5;
            color: white;
            font-size: 11pt;
            cursor: pointer;
            padding: 0; }
            .oB .get-started .getstarted-inner .getstarted-steps .step .step-container .step-buttons span {
              padding: 6px 12px 5px;
              display: flex;
              height: 36px;
              align-items: center;
              border-radius: 5px;
              color: #fff;
              border: 1px solid white; }
          .oB .get-started .getstarted-inner .getstarted-steps .step .step-container .step-buttons.selected, .oB .get-started .getstarted-inner .getstarted-steps .step .step-container .step-buttons:hover {
            opacity: 1; }
          .oB .get-started .getstarted-inner .getstarted-steps .step .step-container .step-buttons.step-button-middle {
            margin: 6px 12px; }
          .oB .get-started .getstarted-inner .getstarted-steps .step .step-container .step-textarea {
            background: #fff;
            border: 0;
            resize: none;
            color: #4a60ff;
            border-radius: 5px;
            padding: 15px;
            font-size: 11pt;
            width: 300px;
            height: 150px;
            line-height: 1.3rem;
            box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); }
          .oB .get-started .getstarted-inner .getstarted-steps .step .step-container .step-textarea::-webkit-input-placeholder {
            color: rgba(85, 68, 255, 0.6); }
          .oB .get-started .getstarted-inner .getstarted-steps .step .step-container .step-textarea::-ms-input-placeholder {
            color: rgba(85, 68, 255, 0.6); }
          .oB .get-started .getstarted-inner .getstarted-steps .step .step-container .step-textarea::placeholder {
            color: rgba(85, 68, 255, 0.6); }

.progress-bar {
  width: 100%;
  height: 7px;
  background: #3366ff;
  border-radius: 10px;
  box-shadow: 0px 7px 20px 2px rgba(0, 0, 0, 0.05);
  position: absolute;
  top: 0;
  left: 0; }
  .progress-bar .progress-bar-completed {
    height: 7px;
    background: #d6b85c;
    border-radius: 10px;
    transition: all 0.3s ease; }

.loader {
  position: absolute;
  top: calc(50% - 56px);
  left: calc((50% - 44px)); }

.line {
  -webkit-animation: expand 1s ease-in-out infinite;
          animation: expand 1s ease-in-out infinite;
  border-radius: 50px;
  display: inline-block;
  -webkit-transform-origin: center center;
          transform-origin: center center;
  margin: 0 3px;
  width: 5px;
  height: 35px; }

.line:nth-child(1) {
  background: #4258ff; }

.line:nth-child(2) {
  -webkit-animation-delay: 180ms;
          animation-delay: 180ms;
  background: #3643ff; }

.line:nth-child(3) {
  -webkit-animation-delay: 360ms;
          animation-delay: 360ms;
  background: #4258ff; }

.line:nth-child(4) {
  -webkit-animation-delay: 540ms;
          animation-delay: 540ms;
  background: #3643ff; }

.section-loader .line {
  height: 5px;
  margin: 0 4px; }

@-webkit-keyframes expand {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  25% {
    -webkit-transform: scale(2);
            transform: scale(2); } }

@keyframes expand {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  25% {
    -webkit-transform: scale(2);
            transform: scale(2); } }

.alert {
  position: fixed;
  left: -600px;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 3px;
  width: auto;
  bottom: 20px;
  max-width: 600px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  transition: all 0.3s ease;
  z-index: 999; }
  .alert p {
    font-family: Poppins, GT-Regular, sans-serif;
    font-weight: 500;
    color: #09133c; }

.alert.alert-show {
  left: 15px; }

.side {
  width: 80px;
  height: 100vh;
  padding-bottom: 20px;
  overflow-y: scroll;
  text-align: center;
  border-right: 1px solid #ececec; }
  .side .copyright {
    font-size: 0.75rem;
    color: rgba(255, 255, 255, 0.45); }
  .side .logo-container {
    height: 70px; }
  .side .logo {
    height: 40px;
    cursor: pointer;
    margin-top: 16px; }
  .side .other-menu {
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap; }
    .side .other-menu a {
      margin-right: 5px;
      font-size: 0.75rem;
      color: #939ff7;
      line-height: 1rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease; }
    .side .other-menu a:after {
      content: "\B7";
      font-size: 1.2rem;
      padding-left: 5px; }
    .side .other-menu > a:last-child:after {
      content: ""; }
    .side .other-menu a:hover {
      color: white; }
    .side .other-menu a:hover:after {
      color: #787998; }
  .side ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 50px;
    margin-bottom: 30px; }
    .side ul li {
      margin-bottom: 25px;
      height: 42px;
      overflow: hidden; }
      .side ul li .soon {
        position: absolute;
        color: white;
        font-size: 7pt;
        background: #ff426e;
        border-radius: 20px;
        padding: 5px;
        z-index: 2;
        text-transform: capitalize; }
      .side ul li .link {
        position: relative;
        display: block;
        height: 32px; }
        .side ul li .link svg {
          fill: #111;
          height: 32px;
          width: 32px;
          transition: all 0.3s ease; }
        .side ul li .link > p {
          font-size: 8.5pt;
          align-items: center;
          color: #3640ff;
          opacity: 0;
          transition: all 0.3s ease;
          padding: 0 10px; }
      .side ul li a.activeLink::after {
        content: '';
        position: absolute;
        width: 10px;
        height: 2px;
        background: #405bff;
        bottom: -4px;
        border-radius: 500px;
        left: calc(50% - 5.5px);
        box-shadow: 0px 0px 3px 0px rgba(39, 65, 255, 0.7); }
    .side ul #nav-cart .hasCart::before {
      content: '';
      position: absolute;
      width: 4.2px;
      height: 4.3px;
      background: #f9355e;
      top: 3px;
      border-radius: 500px;
      right: 30%;
      box-shadow: 0px 0px 5px 0px #f9355e; }
    .side ul li:hover a svg {
      -webkit-transform: translateY(-32px);
              transform: translateY(-32px);
      opacity: 0; }
    .side ul li:hover a > p {
      -webkit-transform: translateY(-28px);
              transform: translateY(-28px);
      opacity: 1; }

.header-container {
  height: 70px;
  width: 100%;
  top: 0;
  transition: all 0.3s ease;
  z-index: 9;
  background: #F7F9FA;
  position: fixed;
  border-bottom: 1px solid #d4dadf;
  box-shadow: 0 3px 10px 0 rgba(71, 71, 82, 0.08); }
  .header-container .top-bar > div:first-child {
    display: flex; }
  .header-container .top-bar {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px; }
    .header-container .top-bar .logo-container {
      height: 50px;
      width: 50px;
      display: inline-flex;
      align-items: center;
      position: relative; }
      .header-container .top-bar .logo-container::after {
        position: absolute;
        border-radius: 3px;
        color: #505050;
        line-height: 1;
        font-size: 14pt;
        font-weight: 600;
        color: #000;
        padding: 3px 5px;
        left: 60px; }
      .header-container .top-bar .logo-container img {
        height: 36px; }
    .header-container .top-bar .menu li a {
      text-transform: uppercase;
      font-size: 10pt;
      opacity: 0.6;
      transition: all 0.3s ease; }
      .header-container .top-bar .menu li a:hover {
        opacity: 0.8; }
    .header-container .top-bar .menu li a.activeLink {
      opacity: 1; }
    .header-container .top-bar .menu #menu-player a {
      color: #406aff;
      opacity: 1; }
    .header-container .top-bar .menu #menu-premium {
      background: #406aff;
      padding: 5px 10px 4px;
      border-radius: 10px; }
      .header-container .top-bar .menu #menu-premium a {
        opacity: 1;
        color: #fff;
        line-height: 1;
        font-size: 9pt;
        font-weight: 600; }
    .header-container .top-bar form {
      position: relative;
      color: #111;
      width: calc(100% - 130px); }
      .header-container .top-bar form tags {
        margin-left: 45px;
        max-width: 320px;
        display: flex; }
        .header-container .top-bar form tags tag > div::before {
          background: #ffffff;
          border: 1px solid #efefef; }
      .header-container .top-bar form img {
        position: absolute;
        top: calc(50% - 15px);
        left: 0;
        opacity: 0.2;
        height: 30px; }
    .header-container .top-bar .left-side {
      width: 100px; }
    .header-container .top-bar .top-right {
      display: flex;
      align-items: center; }
      .header-container .top-bar .top-right a {
        position: relative;
        display: flex; }
      .header-container .top-bar .top-right .cartBtn.notEmpty::before {
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 500px;
        background: #ff4a48;
        position: absolute;
        right: 7px; }
      .header-container .top-bar .top-right img {
        width: 20px;
        height: 20px;
        margin-right: 10px; }
      .header-container .top-bar .top-right .profile-img {
        width: 30px;
        height: 30px;
        margin-left: 5px;
        margin-right: 0;
        border-radius: 500px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border: 1px solid #d5d6de; }
      .header-container .top-bar .top-right .profile-img.guest-profile {
        background-position: center 5px; }
    .header-container .top-bar ul {
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;
      align-items: center; }
      .header-container .top-bar ul li.profile-li {
        margin-left: 40px; }
      .header-container .top-bar ul li {
        margin-left: 20px; }
        .header-container .top-bar ul li a {
          display: flex;
          align-items: center;
          color: #0c0d46; }
          .header-container .top-bar ul li a p {
            font-size: 0.9rem;
            margin-left: 5px; }
        .header-container .top-bar ul li .signup-btn {
          background: #4a60ff;
          color: white;
          border-radius: 5px;
          padding: 6px 12px;
          font-size: 10pt;
          font-weight: 700;
          text-transform: uppercase;
          box-shadow: 0 3px 5px 0 #bac0ec; }
      .header-container .top-bar ul .points {
        border-radius: 500px;
        border: 1px solid #406aff;
        padding: 2px 7px;
        margin-left: 20px; }
        .header-container .top-bar ul .points p {
          font-size: 8pt;
          color: #406aff;
          font-weight: 500; }
          .header-container .top-bar ul .points p span {
            font-weight: 400;
            font-size: 7.5pt; }

.home {
  min-height: 100vh; }

.landing .app-max {
  padding-left: 0; }
  .landing .app-max .main-content .content {
    margin-left: 0;
    width: 100%;
    padding: 0 40px; }

.l {
  width: 100%; }
  .l .lBanner {
    margin-left: -40px;
    margin-right: -40px;
    height: calc(100vh - 50px);
    background-size: cover;
    background-position: top left;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; }
    .l .lBanner .lBanner-content {
      margin-bottom: 80px;
      text-align: center; }
      .l .lBanner .lBanner-content h1, .l .lBanner .lBanner-content h2 {
        text-transform: uppercase; }
      .l .lBanner .lBanner-content h2 {
        opacity: 0.7;
        font-weight: 500;
        font-size: 24pt;
        margin-left: auto;
        margin-right: auto; }
      .l .lBanner .lBanner-content h1 {
        font-size: 40pt;
        max-width: 600px;
        line-height: 36pt;
        margin-top: 10px;
        margin-bottom: 30px;
        font-weight: 700;
        margin-left: auto;
        margin-right: auto; }
      .l .lBanner .lBanner-content p {
        background: #4a60ff;
        display: inline-block;
        border: 2px solid #4a60ff;
        border-radius: 5px;
        padding: 12px 25px;
        font-weight: 500;
        font-size: 14pt;
        color: white;
        cursor: pointer;
        box-shadow: 0 3px 10px 0 #959dd8;
        transition: all 0.3s ease;
        margin-top: 40px; }
        .l .lBanner .lBanner-content p:hover {
          -webkit-transform: scale(1.05);
                  transform: scale(1.05);
          box-shadow: 0 3px 30px 0 #959dd8; }
      .l .lBanner .lBanner-content a.two-free p {
        position: relative; }
        .l .lBanner .lBanner-content a.two-free p:after {
          content: 'Limited time offer.';
          position: absolute;
          bottom: -30px;
          font-size: 10pt;
          left: calc(50% - 50px);
          color: black;
          opacity: 0.7;
          font-weight: normal; }
    .l .lBanner .lBanner-scroll {
      position: absolute;
      bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer; }
      .l .lBanner .lBanner-scroll p {
        font-weight: 500;
        font-size: 12pt; }
      .l .lBanner .lBanner-scroll img {
        margin-top: 5px;
        height: 22px;
        width: 22px; }
  .l .lPrograms {
    text-align: center; }
    .l .lPrograms h3, .l .lPrograms h4 {
      opacity: 0.8; }
    .l .lPrograms h3 {
      font-size: 16pt; }
    .l .lPrograms h4 {
      font-size: 13pt;
      margin-top: 5px; }
    .l .lPrograms h2 {
      font-size: 20pt;
      margin: 50px 0 30px; }
    .l .lPrograms .lPrograms-options {
      max-width: 600px;
      margin: 0 auto; }
      .l .lPrograms .lPrograms-options p {
        display: inline-block;
        border: 1px solid #4a60ff;
        border-radius: 5px;
        padding: 12px 16px 10px;
        color: #4a60ff;
        margin: 10px;
        cursor: pointer;
        transition: all 0.3s ease; }
        .l .lPrograms .lPrograms-options p:hover {
          background: #4a60ff;
          color: white; }
  .l .lRoutines {
    background: #F7F9FA;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: -40px;
    margin-right: -40px;
    width: calc(100% + 80px);
    text-align: center; }
    .l .lRoutines .rB {
      text-align: left; }
    .l .lRoutines h2 {
      font-size: 20pt;
      margin-bottom: 20px; }
    .l .lRoutines .or-you-can {
      opacity: 0.5;
      margin-top: 100px;
      font-size: 16pt;
      margin-bottom: 40px; }
    .l .lRoutines .lRoutines-categories {
      max-width: 700px;
      margin: 0 auto; }
      .l .lRoutines .lRoutines-categories a {
        display: inline-block;
        border: 1px solid #4a60ff;
        border-radius: 5px;
        padding: 10px 16px 10px;
        color: #4a60ff;
        margin: 10px;
        cursor: pointer;
        transition: all 0.3s ease;
        font-size: 14pt; }
        .l .lRoutines .lRoutines-categories a:hover {
          background: #4a60ff;
          color: white; }
    .l .lRoutines .lRoutines-popular {
      white-space: nowrap;
      overflow-x: scroll;
      padding: 20px; }
  .l .lTraining {
    text-align: center; }
    .l .lTraining > div {
      margin-bottom: 70px;
      max-width: 550px;
      margin-left: auto;
      margin-right: auto; }
    .l .lTraining h2 {
      font-size: 20pt;
      margin-bottom: 40px; }
      .l .lTraining h2 span {
        position: relative;
        display: inline-block;
        margin-right: 7px;
        font-weight: 700; }
      .l .lTraining h2 span::before {
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 50px;
        background: #0edc0e;
        display: block;
        position: absolute;
        top: 2px;
        left: -4px;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); }
    .l .lTraining p {
      opacity: 0.6;
      margin-bottom: 25px;
      font-size: 12pt;
      line-height: 18pt; }
      .l .lTraining p b {
        font-weight: 600;
        font-weight: normal; }
    .l .lTraining a {
      border: 1px solid #4a60ff;
      color: #4a60ff;
      padding: 10px 16px 8px;
      border-radius: 5px;
      display: inline-block; }
      .l .lTraining a:hover {
        background: #4a60ff;
        color: white; }
  .l .lMusic {
    background: #111;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: -40px;
    margin-right: -40px;
    width: calc(100% + 80px);
    text-align: center;
    color: white;
    position: relative; }
    .l .lMusic h2 {
      font-size: 20pt;
      margin-bottom: 20px; }
    .l .lMusic p {
      margin: 20px 0 60px;
      opacity: 0.7;
      font-size: 12pt; }
    .l .lMusic iframe {
      border-radius: 10px;
      overflow: hidden;
      border: 2px solid #4a60ff;
      padding: 5px 5px 0;
      width: 90%;
      max-width: 500px; }
    .l .lMusic .notes {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: none; }
      .l .lMusic .notes .note {
        width: 30px;
        height: 30px;
        opacity: 0.15;
        position: absolute; }
      .l .lMusic .notes .note:nth-of-type(1) {
        left: 80%;
        top: 50%; }
      .l .lMusic .notes .note:nth-of-type(2) {
        left: 80.2%;
        top: 50.2%; }
      .l .lMusic .notes .note:nth-of-type(3) {
        left: 20%;
        top: 78%; }
      .l .lMusic .notes .note:nth-of-type(4) {
        left: 20.2%;
        top: 78.2%; }
      .l .lMusic .notes .note:nth-of-type(5) {
        left: 22%;
        top: 15%;
        -webkit-transform: rotate(-10deg);
                transform: rotate(-10deg); }
      .l .lMusic .notes .note:nth-of-type(6) {
        left: 22.2%;
        top: 15.2%;
        -webkit-transform: rotate(-10deg);
                transform: rotate(-10deg); }
      .l .lMusic .notes .note:nth-of-type(7) {
        left: 72%;
        top: 16%;
        -webkit-transform: rotate(10deg);
                transform: rotate(10deg); }
      .l .lMusic .notes .note:nth-of-type(8) {
        left: 72.2%;
        top: 16.2%;
        -webkit-transform: rotate(10deg);
                transform: rotate(10deg); }
      .l .lMusic .notes .note:nth-of-type(9) {
        left: 14%;
        top: 50%;
        -webkit-transform: rotate(10deg);
                transform: rotate(10deg); }
      .l .lMusic .notes .note:nth-of-type(10) {
        left: 14.2%;
        top: 50.2%;
        -webkit-transform: rotate(10deg);
                transform: rotate(10deg); }
      .l .lMusic .notes .note:nth-of-type(11) {
        left: 75%;
        top: 80%;
        -webkit-transform: rotate(10deg);
                transform: rotate(10deg); }
      .l .lMusic .notes .note:nth-of-type(12) {
        left: 75.2%;
        top: 80.2%;
        -webkit-transform: rotate(10deg);
                transform: rotate(10deg); }
  .l .lReviews {
    background: #F7F9FA;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: -40px;
    margin-right: -40px;
    width: calc(100% + 80px);
    text-align: center; }
    .l .lReviews h2 {
      font-size: 20pt;
      margin-bottom: 20px; }
    .l .lReviews .write-review {
      background: #4a60ff;
      display: inline-block;
      border: 2px solid #4a60ff;
      border-radius: 5px;
      padding: 12px 25px;
      font-weight: 500;
      font-size: 12pt;
      color: white;
      cursor: pointer;
      box-shadow: 0 3px 10px 0 #959dd8;
      transition: all 0.3s ease;
      margin-top: 40px; }
      .l .lReviews .write-review:hover {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
        box-shadow: 0 3px 30px 0 #959dd8; }
    .l .lReviews .slider {
      padding: 20px 0 60px; }
      .l .lReviews .slider .slider-control-centerright,
      .l .lReviews .slider .slider-control-centerleft {
        display: none; }
      .l .lReviews .slider .slider-list {
        height: 180px !important; }
      .l .lReviews .slider .slider-slide {
        height: 100% !important; }
        .l .lReviews .slider .slider-slide .slide {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center; }
          .l .lReviews .slider .slider-slide .slide .stars span {
            background-size: 25px 22px;
            height: 22px;
            width: 125px;
            display: block;
            margin: 20px auto 30px;
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAWCAYAAAA1vze2AAAA40lEQVR42rXW3Q2DIBAH8I7Ae18cgREcgQ3qBtgJHEFGcQO6QR2Btz7WDejZXHL1EtIDxOQfHvj4gRjwkvO87tfHvzYxRp4swEDiXrZEHCKuJRIQCeciBOgvQNEtkJEhYwtkZchai3BAHQCKEiP4ab6pc31wPIPIYbb+JMBDVOp17ZgtXRX2s6k94VAH8QWz7+QbT5gVApZ65SNKiKgaZBAiQw2yCJGlBtmEyFaA0N3B8oTcsOR1pgRxbJCJ1U+s3pUg4Wf2Onn806qCFOF3xwxRgsNzxvZdDmIgfeYPRg/RKeQDVyEPzMDbhwwAAAAASUVORK5CYII=); }
          .l .lReviews .slider .slider-slide .slide p {
            max-width: 550px;
            margin: 0 auto;
            line-height: 1.6; }
  .l .lBlog {
    padding-left: 40px;
    padding-right: 40px;
    margin-left: -40px;
    margin-right: -40px;
    width: calc(100% + 80px); }
    .l .lBlog .blog-top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
      align-items: baseline; }
      .l .lBlog .blog-top h2 {
        text-transform: uppercase;
        font-size: 20pt; }
      .l .lBlog .blog-top a {
        display: inline-block;
        position: relative; }
        .l .lBlog .blog-top a::after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          height: 1px;
          background: #111;
          bottom: -3px;
          transition: all 0.2s ease; }
        .l .lBlog .blog-top a:hover::after {
          bottom: -5px; }
    .l .lBlog .blog-preview {
      display: flex;
      justify-content: space-between; }
      .l .lBlog .blog-preview .article {
        width: calc(33% - 20px);
        overflow: hidden; }
        .l .lBlog .blog-preview .article .article-img {
          width: 100%;
          height: 220px;
          border-radius: 5px;
          overflow: hidden; }
          .l .lBlog .blog-preview .article .article-img .blur {
            width: 100%;
            height: 100%; }
          .l .lBlog .blog-preview .article .article-img img {
            object-fit: cover;
            width: 100%;
            height: 100%; }
        .l .lBlog .blog-preview .article h3 {
          font-weight: 600;
          margin: 20px 0 10px;
          font-size: 14pt; }
        .l .lBlog .blog-preview .article p {
          opacity: 0.65;
          font-size: 11pt;
          line-height: 17pt; }
        .l .lBlog .blog-preview .article a {
          display: flex;
          align-items: center;
          color: #4a60ff;
          margin-top: 20px;
          font-size: 11pt; }
          .l .lBlog .blog-preview .article a svg {
            width: 12px;
            height: 12px;
            fill: #4a60ff;
            -webkit-transform: rotate(180deg);
                    transform: rotate(180deg);
            margin-left: 6px;
            transition: all 0.3s ease; }
          .l .lBlog .blog-preview .article a:hover svg {
            -webkit-transform: rotate(180deg) translateX(-5px);
                    transform: rotate(180deg) translateX(-5px); }
  .l .lNewsletter {
    padding-left: 40px;
    padding-right: 40px;
    margin-left: -40px;
    margin-right: -40px;
    width: calc(100% + 80px);
    background: #F7F9FA;
    border-bottom: 1px solid #4a60ff; }
    .l .lNewsletter #mc_embed_signup_scroll {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      max-width: 500px;
      margin: 0 auto;
      position: relative; }
      .l .lNewsletter #mc_embed_signup_scroll .block {
        display: flex;
        margin-top: 25px;
        width: 100%;
        border: 1px solid #4a60ff;
        border-radius: 500px;
        overflow: hidden; }
      .l .lNewsletter #mc_embed_signup_scroll label {
        font-weight: 700;
        text-align: center;
        font-size: 20pt;
        text-transform: capitalize; }
      .l .lNewsletter #mc_embed_signup_scroll p {
        opacity: 0.7;
        font-size: 13pt;
        margin-top: 15px;
        margin-bottom: 20px; }
      .l .lNewsletter #mc_embed_signup_scroll input[type='email'] {
        width: 70%;
        background: white;
        border: 0;
        padding: 10px 20px;
        font-size: 12pt;
        color: #4a60ff;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        box-shadow: none;
        border-right: 1px solid #4a60ff;
        height: 40px; }
      .l .lNewsletter #mc_embed_signup_scroll input[type='email']::-webkit-input-placeholder {
        color: #4a60ff;
        text-transform: capitalize; }
      .l .lNewsletter #mc_embed_signup_scroll input[type='email']::-ms-input-placeholder {
        color: #4a60ff;
        text-transform: capitalize; }
      .l .lNewsletter #mc_embed_signup_scroll input[type='email']::placeholder {
        color: #4a60ff;
        text-transform: capitalize; }
      .l .lNewsletter #mc_embed_signup_scroll .clear {
        width: 30%; }
      .l .lNewsletter #mc_embed_signup_scroll input[type='submit'] {
        width: 100%;
        background: #4a60ff;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        color: white;
        padding: 10px;
        font-size: 12pt;
        border: 0;
        height: 40px; }
  .l footer {
    background: #406aff;
    margin-left: -40px;
    margin-right: -40px;
    display: flex;
    justify-content: center; }
  .l .lFooter {
    padding-left: 40px;
    padding-right: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    max-width: 600px;
    background: #406aff;
    color: white;
    margin: 0 auto; }
    .l .lFooter p {
      display: inline-block;
      font-size: 10pt; }
    .l .lFooter .extra {
      color: #acc0ff;
      font-weight: 600;
      margin-left: 15px;
      font-size: 10pt; }
    .l .lFooter img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      -webkit-filter: invert(1);
              filter: invert(1); }
    .l .lFooter .footer-left {
      margin-bottom: 10px; }
    .l .lFooter .footer-extra {
      width: 100%;
      display: flex;
      flex-wrap: wrap; }
      .l .lFooter .footer-extra ul {
        padding: 0;
        list-style: none;
        margin: 40px 80px 0 0; }
        .l .lFooter .footer-extra ul h3 {
          font-weight: 800;
          color: #fff;
          font-size: 11pt;
          margin-bottom: 20px; }
        .l .lFooter .footer-extra ul a {
          display: block;
          margin: 10px 0;
          font-size: 11pt;
          opacity: 0.8;
          transition: all 0.3s ease; }
          .l .lFooter .footer-extra ul a:hover {
            opacity: 1; }

.components h1 {
  border-bottom: 2px solid white;
  padding-bottom: 5px;
  display: inline-block; }

.components p.component-title {
  margin: 20px 0 10px 0; }

.components .component-section {
  margin: 40px 0; }

.components .inline-spacer {
  margin: 0 10px; }

.raise {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1); }

.t-raise {
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2); }

.button-raise {
  transition: all 0.3s ease;
  cursor: pointer;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1); }

.button-raise:hover {
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.16) !important; }

.social-button {
  border-radius: 5px;
  background-color: #2c42ee;
  color: white;
  height: 50px;
  padding: 7px 25px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center; }
  .social-button p {
    display: inline-block;
    margin-right: 10px;
    font-family: GT-Medium, sans-serif; }

.download-button {
  padding-left: 10px;
  padding-right: 10px; }

.like-button.liked {
  background-color: #14239a; }

.purchase-button {
  border-radius: 5px;
  background-color: transparent;
  color: white;
  height: 50px;
  padding: 3px 20px 0 25px;
  text-align: center;
  border: 2px solid white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: GT-Medium, sans-serif;
  letter-spacing: 0.15rem;
  text-shadow: 0px 6px 10px rgba(0, 0, 0, 0.3); }

.info-button {
  background-color: #14239a;
  font-family: GT-Medium, sans-serif;
  font-size: 0.8rem;
  padding: 6px 10px;
  cursor: default;
  border-radius: 5px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 30px; }
  .info-button p {
    display: inline-block; }
  .info-button img {
    height: 18px;
    margin-right: 5px; }

.step-button {
  font-family: GT-Bold;
  background-color: rgba(0, 0, 0, 0.6); }

.blog-button {
  border-radius: 5px;
  background-color: rgba(20, 35, 154, 0.2);
  color: white;
  height: 45px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 20px;
  padding-right: 20px;
  min-width: 100px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 0.1rem; }
  .blog-button img {
    margin-right: -3px;
    margin-left: 3px; }
  .blog-button p {
    font-family: GT-Medium, sans-serif;
    margin-top: 2px; }
  .blog-button img, .blog-button p {
    opacity: 0.6;
    pointer-events: none; }

.blog-button.active-filter {
  background-color: rgba(20, 35, 154, 0.6); }
  .blog-button.active-filter img, .blog-button.active-filter p {
    opacity: 1; }

.input {
  height: 50px;
  border-radius: 5px;
  border: 0;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  font-family: GT-Medium, sans-serif;
  font-size: 1.2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 15px 0;
  transition: all 0.3s ease; }

.input:focus {
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px); }

.input::-webkit-input-placeholder {
  color: #4758da; }

.input::-ms-input-placeholder {
  color: #4758da; }

.input::placeholder {
  color: #4758da; }

.input.input-center {
  text-align: center; }

.input.input-month, .input.input-day {
  width: 70px; }

.input.input-year {
  width: 90px; }

.option {
  height: 50px;
  border-radius: 5px;
  border: 0;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  font-family: GT-Medium, sans-serif;
  font-size: 1.2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 15px 0;
  min-width: 60px;
  transition: all 0.3s ease; }

.option:hover {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1); }

.option.active-option {
  background-color: rgba(0, 0, 0, 0.6); }

.textarea {
  height: 150px;
  border-radius: 5px;
  border: 0;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  font-size: 1.2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  width: 100%;
  font-family: Poppins, GT-Regular, sans-serif;
  resize: none; }

.textarea::-webkit-input-placeholder {
  color: #4758da;
  font-family: Poppins, GT-Regular, sans-serif; }

.textarea::-ms-input-placeholder {
  color: #4758da;
  font-family: Poppins, GT-Regular, sans-serif; }

.textarea::placeholder {
  color: #4758da;
  font-family: Poppins, GT-Regular, sans-serif; }

.routine-tags {
  margin: 10px 0; }
  .routine-tags p {
    margin-right: 10px;
    font-family: Poppins, sans-serif;
    background-color: #4458f9;
    padding: 4px 10px 3px 0;
    border-radius: 5px;
    font-size: 0.8rem;
    height: 30px;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    color: #deddff; }
  .routine-tags p:before {
    content: '#';
    padding: 4px 8px;
    color: #8592fb; }

.activity-block, .notif-block {
  margin: 30px 0; }
  .activity-block p, .notif-block p {
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 1.2rem; }
    .activity-block p span, .notif-block p span {
      font-style: italic;
      font-weight: 500; }
    .activity-block p a, .notif-block p a {
      font-weight: 700;
      display: inline-block;
      position: relative;
      transition: all 0.2s ease; }
    .activity-block p a:hover, .notif-block p a:hover {
      -webkit-transform: translateX(5px);
              transform: translateX(5px); }
    .activity-block p a:after, .notif-block p a:after {
      content: '';
      width: 100%;
      height: 3px;
      background: #5c8aff;
      left: 0;
      bottom: -2px;
      position: absolute;
      border-radius: 500px; }

.items-block {
  margin-bottom: 15px; }
  .items-block .program p {
    font-family: GT-Bold, sans-serif;
    text-transform: uppercase;
    font-size: 0.9rem;
    letter-spacing: -0.4px;
    text-shadow: 0px 2px 8px black; }
  .items-block .program small {
    display: block;
    margin-top: 10px;
    font-family: GT-Bold, sans-serif;
    text-shadow: 0px 1px 8px black;
    text-transform: uppercase;
    font-size: 0.75rem; }
  .items-block .program .hypertrophy {
    background: #a6663c;
    background: -webkit-gradient(left top, left bottom, color-stop(0%, #a6663c), color-stop(100%, #c24f40));
    background: linear-gradient(to bottom, #a6663c 0%, #c24f40 100%);
    border-radius: 5px;
    width: 100%;
    padding: 23px 25px 20px; }
  .items-block .program .endurance {
    background: #3c8ea6;
    background: -webkit-gradient(left top, left bottom, color-stop(0%, #3c8ea6), color-stop(100%, #4068c2));
    background: linear-gradient(to bottom, #3c8ea6 0%, #4068c2 100%);
    border-radius: 5px;
    width: 100%;
    padding: 23px 25px 20px; }
  .items-block .program .strength {
    background: #d64545;
    background: -webkit-gradient(left top, left bottom, color-stop(0%, #d64545), color-stop(100%, #a23535));
    background: linear-gradient(to bottom, #d64545 0%, #a23535 100%);
    border-radius: 5px;
    width: 100%;
    padding: 23px 25px 20px; }
  .items-block .routine > div {
    position: relative;
    border-radius: 5px;
    height: 130px; }
  .items-block .routine .routine-bg {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    background-color: #223bff;
    display: flex;
    align-items: flex-end;
    padding: 20px; }
    .items-block .routine .routine-bg p {
      font-family: GT-Bold, sans-serif;
      text-transform: uppercase;
      font-size: 1rem;
      letter-spacing: -0.4px;
      text-shadow: 0px 2px 8px black; }

.review-block {
  margin-bottom: 30px;
  font-family: Poppins, sans-serif; }
  .review-block .rating i {
    opacity: 0.5;
    margin-right: 5px;
    font-size: 1.4rem; }
  .review-block .rating i.yellow-star {
    color: #ffff0a;
    opacity: 1; }
  .review-block .timeago {
    font-family: Poppins, sans-serif;
    font-size: 0.9rem;
    margin-top: 0px;
    opacity: 0.8; }
  .review-block .title {
    font-weight: 600;
    font-size: 2rem; }
  .review-block .the-review {
    font-size: 1.2rem; }
  .review-block .reviewer {
    font-size: 0.9rem;
    opacity: 0.8;
    margin-top: 10px; }
    .review-block .reviewer a {
      font-weight: 600;
      display: inline-block;
      transition: all 0.3s ease; }
    .review-block .reviewer a:hover {
      -webkit-transform: translateX(3px);
              transform: translateX(3px); }

.unseen .notif-block {
  background: rgba(0, 0, 0, 0.3); }

.notif-block {
  background: rgba(0, 0, 0, 0.1);
  padding: 10px 15px;
  margin: 15px 0;
  border-radius: 5px;
  cursor: default; }
  .notif-block .notif-user {
    font-weight: 500;
    font-style: italic; }
  .notif-block .notif-user:hover {
    -webkit-transform: none;
            transform: none; }
  .notif-block .notif-user:after {
    content: none; }
  .notif-block .program span {
    text-transform: capitalize;
    font-weight: 700;
    font-style: normal;
    font-size: 110%; }

.focus-area {
  border: 0;
  background: transparent;
  color: white;
  font-family: Poppins, sans-serif;
  font-size: 0.9rem;
  cursor: pointer;
  padding: 0;
  display: inline-flex;
  margin-bottom: 8px;
  margin-right: 8px; }
  .focus-area div {
    display: inline-flex;
    align-items: center; }
  .focus-area img {
    background: #3a4cf9;
    padding: 5px 10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; }
  .focus-area span {
    padding: 5px 12px;
    display: flex;
    height: 42px;
    align-items: center;
    background: #18267b;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px; }

.left-sidebar {
  width: 170px;
  position: fixed;
  left: 40px;
  top: 70px;
  height: calc(100vh - 70px);
  max-height: calc(100vh - 70px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 30px;
  border-right: 1px solid #e6ecf1; }
  .left-sidebar .copyright {
    margin-top: 40px; }
    .left-sidebar .copyright .social-media img {
      height: 16px;
      width: 16px;
      margin-right: 5px;
      margin-bottom: 10px; }
    .left-sidebar .copyright .extra {
      margin-bottom: 5px;
      color: #4250ff;
      font-weight: 700;
      font-size: 8pt;
      display: block; }
    .left-sidebar .copyright p {
      font-size: 8pt; }

.rC {
  padding-top: 40px; }
  .rC h3 {
    font-size: 10pt;
    font-weight: 700;
    margin-bottom: 10px;
    text-transform: uppercase; }
  .rC .rC-cat {
    font-size: 10pt;
    border-radius: 5px;
    padding: 5px 0;
    color: #111;
    display: block;
    opacity: 0.6;
    transition: all 0.3s ease; }
    .rC .rC-cat:hover, .rC .rC-cat.activeSide {
      opacity: 1;
      -webkit-transform: scale(1.05) translateX(10px);
              transform: scale(1.05) translateX(10px); }

.rH .rH-section:not(:first-child) {
  margin-bottom: 40px; }

.rH .rH-section .rH-section-routines {
  white-space: nowrap;
  overflow-x: scroll; }
  .rH .rH-section .rH-section-routines .rB {
    margin-bottom: 0; }
  .rH .rH-section .rH-section-routines.list {
    white-space: normal; }
    .rH .rH-section .rH-section-routines.list .rB {
      margin-bottom: 15px; }

.rH .rH-section .subheader {
  opacity: 0.6;
  margin-top: 20px;
  font-size: 14pt; }

.rH .rH-section .rH-section-more a {
  display: flex;
  align-items: center; }
  .rH .rH-section .rH-section-more a img {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    transition: all 0.3s;
    padding-top: 7px;
    padding-bottom: 2px;
    margin-bottom: 2px; }

.rH .rH-section .rH-section-more:hover img {
  -webkit-transform: translateX(5px);
  transform: translateX(5px); }

.categories .category .category-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px; }
  .categories .category .category-top a {
    display: flex;
    align-items: center; }
  .categories .category .category-top p {
    display: inline-block;
    text-transform: uppercase;
    font-size: 0.8rem;
    margin-right: 10px; }
  .categories .category .category-top .category-title {
    font-weight: 500;
    display: flex;
    align-items: center;
    color: #4258ff; }
    .categories .category .category-top .category-title span {
      font-size: 0.8rem;
      background: #e0e1e6;
      border-radius: 5px;
      padding: 3px 12px;
      font-style: normal;
      font-weight: 500;
      display: inline-block;
      margin-left: 20px;
      color: #6e6e80;
      border: 1px solid #d8d2d2; }

.categories > .category {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  background: #f7f9fa;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05); }

.categories > .category:first-child {
  margin-top: 0px; }

.rB {
  position: relative;
  z-index: 1;
  color: white;
  width: 200px;
  margin-right: 15px;
  display: inline-block;
  transition: all 0.2s ease-in-out;
  white-space: normal;
  border-radius: 10px;
  flex-direction: column; }
  .rB > a {
    display: inline-flex;
    border-radius: 5px;
    justify-content: space-between;
    flex-direction: column;
    color: #111; }
    .rB > a > span {
      width: 100%;
      height: 100%; }
  .rB > a:first-child {
    width: 200px;
    height: 250px;
    margin-bottom: 20px; }
  .rB h3 {
    font-size: 11pt;
    margin-bottom: 10px;
    font-weight: 700; }
  .rB .rB-cat {
    padding: 5px 10px 4px;
    border-radius: 5px;
    display: inline-block;
    font-size: 10pt;
    margin-right: 5px;
    margin-bottom: 5px;
    color: #4a60ff;
    border: 1px solid #4a60ff; }
  .rB small {
    font-size: 10pt;
    opacity: 0.6;
    margin-top: 30px;
    display: none; }
  .rB .rB-pic {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    z-index: 1;
    border: 2px solid #4a60ff;
    box-shadow: 0 0 10px 0 rgba(68, 68, 181, 0.3); }
  .rB .on-top {
    z-index: 2; }

.rB:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03); }

.blog .blog-filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 80px; }

.blog .blog-articles .article-block-wrapper {
  width: 100%;
  position: relative;
  margin-bottom: 25px;
  border-radius: 5px;
  padding: 0; }
  @media (min-width: 1100px) {
    .blog .blog-articles .article-block-wrapper {
      width: calc(50% - 25px);
      margin-right: 12.5px;
      margin-left: 12.5px; } }

.blog .blog-articles .article-block-img {
  opacity: 0.8;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  width: 100%; }

.blog .blog-articles .article-block-type {
  position: absolute;
  top: 10px;
  left: 10px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  padding: 5px; }

.blog .blog-articles .article-block {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-direction: column;
  padding: 30px;
  font-family: Poppins, san-serif; }
  .blog .blog-articles .article-block p {
    font-size: 0.875rem;
    line-height: 1.4;
    margin-bottom: 15px;
    opacity: 0.8; }
  .blog .blog-articles .article-block small {
    font-size: 0.8rem;
    line-height: 1.1rem;
    opacity: 0.8; }
    .blog .blog-articles .article-block small .blog-content {
      display: inline; }
      .blog .blog-articles .article-block small .blog-content p {
        display: inline; }
  .blog .blog-articles .article-block h3 {
    line-height: 2rem;
    font-size: 1.7rem;
    margin-bottom: 12px;
    transition: all 0.3s ease; }
    .blog .blog-articles .article-block h3:hover {
      text-decoration: underline; }
  .blog .blog-articles .article-block .article-block-date {
    display: block; }

.r {
  width: 100%; }
  .r .r-bar {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .r .r-bar .r-back svg {
      height: 30px;
      width: 30px;
      cursor: pointer; }
    .r .r-bar .r-tabs {
      border-radius: 5px;
      background: #F7F9FA;
      position: relative;
      padding: 5px; }
      .r .r-bar .r-tabs .r-tab {
        font-size: 11pt;
        width: 100px;
        display: inline-block;
        text-align: center;
        padding: 12px 0;
        cursor: pointer;
        position: relative;
        color: #555; }
      .r .r-bar .r-tabs .r-tab.active {
        color: #406aff; }
      .r .r-bar .r-tabs #r-tab-indicator {
        position: absolute;
        border-radius: 5px;
        top: 5px;
        bottom: 5px;
        width: 100px;
        left: 5px;
        transition: all 0.3s ease;
        border: 1px solid #406aff; }

.rR1 .rR1-seperator {
  display: flex; }
  .rR1 .rR1-seperator .rR1-price {
    position: relative;
    margin-top: 30px; }
    .rR1 .rR1-seperator .rR1-price .price {
      position: absolute;
      top: -40px;
      right: -25px;
      background: white;
      border-radius: 500px;
      border: 1px solid #ececec;
      padding: 5px;
      z-index: 1;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14pt; }
  .rR1 .rR1-seperator .rB {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
    margin-left: 15px; }
  .rR1 .rR1-seperator .rR1-info {
    width: calc(100% - 300px);
    max-width: 700px;
    margin-left: 40px;
    margin-bottom: 30px; }
    .rR1 .rR1-seperator .rR1-info .on-top {
      margin-bottom: 20px; }
      .rR1 .rR1-seperator .rR1-info .on-top h3 {
        font-size: 16pt;
        margin-bottom: 10px;
        font-weight: 700; }
      .rR1 .rR1-seperator .rR1-info .on-top .rB-cat {
        padding: 5px 10px 4px;
        border-radius: 5px;
        display: inline-block;
        font-size: 10pt;
        margin-right: 5px;
        margin-bottom: 5px;
        color: #4a60ff;
        border: 1px solid #4a60ff; }
    .rR1 .rR1-seperator .rR1-info .routine-stats p {
      display: inline-flex;
      align-items: center;
      margin-right: 30px; }
      .rR1 .rR1-seperator .rR1-info .routine-stats p svg {
        margin-right: 10px; }
    .rR1 .rR1-seperator .rR1-info .routine-info {
      margin: 20px 0; }
      .rR1 .rR1-seperator .rR1-info .routine-info p {
        margin-bottom: 20px;
        line-height: 16pt; }
    .rR1 .rR1-seperator .rR1-info .rR1-btns {
      display: flex;
      align-items: center;
      padding: 0;
      background: transparent;
      border: 0;
      cursor: pointer;
      transition: all 0.3s ease; }
      .rR1 .rR1-seperator .rR1-info .rR1-btns:hover {
        -webkit-transform: translateY(-5px);
                transform: translateY(-5px); }
      .rR1 .rR1-seperator .rR1-info .rR1-btns p {
        display: inline-flex;
        align-items: center;
        margin-right: 15px;
        border-radius: 5px;
        padding: 10px 15px;
        cursor: pointer;
        transition: all 0.3s ease; }
        .rR1 .rR1-seperator .rR1-info .rR1-btns p svg {
          margin-right: 10px; }
      .rR1 .rR1-seperator .rR1-info .rR1-btns .routine-price {
        padding: 0;
        margin: 0;
        font-size: 14pt;
        color: #406aff;
        cursor: default; }
      .rR1 .rR1-seperator .rR1-info .rR1-btns .add-to-wishlist {
        color: #f19db4;
        border: 1px solid #f4e2e7;
        box-shadow: -2px 2px 5px 0px rgba(244, 226, 231, 0.5);
        background: #fbf4f6; }
        .rR1 .rR1-seperator .rR1-info .rR1-btns .add-to-wishlist:hover {
          color: #f18aa6;
          border: 1px solid #f1d6dd;
          box-shadow: -2px 2px 5px 0px rgba(243, 212, 221, 0.5);
          background: #f9edf0; }
          .rR1 .rR1-seperator .rR1-info .rR1-btns .add-to-wishlist:hover svg {
            fill: #f18aa6; }
        .rR1 .rR1-seperator .rR1-info .rR1-btns .add-to-wishlist svg {
          fill: #f19db4; }
      .rR1 .rR1-seperator .rR1-info .rR1-btns .add-to-cart {
        color: #111;
        border: 1px solid #ececec;
        box-shadow: -2px 2px 5px 0px rgba(236, 236, 236, 0.5);
        background: #fff;
        padding: 15px 20px; }
        .rR1 .rR1-seperator .rR1-info .rR1-btns .add-to-cart:hover {
          border: 1px solid #e4e4e4;
          box-shadow: -2px 2px 5px 0px rgba(226, 226, 226, 0.5);
          background: #f4f8f9; }
        .rR1 .rR1-seperator .rR1-info .rR1-btns .add-to-cart svg {
          fill: #111;
          height: 16px;
          width: 16px; }
      .rR1 .rR1-seperator .rR1-info .rR1-btns .add-to-cart.added {
        color: #fff;
        border: 1px solid #406aff;
        box-shadow: -2px 2px 5px 0px rgba(236, 236, 236, 0.5);
        background: #406aff; }
        .rR1 .rR1-seperator .rR1-info .rR1-btns .add-to-cart.added svg {
          fill: #fff; }
      .rR1 .rR1-seperator .rR1-info .rR1-btns .play-routine {
        background: #406aff;
        color: white;
        border: 1px solid #ececec;
        box-shadow: -2px 2px 5px 0px rgba(236, 236, 236, 0.5);
        padding: 15px 20px 13px; }
        .rR1 .rR1-seperator .rR1-info .rR1-btns .play-routine svg {
          fill: #fff;
          height: 16px;
          width: 16px; }

.rR1 hr {
  margin: 40px 0 20px 0; }

.eList {
  position: relative;
  margin-bottom: 40px; }
  .eList .eList-hr {
    background: #f7f9fa;
    margin-top: 20px; }
  .eList .locked {
    background: linear-gradient(to bottom, rgba(125, 185, 232, 0) 0%, white 90%);
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center; }
    .eList .locked p {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #406aff;
      border-radius: 5px;
      padding: 20px;
      font-weight: 600;
      font-size: 12pt;
      text-transform: capitalize;
      line-height: 15pt;
      text-align: center; }
      .eList .locked p svg {
        margin-bottom: 5px;
        height: 24px;
        width: 24px;
        fill: #406aff !important; }
  .eList .eE:last-child {
    border-bottom: 0; }
  .eList .eE {
    padding-top: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ececec;
    padding-bottom: 20px;
    cursor: default;
    padding-left: 20px;
    padding-right: 20px;
    transition: all 0.2s ease; }
    .eList .eE.blur {
      -webkit-filter: blur(5px);
              filter: blur(5px); }
    .eList .eE:hover {
      background: #eceef1; }
    .eList .eE .eE-image {
      position: relative;
      z-index: 1;
      width: 113px;
      height: 200px;
      border-radius: 10px;
      border: 2px solid #f8fb2b;
      box-shadow: 0 0 10px 0 rgba(118, 128, 30, 0.3);
      margin-left: 5px;
      display: none; }
      .eList .eE .eE-image img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 10px; }
    .eList .eE .eE-info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .eList .eE .eE-info h3 {
        font-weight: 600;
        font-size: 14pt; }

.rR2 h2, .rR3 h2 {
  font-size: 22pt; }
  .rR2 h2 span, .rR3 h2 span {
    font-weight: 800;
    font-style: italic; }

.rR2 .rB {
  margin-bottom: 40px; }

.rR3 .rR3-block {
  background: #f6f9fa;
  padding: 40px;
  border-bottom: 1px solid #ececec;
  transition: all 0.3s ease; }
  .rR3 .rR3-block:hover {
    background: #eceef1; }

.rR3 .rR3-block:last-child {
  border-bottom: 0; }

.rR3 .rR3-title {
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 5px; }

.rR3 .rR3-review {
  font-size: 12pt;
  line-height: 20pt;
  margin: 5px 0 15px; }

.rR3 .rR3-info {
  display: flex;
  align-items: flex-end; }
  .rR3 .rR3-info > * {
    display: inline-block;
    margin-right: 10px;
    font-size: 11pt; }
  .rR3 .rR3-info .rR3-user {
    color: #384ef3; }
  .rR3 .rR3-info svg {
    height: 22px;
    width: 22px;
    margin-right: 2px; }

.rR3 .rR3-data {
  display: flex;
  align-items: center; }
  .rR3 .rR3-data .add-a-review {
    display: inline-flex;
    align-items: center;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    color: #111;
    border: 1px solid #ececec;
    box-shadow: -2px 2px 5px 0px rgba(236, 236, 236, 0.5);
    background: #fff;
    font-size: 10pt;
    margin-left: 20px; }
    .rR3 .rR3-data .add-a-review:hover {
      border: 1px solid #e4e4e4;
      box-shadow: -2px 2px 5px 0px rgba(226, 226, 226, 0.5);
      background: #f4f8f9; }
    .rR3 .rR3-data .add-a-review svg {
      fill: #111;
      margin-right: 10px;
      width: 20px;
      height: 20px; }

.rR3 .overall-rating {
  position: relative;
  height: 30px;
  width: 160px;
  margin-right: 20px; }
  .rR3 .overall-rating .empty-stars, .rR3 .overall-rating .full-stars {
    position: absolute;
    display: flex; }
    .rR3 .overall-rating .empty-stars svg, .rR3 .overall-rating .full-stars svg {
      min-height: 30px;
      min-width: 30px;
      height: 30px;
      width: 30px;
      margin-right: 2px; }
  .rR3 .overall-rating .full-stars {
    width: 50%;
    overflow: hidden; }

.review-add {
  opacity: 0;
  visibility: hidden;
  height: 0;
  overflow: hidden;
  position: fixed;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.2s ease;
  background: rgba(255, 255, 255, 0.95); }
  .review-add .review-container {
    max-width: 400px;
    width: 90%;
    border: 2px solid #406aff;
    border-radius: 5px;
    transition: all 0.3s ease;
    background: #fff;
    margin-top: 200px; }
    .review-add .review-container h3 {
      padding: 20px;
      font-size: 26pt;
      font-weight: 700; }
    .review-add .review-container .review-group {
      margin: 0 20px 10px;
      padding-bottom: 20px;
      display: flex;
      flex-direction: column; }
      .review-add .review-container .review-group input, .review-add .review-container .review-group textarea {
        border: 0;
        resize: none;
        padding: 10px;
        font-size: 12pt;
        border-bottom: 1px solid #406aff; }
      .review-add .review-container .review-group textarea {
        height: 120px; }
    .review-add .review-container .stars {
      flex-direction: row;
      align-items: center; }
      .review-add .review-container .stars span {
        margin-left: 20px;
        font-size: 10pt;
        opacity: 0.6; }
      .review-add .review-container .stars svg {
        width: 36px;
        height: 36px;
        fill: #fdf887;
        margin-right: 0;
        transition: all 0.3s ease;
        stroke: none;
        cursor: pointer; }
        .review-add .review-container .stars svg * {
          pointer-events: none; }
      .review-add .review-container .stars svg:hover, .review-add .review-container .stars svg.yellow-star {
        fill: #f1e924;
        -webkit-transform: translateY(-3px);
                transform: translateY(-3px); }
    .review-add .review-container .submit-review {
      border: 2px solid #111;
      background: white;
      color: #111;
      padding: 12px 10px 10px;
      border-radius: 5px;
      text-align: center;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.2s ease; }
      .review-add .review-container .submit-review:hover {
        background: #111;
        color: white; }

.review-add.show {
  height: auto;
  opacity: 1;
  visibility: visible; }
  .review-add.show .review-container {
    margin-top: 0; }

.exercise-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px; }
  .exercise-block .exercise-block-left {
    display: flex;
    align-items: center; }
    .exercise-block .exercise-block-left > img {
      height: 80px;
      border-radius: 5px;
      margin-right: 30px; }
    .exercise-block .exercise-block-left > div {
      cursor: pointer; }
      .exercise-block .exercise-block-left > div p {
        text-transform: uppercase;
        font-family: GT-Medium, sans-serif;
        pointer-events: none; }
      .exercise-block .exercise-block-left > div small {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-size: 0.7rem;
        letter-spacing: 0.05rem;
        pointer-events: none; }
        .exercise-block .exercise-block-left > div small img {
          margin-left: 10px;
          pointer-events: none; }
    .exercise-block .exercise-block-left > div:hover small {
      text-decoration: underline; }
  .exercise-block .exercise-block-right p {
    color: rgba(255, 255, 255, 0.6);
    font-family: GT-Light, sans-serif;
    font-size: 0.9rem; }

.exercise {
  position: fixed;
  right: -2000px;
  top: 0;
  width: calc(50% - 100px);
  min-width: 500px;
  max-width: 700px;
  z-index: 99;
  background: #172edc;
  height: 100vh;
  padding: 40px;
  transition: all 0.5s ease;
  box-shadow: -5px 0px 50px 0px rgba(0, 0, 0, 0.35); }
  .exercise .exercise-top {
    display: flex;
    align-items: center;
    margin-bottom: 40px; }
    .exercise .exercise-top > div {
      cursor: pointer; }
    .exercise .exercise-top > p {
      margin-left: 60px;
      margin-right: 20px;
      text-transform: uppercase;
      font-family: GT-Medium, sans-serif; }
    .exercise .exercise-top .info-button {
      text-transform: capitalize; }
  .exercise .exercise-middle {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3); }
    .exercise .exercise-middle .video {
      height: 400px; }
    .exercise .exercise-middle .exercise-amount {
      position: absolute;
      top: 20px;
      left: 20px;
      font-size: 0.8rem;
      font-family: GT-Medium, sans-serif;
      background-color: #213af9;
      padding: 6px 15px 5px;
      border-radius: 5px;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5); }
  .exercise .exercise-bottom {
    margin-top: 40px;
    font-family: GT-Light, sans-serif; }

.exercise.show {
  right: 0px; }

.custom-program .program-build {
  width: 100%; }
  .custom-program .program-build .contain {
    margin-bottom: 15px; }
  .custom-program .program-build .expand-info {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .custom-program .program-build .expand-info .toggleArrow {
      margin-left: 10px;
      width: 18px;
      cursor: pointer;
      transition: all 0.3s ease; }
    .custom-program .program-build .expand-info .toggleArrow.flipArrow {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .custom-program .program-build h1 {
    text-transform: uppercase;
    font-family: GT-Medium-Italic, sans-serif; }
  .custom-program .program-build small {
    margin: 30px 0 40px 0;
    display: block;
    font-size: 0.8rem;
    font-family: Poppins, GT-Regular, sans-serif; }
  .custom-program .program-build h2 {
    font-family: Poppins, sans-serif;
    font-size: 1.1rem;
    font-weight: 500; }
  .custom-program .program-build p {
    font-family: Poppins, sans-serif;
    font-size: 0.9rem;
    display: flex;
    align-items: center; }
    .custom-program .program-build p img {
      margin-left: 10px;
      width: 18px;
      margin-bottom: 2px;
      cursor: pointer; }
  .custom-program .program-build .personal-info {
    overflow: hidden;
    max-height: 0;
    transition: all 0.5s ease; }
    .custom-program .program-build .personal-info .basicinfo {
      margin-top: 30px; }
  .custom-program .program-build .personal-info.opened {
    max-height: 2000px; }
  .custom-program .program-build p.bolder {
    font-weight: 600;
    margin-bottom: 10px; }
  .custom-program .program-build .focus-area, .custom-program .program-build .infobtn {
    cursor: default; }
  .custom-program .program-build .infobtn {
    display: inline-flex;
    border-radius: 5px;
    margin-bottom: 8px;
    margin-right: 8px; }
  .custom-program .program-build .description, .custom-program .program-build .answer {
    line-height: 40px;
    padding: 0 12px;
    margin-bottom: 0; }
  .custom-program .program-build .description {
    background: #172686;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; }
  .custom-program .program-build .answer {
    font-weight: 500;
    background: #3a4cf9;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 1.1rem; }
    .custom-program .program-build .answer img {
      width: auto;
      margin: 0;
      cursor: default; }
  .custom-program .program-build .divider {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.08);
    margin: 20px 0; }
  .custom-program .program-build .build-step {
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .custom-program .program-build .build-step > .inline-block {
      display: block;
      width: 100%; }
    .custom-program .program-build .build-step .step-section-half {
      margin-top: 20px;
      width: 48%;
      display: inline-block; }
    .custom-program .program-build .build-step .step-section-full {
      margin-top: 20px;
      width: 100%;
      display: inline-block; }
    .custom-program .program-build .build-step .step-section-title {
      text-transform: uppercase;
      letter-spacing: 0.06rem;
      font-size: 0.95rem;
      margin-bottom: 3px; }
    .custom-program .program-build .build-step .option, .custom-program .program-build .build-step .input {
      margin-right: 10px; }
    .custom-program .program-build .build-step .option {
      border: 1px solid rgba(0, 0, 0, 0); }
    .custom-program .program-build .build-step .option.active-option {
      border: 1px solid rgba(0, 0, 0, 0.3);
      background-color: rgba(0, 0, 0, 0.4); }
    .custom-program .program-build .build-step .purchase-button {
      margin-top: 30px; }
    .custom-program .program-build .build-step .purchase-program {
      display: inline-block; }

.custom-program .program-cost {
  position: fixed;
  right: calc(50% - 56px - 370px);
  bottom: 40px; }
  .custom-program .program-cost .program-cost-title {
    text-transform: uppercase;
    font-family: GT-Medium, sans-serif;
    margin-bottom: 20px; }
  .custom-program .program-cost .cost-block {
    font-family: GT-Medium, sans-serif;
    margin-bottom: 10px; }
    .custom-program .program-cost .cost-block small {
      font-family: GT-Regular, sans-serif;
      font-size: 0.7rem;
      margin: 10px 0px 10px 5px; }
  .custom-program .program-cost .cost-total {
    font-family: GT-Medium, sans-serif;
    margin-top: 30px; }

.input-name {
  width: 100%; }

.input-height, .input-weight {
  width: 40%;
  min-width: 110px; }

.ReactTags__tags {
  margin-left: 50px;
  overflow: hidden; }

.ReactTags__selected {
  display: flex;
  align-items: center; }

.ReactTags__tagInput {
  display: inline-block;
  margin-left: 10px; }

input.ReactTags__tagInputField {
  border: 0;
  background: transparent;
  font-size: 12pt;
  font-family: 'SF', sans-serif; }

input.ReactTags__tagInputField::-webkit-input-placeholder {
  color: #CBCCCD; }

input.ReactTags__tagInputField::-ms-input-placeholder {
  color: #CBCCCD; }

input.ReactTags__tagInputField::placeholder {
  color: #CBCCCD; }

span.ReactTags__tag {
  font-size: 12pt;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background: white;
  padding: 5px;
  margin-right: 10px;
  font-family: 'SF', sans-serif; }

a.ReactTags__remove {
  margin-left: 5px; }

.community {
  font-family: Poppins, sans-serif; }
  .community .community-top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center; }
    .community .community-top .ask-button, .community .community-top .category-menu {
      background-color: #1a2dc2;
      font-size: 1rem;
      padding: 6px 15px;
      cursor: pointer;
      border-radius: 5px;
      text-align: center;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      font-weight: 500; }
      .community .community-top .ask-button img, .community .community-top .category-menu img {
        margin-left: 5px; }
    .community .community-top .community-filters {
      display: flex;
      position: relative; }
      .community .community-top .community-filters p {
        margin-right: 20px;
        font-size: 0.95rem;
        font-weight: 500;
        cursor: pointer;
        position: relative; }
      .community .community-top .community-filters > p:last-of-type {
        margin-right: 0; }
    .community .community-top .community-filters:after,
    .community .community-top .community-filters p:after {
      width: 100%;
      height: 6px;
      content: '';
      position: absolute;
      bottom: -10px;
      border-radius: 5px;
      left: 0; }
    .community .community-top .community-filters:after {
      background: #1a2ec7; }
    .community .community-top .community-filters p:after {
      background: white;
      z-index: 1;
      height: 6px;
      opacity: 0;
      transition: all 0.3s ease; }
    .community .community-top .community-filters p.active-filter:after {
      opacity: 1; }
  .community .community-feed {
    margin-top: 60px; }
    .community .community-feed .feed-block {
      background: rgba(0, 0, 0, 0.12);
      border-radius: 5px;
      padding: 10px 15px 15px;
      margin-bottom: 15px;
      position: relative;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05); }
      .community .community-feed .feed-block .feed-block-top {
        display: flex;
        align-items: center;
        margin-bottom: 10px; }
        .community .community-feed .feed-block .feed-block-top p {
          font-weight: 500;
          font-size: 1.2rem; }
      .community .community-feed .feed-block .feed-block-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .community .community-feed .feed-block .feed-block-bottom .feed-block-tags {
          margin: 0; }
        .community .community-feed .feed-block .feed-block-bottom .feed-block-info {
          display: flex;
          align-items: center; }
          .community .community-feed .feed-block .feed-block-bottom .feed-block-info div {
            display: flex;
            align-items: center; }
          .community .community-feed .feed-block .feed-block-bottom .feed-block-info div:first-of-type {
            margin-right: 20px; }
          .community .community-feed .feed-block .feed-block-bottom .feed-block-info p {
            display: inline-block;
            margin-left: 6px;
            font-weight: 600;
            font-size: 0.8rem; }
        .community .community-feed .feed-block .feed-block-bottom .feed-block-posted {
          display: flex;
          align-items: center; }
          .community .community-feed .feed-block .feed-block-bottom .feed-block-posted p {
            font-size: 0.7rem;
            opacity: 0.8;
            display: inline-block;
            margin-right: 10px; }
          .community .community-feed .feed-block .feed-block-bottom .feed-block-posted img {
            border-radius: 500px;
            height: 30px;
            width: 30px;
            margin-bottom: 3px; }

.ask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  padding: 20px; }
  .ask .ask-container {
    background-color: #1a2dc2;
    border-radius: 5px;
    width: 600px;
    height: auto;
    margin-bottom: 70px;
    padding: 40px;
    min-height: 420px;
    position: relative; }
    .ask .ask-container .close-ask {
      position: absolute;
      right: 30px;
      top: 20px;
      cursor: pointer; }
    .ask .ask-container .ask-block {
      margin-bottom: 20px; }
      .ask .ask-container .ask-block .ask-block-title {
        text-transform: uppercase;
        font-family: GT-Regular, Poppins, sans-serif;
        font-size: 0.95rem;
        margin-bottom: 3px;
        letter-spacing: 0.05rem; }
        .ask .ask-container .ask-block .ask-block-title span {
          text-transform: lowercase;
          font-size: 0.7rem; }
      .ask .ask-container .ask-block .input {
        width: 100%;
        background-color: #15259b; }
      .ask .ask-container .ask-block .textarea-description {
        height: 100px;
        font-size: 1.2rem;
        font-family: GT-Medium, sans-serif; }
      .ask .ask-container .ask-block .input-tags {
        display: none; }
      .ask .ask-container .ask-block tags {
        height: 50px;
        border-radius: 5px;
        border: 0;
        background-color: rgba(0, 0, 0, 0.2);
        color: white;
        font-family: GT-Medium, sans-serif;
        font-size: 1.2rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 4px 15px 0;
        transition: all 0.3s ease;
        width: 100%; }
        .ask .ask-container .ask-block tags tag {
          margin-bottom: 7px; }
      .ask .ask-container .ask-block tags > div {
        width: 100%; }
        .ask .ask-container .ask-block tags > div input {
          width: 100%; }
      .ask .ask-container .ask-block input.placeholder {
        height: 50px;
        background-color: transparent; }
    .ask .ask-container .ask-block-end {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .ask .ask-container .ask-block-end .ask-private {
        display: flex;
        align-items: center;
        font-family: Poppins, sans-serif; }
        .ask .ask-container .ask-block-end .ask-private .ask-private-btn {
          background-color: rgba(0, 0, 0, 0.2);
          border: 0;
          width: 23px;
          height: 20px;
          border-radius: 5px;
          cursor: pointer;
          position: relative; }
        .ask .ask-container .ask-block-end .ask-private .ask-private-btn:after {
          background-image: url(/assets/check.png);
          position: absolute;
          top: -5px;
          left: 4px;
          width: 23px;
          height: 20px; }
        .ask .ask-container .ask-block-end .ask-private .ask-private-btn.checked:after {
          content: ''; }
        .ask .ask-container .ask-block-end .ask-private p {
          font-size: 0.75rem;
          font-weight: 500;
          margin-left: 10px; }
      .ask .ask-container .ask-block-end .ask-button {
        background-color: #0a124e; }

.ask.hideAsk {
  display: none; }

.c .cS {
  display: flex; }
  .c .cS .cS-continue {
    background: #fff;
    border-radius: 5px;
    margin-bottom: 7px;
    padding: 9px 12px 8px;
    color: #4a60ff;
    border: 1px solid #4a60ff;
    display: block;
    text-align: center;
    transition: all 0.2s ease-in-out;
    font-size: 11pt; }
    .c .cS .cS-continue:hover {
      background: #f5faff; }
  .c .cS .cS-checkout {
    background: #4a60ff;
    border-radius: 5px;
    margin-bottom: 7px;
    padding: 9px 0px 8px;
    color: #fff;
    border: 1px solid #4a60ff;
    text-align: center;
    font-size: 11pt;
    width: 100%;
    display: block;
    cursor: pointer; }
  .c .cS .points-eligible {
    margin-top: 10px;
    font-size: 9pt;
    cursor: pointer;
    color: #406aff;
    border-bottom: 1px solid #406aff; }
  .c .cS .cS-total {
    margin: 50px 0 15px 0;
    display: flex;
    justify-content: space-between; }
    .c .cS .cS-total b {
      font-weight: 700;
      font-weight: normal;
      letter-spacing: 0.3pt; }

.c .cTop h2 {
  margin-bottom: 30px; }

.c .cTop p {
  margin-bottom: 20px;
  font-size: 11pt;
  line-height: 16pt; }
  .c .cTop p a {
    color: #5367ff;
    text-transform: capitalize; }

.c .cTop > p:last-child {
  margin-bottom: 0; }

.c .cItem:last-child {
  border-bottom: none; }

.c .cItem {
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  border-bottom: 1px solid #ececec;
  flex-direction: column; }
  .c .cItem > * {
    width: 100%; }
  .c .cItem .cItem-top {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .c .cItem h3 {
    font-weight: 700;
    font-size: 14pt;
    text-transform: capitalize; }
  .c .cItem .cItem-cat {
    padding: 4px 10px 4px;
    border-radius: 5px;
    display: inline-block;
    font-size: 10pt;
    margin-right: 5px;
    margin-top: 10px;
    color: #4a60ff;
    border: 1px solid #4a60ff;
    cursor: default; }
  .c .cItem .cItem-right {
    display: inline-flex;
    align-items: center; }
    .c .cItem .cItem-right .cItem-price {
      font-size: 14pt;
      margin-right: 30px; }
    .c .cItem .cItem-right .cItem-remove {
      position: relative;
      cursor: pointer; }
    .c .cItem .cItem-right svg {
      height: 16px;
      width: 16px;
      margin-left: 10px;
      cursor: pointer;
      pointer-events: none; }
  .c .cItem .cItem-info {
    background: #f7f9fa;
    padding: 10px 10px 0;
    border-radius: 5px;
    border: 1px solid #e1e9ec;
    margin-top: 20px; }
    .c .cItem .cItem-info .info-container {
      margin-bottom: 10px;
      border-bottom: 1px solid #e2e8ea;
      padding-bottom: 10px; }
      .c .cItem .cItem-info .info-container span {
        font-size: 9pt;
        text-transform: uppercase;
        margin-bottom: 5px;
        display: block; }
      .c .cItem .cItem-info .info-container p {
        font-weight: 700;
        text-transform: capitalize; }
      .c .cItem .cItem-info .info-container .cItem-cat {
        margin-bottom: 10px;
        margin-top: 0; }
    .c .cItem .cItem-info .info-container:last-child {
      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0; }

.cCheckout {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  height: 0;
  overflow: hidden;
  position: fixed;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.2s ease;
  background: rgba(255, 255, 255, 0.95); }
  .cCheckout #dropin-container {
    width: 400px; }
    .cCheckout #dropin-container .braintree-sheet {
      border: 2px solid #4a60ff;
      border-radius: 5px; }
  .cCheckout #submit-button {
    background: #4a60ff;
    color: white;
    font-size: 13pt;
    text-transform: capitalize;
    width: 400px;
    border-radius: 5px;
    padding: 14px 15px 12px;
    cursor: pointer;
    border: 0; }

.cCheckout.show {
  height: auto;
  opacity: 1;
  visibility: visible;
  pointer-events: auto; }

.cTY {
  width: 100%; }
  .cTY .cTY-top p {
    margin-top: 20px; }
  .cTY .cTY-purchased {
    margin-bottom: 40px;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #4a60ff; }
  .cTY .receipt {
    background: #f7f9fa;
    margin-left: 40px;
    margin-right: 40px;
    border-radius: 5px;
    max-width: 500px; }
  .cTY .cItem {
    margin: 0 0 30px; }
  .cTY .t-container p {
    padding: 10px 0;
    border-bottom: 1px solid #ececec; }
    .cTY .t-container p b {
      font-weight: 700;
      margin-left: 10px; }

.cart-thankyou {
  font-family: Poppins, sans-serif; }
  .cart-thankyou .top {
    display: flex;
    align-items: center;
    margin-bottom: 60px; }
    .cart-thankyou .top img {
      margin-right: 40px; }
    .cart-thankyou .top h1 {
      font-weight: 500; }
    .cart-thankyou .top p {
      font-size: 0.95rem; }
  .cart-thankyou .bottom {
    display: flex;
    align-items: center; }
    .cart-thankyou .bottom .receipt {
      background: #fff;
      width: 350px;
      height: auto;
      border-radius: 5px;
      margin-right: 50px;
      min-width: 350px;
      padding: 30px; }
      .cart-thankyou .bottom .receipt h2 {
        color: #2037ef;
        font-weight: 600;
        font-size: 1.4rem;
        margin-bottom: 30px;
        line-height: 1.4rem; }
      .cart-thankyou .bottom .receipt .receipt-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: black;
        font-size: 0.8rem;
        margin: 5px 0; }
      .cart-thankyou .bottom .receipt p.receipt-items {
        color: black;
        font-weight: 600;
        font-size: 0.9rem;
        margin: 20px 0 10px; }
      .cart-thankyou .bottom .receipt hr {
        margin: 20px 0; }
      .cart-thankyou .bottom .receipt .receipt-block {
        text-transform: capitalize; }
        .cart-thankyou .bottom .receipt .receipt-block h3 {
          font-weight: 600;
          font-size: 1rem; }
    .cart-thankyou .bottom .ready {
      margin-bottom: 50px; }
      .cart-thankyou .bottom .ready h1 {
        font-weight: 500;
        line-height: 2.6rem;
        font-size: 1.6rem; }
      .cart-thankyou .bottom .ready .routines-button {
        font-family: Poppins, sans-serif;
        font-size: 1.2rem;
        background-color: #0b1456;
        padding: 12px 40px;
        height: auto;
        margin: 10px 0 40px;
        cursor: pointer; }
        .cart-thankyou .bottom .ready .routines-button img {
          height: auto;
          margin-right: 10px; }
      .cart-thankyou .bottom .ready > p {
        max-width: 400px; }
        .cart-thankyou .bottom .ready > p i {
          font-family: Poppins, sans-serif !important; }
        .cart-thankyou .bottom .ready > p u {
          border-bottom: 1px solid white;
          line-height: 0.8rem;
          text-decoration: none; }

.question {
  padding-bottom: 50px; }
  .question .mainQ {
    font-family: Poppins, GT-Bold, sans-serif;
    font-weight: 600;
    margin-bottom: 30px;
    line-height: 2.2rem; }
  .question .question-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    background: #1728bf;
    padding: 10px 25px; }
    .question .question-data > div {
      display: flex; }
    .question .question-data .asked-by {
      display: flex;
      font-family: Poppins, sans-serif;
      align-items: center;
      font-size: 0.9rem;
      margin-right: 40px; }
      .question .question-data .asked-by > div, .question .question-data .asked-by > a {
        display: flex;
        align-items: center; }
      .question .question-data .asked-by .profile-img {
        border-radius: 500px;
        width: 25px;
        height: 25px;
        margin: 0 10px; }
      .question .question-data .asked-by .username {
        font-weight: 700;
        font-size: 0.8rem; }
    .question .question-data .comments, .question .question-data .views {
      display: flex;
      align-items: center;
      margin-left: 25px; }
      .question .question-data .comments p, .question .question-data .views p {
        font-family: Poppins, sans-serif;
        font-weight: 700;
        font-size: 0.9rem;
        margin-left: 10px; }
    .question .question-data .follow {
      display: flex;
      align-items: center;
      cursor: pointer; }
      .question .question-data .follow p {
        font-family: Poppins, sans-serif;
        font-weight: 500;
        font-size: 0.9rem;
        margin-left: 10px; }
  .question .question-description {
    margin: 30px 0; }
    .question .question-description p {
      font-family: Poppins, sans-serif;
      font-size: 0.9rem;
      margin-bottom: 15px;
      line-height: 1.2rem; }
  .question .question-tag-share {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px; }
    .question .question-tag-share .routine-tags {
      margin: 0; }
    .question .question-tag-share .share-container {
      display: flex;
      align-items: center; }
      .question .question-tag-share .share-container p {
        font-family: GT-Bold, sans-serif;
        margin-right: 15px; }
      .question .question-tag-share .share-container img {
        margin-left: 5px;
        cursor: pointer; }
  .question .post-comment .textarea {
    height: 70px;
    font-size: 0.9rem;
    line-height: 1rem; }
  .question .post-comment .info-button {
    cursor: pointer;
    background-color: #0a124e;
    font-family: GT-Bold, sans-serif;
    padding: 8px 15px;
    margin-top: 6px; }
    .question .post-comment .info-button p {
      font-size: 0.9rem; }
  .question .conversation {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    font-family: Poppins, sans-serif; }
    .question .conversation p {
      font-size: 0.9rem;
      line-height: 1.1rem; }
    .question .conversation .post-reply {
      max-height: 0;
      overflow: hidden;
      padding-left: 40px;
      margin-top: 10px;
      transition: all 0.3s ease; }
      .question .conversation .post-reply textarea {
        height: 70px;
        font-size: 0.9rem; }
      .question .conversation .post-reply .reply-post {
        margin-top: 0; }
      .question .conversation .post-reply > .inline-block {
        cursor: pointer; }
      .question .conversation .post-reply > .inline-block span {
        display: none; }
      .question .conversation .post-reply > .inline-block * {
        pointer-events: none; }
    .question .conversation .post-reply.openReplyBox {
      max-height: 2000px; }
    .question .conversation .reply-info {
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 5px;
      background: #223af5;
      padding: 5px 15px; }
      .question .conversation .reply-info .reply-user a {
        display: flex;
        align-items: center; }
      .question .conversation .reply-info .reply-user img {
        border-radius: 500px;
        width: 25px;
        height: 25px;
        margin: 0 10px; }
      .question .conversation .reply-info .reply-btn, .question .conversation .reply-info .reply-user {
        display: flex;
        align-items: center; }
      .question .conversation .reply-info .reply-btn {
        cursor: pointer; }
        .question .conversation .reply-info .reply-btn * {
          pointer-events: none; }
        .question .conversation .reply-info .reply-btn span {
          display: none; }
        .question .conversation .reply-info .reply-btn p {
          font-size: 0.8rem;
          margin-left: 5px; }
      .question .conversation .reply-info .reply-user p {
        font-size: 0.7rem; }
      .question .conversation .reply-info .reply-user .username {
        font-weight: 700; }
    .question .conversation .subreplies {
      padding-left: 40px;
      margin-top: 20px; }
      .question .conversation .subreplies .reply {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end; }
        .question .conversation .subreplies .reply > p {
          width: 100%; }
      .question .conversation .subreplies p {
        font-size: 0.8rem;
        line-height: 1rem; }
  .question .delete-reply, .question .delete-comment {
    background: rgba(164, 12, 12, 0.54);
    border-radius: 500px;
    display: inline-flex;
    margin-left: 10px;
    align-items: center;
    justify-content: center;
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    width: 20px;
    height: 20px;
    padding-right: 0.5px;
    padding-top: 1px;
    transition: all 0.3s ease;
    cursor: pointer; }
  .question .delete-reply:after, .question .delete-comment:after {
    content: "\D7"; }
  .question .delete-reply:hover, .question .delete-comment:hover {
    background: #a50c0c;
    box-shadow: 0px 0px 10px 0px #e40606; }
  .question #disqus_thread {
    margin-top: 10px; }

.cR .rB {
  margin-bottom: 40px; }

.cR .cR-title {
  display: flex;
  align-items: center; }
  .cR .cR-title .r-back svg {
    height: 30px;
    width: 30px;
    cursor: pointer;
    margin-right: 20px; }

.s {
  min-height: calc(100vh - 60px);
  margin-bottom: 80px; }
  .s .title {
    font-weight: bold;
    font-size: 30pt; }
  .s .sub {
    font-size: 16pt;
    margin-top: 20px;
    color: #304354;
    opacity: 0.6; }
  .s .s_container {
    margin-top: 60px;
    display: flex;
    box-shadow: 0 0 100px #ececec;
    border-radius: 5px;
    width: 100%;
    max-width: 1000px; }
    .s .s_container .tabs {
      margin: 0;
      list-style: none;
      width: 230px;
      padding: 40px 30px;
      border-right: 1px solid #ececec; }
      .s .s_container .tabs .tab {
        margin-bottom: 30px;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        align-items: center;
        color: #111;
        opacity: 0.6; }
        .s .s_container .tabs .tab * {
          pointer-events: none; }
        .s .s_container .tabs .tab svg {
          margin-right: 12px;
          width: 20px;
          height: 20px; }
        .s .s_container .tabs .tab.active {
          color: #111;
          opacity: 1; }
          .s .s_container .tabs .tab.active svg path {
            fill: #406aff; }
        .s .s_container .tabs .tab:last-child {
          margin-bottom: 0; }
    .s .s_container .content {
      padding: 40px 30px;
      width: 100%; }
      .s .s_container .content .section {
        display: none; }
        .s .s_container .content .section h3 {
          margin-bottom: 40px;
          font-weight: 500; }
        .s .s_container .content .section.active {
          display: block; }
      .s .s_container .content .group .label {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 9pt;
        opacity: 0.6;
        letter-spacing: 0.4pt;
        margin-bottom: 10px; }
      .s .s_container .content .group input,
      .s .s_container .content .group textarea,
      .s .s_container .content .group button,
      .s .s_container .content .group select {
        border: 1px solid #cfcfcf;
        border-radius: 5px;
        padding: 10px;
        font-size: 12pt;
        width: 100%; }
      .s .s_container .content .group select {
        height: 40px; }
      .s .s_container .content .group .wrap {
        display: flex;
        align-items: center; }
      .s .s_container .content .group .img-block {
        width: 40px;
        height: 40px;
        margin-right: 10px; }
        .s .s_container .content .group .img-block img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 500px; }
      .s .s_container .content .group .choose_pic {
        font-size: 10pt;
        border-bottom: 1px solid grey; }
      .s .s_container .content .group button {
        width: 70px;
        background: #e8ebf5;
        cursor: pointer;
        border-color: #cfd5e4;
        color: #8e96a9;
        box-shadow: 0 3px 5px #eef0f5; }
        .s .s_container .content .group button:hover {
          color: #406aff;
          border-color: #406aff; }
        .s .s_container .content .group button.active {
          color: white;
          border-color: #3357d8;
          background: #406aff;
          box-shadow: 0 3px 5px #c3cfee; }
      .s .s_container .content .group .cats-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap; }
        .s .s_container .content .group .cats-list button {
          width: auto;
          padding-left: 20px;
          padding-right: 20px;
          margin-right: 10px;
          margin-bottom: 10px; }
      .s .s_container .content .group .filepond--wrapper {
        width: calc(100% - 50px);
        height: 40px;
        background: #f1f0ef;
        border-radius: 5px; }
        .s .s_container .content .group .filepond--wrapper button {
          display: none; }
        .s .s_container .content .group .filepond--wrapper .filepond--root .filepond--drop-label {
          min-height: 40px; }
        .s .s_container .content .group .filepond--wrapper .filepond--file .filepond--progress-indicator {
          opacity: 1 !important;
          visibility: visible !important; }
      .s .s_container .content .group#height .wrap, .s .s_container .content .group#weight .wrap {
        justify-content: space-between; }
      .s .s_container .content .group#height input, .s .s_container .content .group#weight input {
        width: calc(100% - 160px);
        text-align: center; }
      .s .s_container .content .halfs-group {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 30px; }
        .s .s_container .content .halfs-group .group {
          width: calc(50% - 20px); }
        .s .s_container .content .halfs-group.special > .group:nth-of-type(1) {
          width: 150px; }
          .s .s_container .content .halfs-group.special > .group:nth-of-type(1) .value {
            font-size: 20pt;
            font-weight: 600; }
        .s .s_container .content .halfs-group.special > .group:nth-of-type(2) {
          width: auto; }
      .s .s_container .content .full-group {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 30px; }
        .s .s_container .content .full-group .group {
          width: 100%; }
      .s .s_container .content .three-group {
        display: flex;
        width: 100%;
        justify-content: space-between; }
        .s .s_container .content .three-group input {
          width: calc(33.33% - 10px);
          text-align: center; }
      .s .s_container .content .msg-container {
        display: flex;
        align-items: center; }
        .s .s_container .content .msg-container .msg {
          display: block;
          margin-left: 20px;
          color: #2ca965;
          opacity: 0;
          visibility: hidden; }
          .s .s_container .content .msg-container .msg.active {
            opacity: 1;
            visibility: visible; }
      .s .s_container .content .saveData,
      .s .s_container .content .savePassword {
        border-radius: 5px;
        font-size: 14pt;
        padding: 10px 30px;
        background: #2ca965;
        color: white;
        border: #369662;
        box-shadow: 0 3px 5px #b8e2cb;
        cursor: pointer;
        transition: all 0.3s ease; }
        .s .s_container .content .saveData:hover,
        .s .s_container .content .savePassword:hover {
          -webkit-transform: scale(1.05);
                  transform: scale(1.05);
          box-shadow: 0 3px 10px 0 #b8e2cb; }
      .s .s_container .content .tooltip.fade:after,
      .s .s_container .content .tooltip.fade:before {
        -webkit-transform: translate3d(0, -10px, 0);
                transform: translate3d(0, -10px, 0);
        transition: all 0.15s ease-in-out; }
      .s .s_container .content .tooltip.fade.showTooltip:after,
      .s .s_container .content .tooltip.fade.showTooltip:before {
        opacity: 1;
        -webkit-transform: translate3d(0, -28px, 0);
                transform: translate3d(0, -28px, 0); }
  .s .divider {
    width: 100%;
    margin: 50px 0;
    border: 1px solid #ececec;
    height: 1px;
    opacity: 0.5; }
  .s .cancel {
    display: inline-block;
    margin-top: 20px;
    border-radius: 5px;
    font-size: 12pt;
    padding: 8px 15px;
    background: #cf901a;
    color: white;
    border: #cf901a;
    box-shadow: 0 3px 5px #f5e9d4;
    cursor: pointer;
    transition: all 0.3s ease; }
  .s .logout {
    color: #e41c47;
    display: flex;
    font-size: 12pt;
    cursor: pointer;
    line-height: 2;
    opacity: 0.87;
    transition: all 0.3s ease; }
    .s .logout:hover {
      opacity: 1; }
  .s .delete {
    background: #e41c47;
    color: white;
    margin-top: 20px;
    display: inline-flex;
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 3px 5px #eeccd4; }

.routine-reviews {
  margin-top: 10px; }
  .routine-reviews .reviews-top {
    display: flex;
    align-items: baseline;
    justify-content: space-between; }
    .routine-reviews .reviews-top h1 {
      font-family: Poppins, sans-serif;
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      font-weight: 500; }
      .routine-reviews .reviews-top h1 span {
        display: inline-flex;
        padding: 6px 5px 5px;
        border-radius: 500px;
        background: #0e1861;
        align-items: center;
        text-align: center;
        justify-content: center;
        margin-left: 20px;
        font-size: 0.8rem;
        width: 30px;
        height: 30px;
        font-weight: 600; }
    .routine-reviews .reviews-top .rating i {
      margin-left: 5px; }
    .routine-reviews .reviews-top .rating .yellow-star {
      color: #f1e924; }
  .routine-reviews .empty-reviews {
    font-family: Poppins, sans-serif; }

.review {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  padding: 20px; }
  .review .review-container {
    background-color: #1a2dc2;
    border-radius: 5px;
    width: 600px;
    height: auto;
    margin-bottom: 70px;
    padding: 40px;
    min-height: 420px;
    position: relative; }
    .review .review-container .close-review {
      position: absolute;
      right: 30px;
      top: 20px;
      cursor: pointer; }
    .review .review-container h2 {
      font-family: Poppins, sans-serif;
      font-weight: 500;
      margin-top: -20px;
      margin-bottom: 30px; }
      .review .review-container h2 span {
        font-weight: 600; }
    .review .review-container .review-block {
      margin-bottom: 20px; }
      .review .review-container .review-block .textarea {
        font-family: Poppins, sans-serif;
        font-size: 1rem; }
      .review .review-container .review-block .review-block-title {
        font-family: GT-Regular, Poppins, sans-serif;
        font-size: 0.95rem;
        margin-bottom: 3px;
        letter-spacing: 0.05rem; }
        .review .review-container .review-block .review-block-title span {
          text-transform: lowercase;
          font-size: 0.7rem; }
      .review .review-container .review-block .input {
        width: 100%;
        background-color: #15259b;
        font-family: Poppins,sans-serif;
        font-weight: 500; }
      .review .review-container .review-block .textarea-description {
        height: 100px;
        font-size: 1.2rem;
        font-family: GT-Medium, sans-serif; }
      .review .review-container .review-block .input-tags {
        display: none; }
      .review .review-container .review-block tags {
        height: 50px;
        border-radius: 5px;
        border: 0;
        background-color: rgba(0, 0, 0, 0.2);
        color: white;
        font-family: GT-Medium, sans-serif;
        font-size: 1.2rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 4px 15px 0;
        transition: all 0.3s ease;
        width: 100%; }
        .review .review-container .review-block tags tag {
          margin-bottom: 7px; }
      .review .review-container .review-block tags > div {
        width: 100%; }
        .review .review-container .review-block tags > div input {
          width: 100%; }
      .review .review-container .review-block input.placeholder {
        height: 50px;
        background-color: transparent; }
    .review .review-container .review-block-end {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .review .review-container .review-block-end .review-private {
        display: flex;
        align-items: center;
        font-family: Poppins, sans-serif; }
        .review .review-container .review-block-end .review-private i {
          font-size: 1.4rem;
          margin-right: 10px;
          transition: all 0.3s ease;
          cursor: pointer; }
        .review .review-container .review-block-end .review-private i:hover {
          -webkit-transform: translateY(-3px);
                  transform: translateY(-3px);
          color: #f1e924; }
        .review .review-container .review-block-end .review-private i.yellow-star {
          color: #f1e924; }
        .review .review-container .review-block-end .review-private p {
          font-size: 0.75rem;
          font-weight: 500;
          margin-left: 10px; }
      .review .review-container .review-block-end .review-button {
        background-color: #0a124e;
        font-size: 1rem;
        padding: 6px 15px;
        cursor: pointer;
        border-radius: 5px;
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        font-weight: 500;
        font-family: Poppins; }

.review.hideReview {
  display: none; }

.s {
  width: 100%; }
  .s .s-search input {
    width: 100%;
    font-size: 16pt;
    padding: 20px 20px;
    border-radius: 5px;
    border: 1px solid #4a60ff;
    color: #4a60ff;
    box-shadow: 5px 5px 5px 0px rgba(151, 141, 255, 0.1);
    transition: all 0.3s ease; }
  .s .s-search input:focus {
    box-shadow: 10px 10px 10px 0px rgba(151, 141, 255, 0.1); }
  .s .s-search input::-webkit-input-placeholder {
    color: rgba(85, 68, 255, 0.4); }
  .s .s-search input::-ms-input-placeholder {
    color: rgba(85, 68, 255, 0.4); }
  .s .s-search input::placeholder {
    color: rgba(85, 68, 255, 0.4); }
  .s .rB {
    margin-bottom: 40px;
    text-align: left; }
  .s .s-list .empty-search {
    max-width: 100%;
    overflow: scroll; }

.items .contain {
  margin-bottom: 20px; }

.items .scroll-for-more {
  display: inline-flex;
  text-transform: uppercase;
  font-size: 0.8rem;
  align-items: center; }
  .items .scroll-for-more img {
    margin-left: 10px;
    margin-bottom: 1.5px; }

.items .item-section:hover .item-header {
  opacity: 1; }

.items .item-header {
  font-family: Poppins, GT-Regular, sans-serif;
  text-transform: uppercase;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0.8;
  font-weight: 500;
  transition: all 0.3s ease; }

.items .emptyPrograms {
  font-family: Poppins, sans-serif;
  border-radius: 5px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.1);
  font-size: 0.9rem;
  margin-top: 10px; }

.items .item-programs .program-block {
  display: inline-flex;
  background: #3149fb;
  border-radius: 5px;
  padding: 12px 15px;
  margin-right: 15px;
  align-items: center;
  justify-content: space-between; }
  .items .item-programs .program-block .img-wrapper {
    background: #1127d3;
    border-radius: 5px;
    padding: 5px;
    height: 46px;
    width: 50px;
    margin-right: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center; }
  .items .item-programs .program-block .program-type, .items .item-programs .program-block .program-length {
    font-family: GT-Bold, sans-serif;
    text-transform: uppercase;
    margin-right: 2px;
    text-shadow: 0px 3px 10px rgba(0, 0, 0, 0.7);
    font-size: 1.2rem; }
  .items .item-programs .program-block .program-length {
    font-size: 0.9rem;
    font-family: GT-Medium-Italic, sans-serif;
    margin-top: 6px; }

.items .item-routines .emptyPrograms {
  margin-top: 0; }

.items .item-routines .item-header {
  margin-bottom: 10px; }

.items .item-routines .items-cat {
  margin-bottom: 20px; }

.items .item-routines .cat-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0.8;
  transition: all 0.3s ease; }
  .items .item-routines .cat-header:hover {
    opacity: 1; }
  .items .item-routines .cat-header h3 {
    background: #2f47fb;
    border-radius: 5px;
    font-family: Poppins, sans-serif;
    text-transform: capitalize;
    color: white;
    font-weight: 500;
    font-size: 1rem;
    padding: 10px 20px;
    line-height: 1rem;
    cursor: default; }
    .items .item-routines .cat-header h3 span {
      color: #8592fb; }

.items .item-routines .rt {
  padding-top: 5px; }
  .items .item-routines .rt .routine-block-wrapper {
    width: calc(45% - 14px); }

.schedule {
  width: 100%; }
  .schedule h2 {
    margin-bottom: 40px;
    display: none; }
  .schedule .calendly-inline-widget {
    border-radius: 30px;
    overflow: hidden;
    position: relative;
    width: 700px !important;
    height: calc(100vh - 60px) !important;
    max-width: 100%;
    margin: 0 auto; }

.p, .pG {
  text-align: center; }
  .p h3, .p h4, .pG h3, .pG h4 {
    opacity: 0.8; }
  .p h3, .pG h3 {
    font-size: 16pt; }
  .p h4, .pG h4 {
    font-size: 13pt;
    margin-top: 5px; }
  .p h2, .pG h2 {
    font-size: 20pt;
    margin: 50px 0 30px; }
  .p .lPrograms-options, .pG .lPrograms-options {
    max-width: 600px;
    margin: 0 auto; }
    .p .lPrograms-options p, .pG .lPrograms-options p {
      display: inline-block;
      border: 1px solid #4a60ff;
      border-radius: 5px;
      padding: 12px 16px 10px;
      color: #4a60ff;
      margin: 10px;
      cursor: pointer;
      transition: all 0.3s ease; }
      .p .lPrograms-options p:hover, .p .lPrograms-options p.active, .pG .lPrograms-options p:hover, .pG .lPrograms-options p.active {
        background: #4a60ff;
        color: white; }
  .p .goal-list, .pG .goal-list {
    list-style: none;
    margin: 40px auto 0;
    padding: 0;
    max-width: 600px;
    width: 100%; }
    .p .goal-list li, .pG .goal-list li {
      margin-bottom: 20px;
      text-align: left;
      box-shadow: 0 3px 10px 0 rgba(68, 68, 181, 0.2);
      background: white;
      padding: 30px 20px;
      border-radius: 5px;
      transition: all 0.3s ease; }
      .p .goal-list li:hover, .pG .goal-list li:hover {
        -webkit-transform: translateY(-10px);
                transform: translateY(-10px); }
      .p .goal-list li a, .pG .goal-list li a {
        display: block; }
      .p .goal-list li h3, .pG .goal-list li h3 {
        color: #4a60ff;
        margin-bottom: 15px;
        font-weight: 700; }
      .p .goal-list li p, .pG .goal-list li p {
        line-height: 1.5; }
  .p .user-programs, .pG .user-programs {
    margin-top: 60px;
    background: #F7F9FA;
    padding: 30px; }
    .p .user-programs h2, .pG .user-programs h2 {
      font-size: 20pt;
      margin: 0;
      text-transform: capitalize; }
    .p .user-programs .legend, .pG .user-programs .legend {
      padding-left: 15px;
      margin: 15px 0 30px; }
      .p .user-programs .legend p, .pG .user-programs .legend p {
        display: inline-block;
        position: relative;
        margin: 10px 30px;
        color: #6d828c; }
        .p .user-programs .legend p:after, .pG .user-programs .legend p:after {
          content: '';
          position: absolute;
          height: 6px;
          width: 6px;
          border-radius: 500px;
          background: #4a60ff;
          left: -15px;
          top: 4px; }
        .p .user-programs .legend p.progress:after, .pG .user-programs .legend p.progress:after {
          background: #dbc422; }

.pS h2 {
  margin-bottom: 40px; }

.pS .pS-step .optionsMsg {
  visibility: hidden;
  opacity: 0;
  margin-left: -20px;
  transition: all 0.3s ease;
  padding: 8px 10px;
  border-radius: 3px;
  font-size: 10pt; }

.pS .pS-step .optionsMsg.show {
  visibility: visible;
  opacity: 1;
  margin-left: 0;
  background: #ff4a48;
  color: white; }

.pS .pS-step .createAccountBtn {
  display: inline-block;
  margin-top: 1em;
  background: #4a60ff;
  color: white;
  border-radius: 5px;
  padding: 10px 16px;
  font-size: 11pt;
  font-weight: 700;
  box-shadow: 0 3px 5px 0 #bac0ec; }

.pS .pS-step .nextBtn {
  color: #4a60ff;
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 7px;
  margin-top: 20px;
  padding: 10px 0;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 80%;
  border-left: 0;
  border-right: 0;
  font-weight: 700;
  font-size: 14pt; }
  .pS .pS-step .nextBtn:hover img {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }
  .pS .pS-step .nextBtn img {
    margin-left: 7px;
    padding-top: 3px;
    opacity: 0.6;
    transition: all 0.3s ease; }

.pS .pS-step .addToCart {
  color: white;
  background: #4a60ff;
  width: auto;
  display: inline-flex;
  padding: 10px 20px;
  border-radius: 3px;
  font-size: 14pt;
  font-weight: 700; }

.pS .progress {
  background: white;
  color: #111;
  border: 1px solid #ececec;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;
  padding: 10px 15px;
  transition: all 0.3s ease;
  width: 100%;
  border-left: 0;
  border-right: 0;
  margin-top: 60px; }
  .pS .progress .progress-step {
    font-size: 10pt;
    opacity: 0.6;
    transition: all 0.3s ease;
    cursor: pointer; }
    .pS .progress .progress-step img {
      height: 20px;
      margin-left: 7px;
      padding-top: 3px;
      opacity: 0.8;
      display: none; }
  .pS .progress .progress-step.active {
    font-weight: 700;
    opacity: 1; }

.pS .pI {
  position: relative; }
  .pS .pI p {
    line-height: 22pt;
    width: 80%;
    margin-bottom: 20px;
    font-size: 14pt; }
  .pS .pI div > p:last-of-type {
    margin-bottom: 0; }
  .pS .pI img {
    position: absolute;
    width: 50px;
    left: calc(30%);
    top: -90px; }

.pS .pC .pC-inputContainer {
  display: inline-flex;
  width: 50%;
  padding-right: 40px;
  margin-bottom: 40px;
  flex-direction: column; }
  .pS .pC .pC-inputContainer span {
    font-size: 8pt;
    font-weight: 700;
    text-transform: uppercase;
    opacity: 0.6; }
  .pS .pC .pC-inputContainer input, .pS .pC .pC-inputContainer textarea {
    border: 0;
    font-size: 12pt;
    margin-top: 10px;
    padding-bottom: 6px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease;
    max-width: 80%;
    line-height: 16pt; }
    .pS .pC .pC-inputContainer input:focus, .pS .pC .pC-inputContainer textarea:focus {
      border-bottom: 1px solid black; }
    .pS .pC .pC-inputContainer input:disabled, .pS .pC .pC-inputContainer textarea:disabled {
      border-bottom: 0;
      color: #111;
      resize: none; }

#program-confirm h2 {
  display: flex;
  align-items: center; }

#program-confirm .btn {
  display: inline-flex;
  align-items: center;
  border: 1px solid;
  border-radius: 7px;
  padding: 4px 10px 2.5px 9pt;
  margin-left: 40px;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  transition: all 0.5s ease;
  font-size: 10pt;
  font-family: 700;
  text-transform: uppercase;
  letter-spacing: 0.8pt;
  position: relative; }
  #program-confirm .btn:hover {
    color: white;
    -webkit-transform: translateX(2px);
            transform: translateX(2px); }

#program-confirm .btn.edit {
  border-color: #54f;
  box-shadow: 0 0 15px 0 rgba(84, 69, 255, 0.25);
  background: #54f; }

#program-confirm .btn.save {
  border-color: #ff4a48;
  box-shadow: 0 0 15px 0 rgba(255, 74, 72, 0.25);
  background: #ff4a48; }

#program-confirm .btn::after {
  content: 'Information saved.';
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
  position: absolute;
  left: 80px;
  width: 200px;
  text-transform: capitalize;
  letter-spacing: normal; }

#program-confirm .btn.saved::after {
  opacity: 1;
  visibility: visible;
  color: #0ee05d;
  font-size: 10pt;
  margin-left: 15px; }

.pO .o-container {
  margin-bottom: 40px; }
  .pO .o-container .o-option {
    font-family: 700;
    font-size: 12pt;
    margin-bottom: 15px; }
  .pO .o-container .o-btn {
    display: inline-flex;
    margin-right: 10px;
    padding: 7px 10px 5px;
    border: 1px solid #4a60ff;
    border-radius: 5px;
    align-items: center;
    font-size: 10pt;
    color: #4a60ff;
    cursor: pointer;
    transition: all 0.2s ease; }
  .pO .o-container .o-btn.active,
  .pO .o-container .o-btn:hover {
    background: #4a60ff;
    color: white; }
  .pO .o-container textarea {
    border: 0;
    border-bottom: 1px solid #4a60ff;
    font-size: 10pt;
    resize: vertical;
    width: 80%; }
  .pO .o-container .breakdown-container .breakdown.total {
    display: flex;
    margin-top: 20px;
    border-top: 1px solid #ececec;
    padding-top: 20px;
    width: 80%; }
  .pO .o-container .breakdown-container .breakdown {
    display: inline-flex;
    margin-right: 50px; }
    .pO .o-container .breakdown-container .breakdown p {
      font-size: 10pt;
      color: #555; }
    .pO .o-container .breakdown-container .breakdown b {
      margin-left: 5px;
      color: #111; }

.pR .r-row {
  flex-wrap: wrap;
  display: flex; }
  .pR .r-row .r-container.w-100 {
    width: calc(100% - 20px);
    display: flex;
    margin-right: 0; }
  .pR .r-row .r-container {
    display: inline-flex;
    margin-right: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ececec;
    padding-bottom: 20px;
    width: calc(50% - 20px);
    padding-right: 20px; }
    .pR .r-row .r-container p {
      color: #555;
      line-height: 16pt; }
      .pR .r-row .r-container p b {
        color: #111;
        display: flex;
        padding-top: 10px; }
      .pR .r-row .r-container p b.cap {
        text-transform: capitalize; }

.pB {
  width: 300px;
  height: 200px;
  border-radius: 5px;
  z-index: 1;
  margin: 20px 10px;
  box-shadow: 0 3px 10px 0 rgba(68, 68, 181, 0.3);
  display: inline-block;
  background: white;
  position: relative;
  text-align: left;
  transition: all 0.3s ease;
  background-position: center right;
  background-repeat: no-repeat;
  background-image: url("/assets/program-ready.jpg");
  overflow: hidden; }
  .pB:hover {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }
  .pB.yellow {
    box-shadow: 0 3px 10px 0 rgba(175, 170, 17, 0.3);
    background-image: url("/assets/program-notready.jpg"); }
  .pB .pB-container {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    color: white;
    background: rgba(0, 0, 0, 0.2); }
    .pB .pB-container .made-for {
      font-weight: 700;
      font-size: 9pt;
      opacity: 1;
      font-style: italic; }
    .pB .pB-container h3 {
      text-transform: capitalize;
      font-weight: 700;
      opacity: 1; }
    .pB .pB-container p {
      opacity: 0.8;
      margin-top: 10px;
      font-size: 12pt;
      font-weight: 700; }

.player {
  color: white;
  background: #282d4f;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99999;
  overflow: hidden;
  box-shadow: 0 0 80px 0 rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease-in; }
  .player .section-loader .line {
    background: #4a60ff; }
  .player .player-top {
    padding: 15px 40px;
    text-align: center;
    background: #1b1e36;
    box-shadow: 0 3px 50px 0 #1b1e36;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    z-index: 99; }
    .player .player-top .left, .player .player-top .right {
      display: flex;
      align-items: center; }
    .player .player-top .logo {
      -webkit-filter: brightness(0) invert(1);
              filter: brightness(0) invert(1);
      height: 36px; }
    .player .player-top .img-block {
      height: 36px;
      width: 36px; }
      .player .player-top .img-block .profile-img {
        width: 30px;
        height: 30px;
        margin-left: 5px;
        margin-right: 0;
        border-radius: 500px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border: 1px solid #4b4e63; }
    .player .player-top p, .player .player-top a {
      text-transform: uppercase;
      letter-spacing: 1pt;
      font-size: 11pt;
      color: #fff;
      display: inline-block; }
    .player .player-top .signup-btn {
      background: #4a60ff;
      color: white;
      border-radius: 5px;
      padding: 6px 12px;
      font-size: 10pt;
      font-weight: 700; }
    .player .player-top ul {
      padding: 0;
      margin: 0 0 0 82px;
      list-style: none;
      display: flex; }
      .player .player-top ul li {
        opacity: 0.4;
        cursor: pointer;
        position: relative;
        transition: all 0.2s ease;
        margin-right: 30px; }
        .player .player-top ul li::after {
          position: absolute;
          content: '';
          height: 3px;
          width: 100%;
          left: 0;
          bottom: -24px;
          background: transparent; }
        .player .player-top ul li.active, .player .player-top ul li:hover {
          opacity: 1; }
        .player .player-top ul li.active::after {
          background: #406aff; }
  .player .player-content {
    display: flex; }
    .player .player-content .left,
    .player .player-content .right {
      height: calc(100vh - 52px);
      overflow: hidden;
      transition: all 0.3s ease; }
    .player .player-content .left {
      width: 45%;
      padding-left: 40px;
      display: flex; }
    .player .player-content .right {
      width: 55%;
      background: #111322;
      box-shadow: -3px 0 50px 0 #1b1e36;
      overflow: scroll; }
    .player .player-content .side-menu {
      width: 200px;
      margin-top: 60px; }
      .player .player-content .side-menu ul {
        padding: 0;
        margin: 50px 0 0;
        list-style: none; }
        .player .player-content .side-menu ul li {
          font-size: 13pt;
          margin: 20px 0;
          letter-spacing: 0.3pt;
          opacity: 0.4;
          cursor: pointer;
          transition: all 0.2s ease; }
          .player .player-content .side-menu ul li.active, .player .player-content .side-menu ul li:hover {
            opacity: 1; }
    .player .player-content .list {
      width: calc(100% - 200px);
      overflow: scroll;
      height: 100%;
      padding-top: 60px; }
      .player .player-content .list .search-list {
        position: relative;
        background: #222744;
        width: 100%;
        margin-top: 10px; }
        .player .player-content .list .search-list input {
          background: transparent;
          width: 100%;
          border: 0;
          outline: none;
          padding: 10px 10px 10px 40px;
          font-size: 10pt;
          color: white;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px; }
          .player .player-content .list .search-list input::-webkit-input-placeholder {
            color: #ffffff;
            opacity: 0.2; }
          .player .player-content .list .search-list input::-ms-input-placeholder {
            color: #ffffff;
            opacity: 0.2; }
          .player .player-content .list .search-list input::placeholder {
            color: #ffffff;
            opacity: 0.2; }
        .player .player-content .list .search-list .search-icon {
          position: absolute;
          left: 10px;
          top: calc(50% - 10px); }
          .player .player-content .list .search-list .search-icon svg {
            width: 20px;
            height: 20px;
            opacity: 0.4; }
            .player .player-content .list .search-list .search-icon svg path {
              fill: #fff; }

.player-routines, .player-programs {
  margin-top: 50px;
  padding-bottom: 70px; }
  .player-routines .section-loader, .player-programs .section-loader {
    margin-top: 30px; }
  .player-routines .dropdown, .player-programs .dropdown {
    margin-bottom: 30px; }
    .player-routines .dropdown .t, .player-programs .dropdown .t {
      color: #646a92;
      font-size: 100%; }
    .player-routines .dropdown > div, .player-programs .dropdown > div {
      font-size: 11pt;
      letter-spacing: 0.3pt;
      font-weight: 600;
      display: flex;
      align-items: center;
      text-transform: capitalize; }
      .player-routines .dropdown > div svg, .player-programs .dropdown > div svg {
        margin-left: 8px;
        height: 14px;
        width: 14px; }
        .player-routines .dropdown > div svg path, .player-programs .dropdown > div svg path {
          fill: #fff; }
  .player-routines .rB, .player-programs .rB {
    margin-bottom: 30px; }
    .player-routines .rB:hover, .player-programs .rB:hover {
      -webkit-transform: none;
              transform: none; }
    .player-routines .rB h3, .player-programs .rB h3 {
      color: white; }
    .player-routines .rB .rB-cat, .player-routines .rB .rB-pic, .player-programs .rB .rB-cat, .player-programs .rB .rB-pic {
      color: white;
      border-color: white; }
  .player-routines .item-selector, .player-programs .item-selector {
    cursor: pointer;
    position: relative;
    margin-right: 40px;
    width: 200px;
    display: inline-block; }
    .player-routines .item-selector::before, .player-programs .item-selector::before {
      display: none;
      content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMTgiIGhlaWdodD0iMTgiCnZpZXdCb3g9IjAgMCAxOTIgMTkyIgpzdHlsZT0iIGZpbGw6IzAwMDAwMDsiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxwYXRoIGQ9Ik0wLDE5MnYtMTkyaDE5MnYxOTJ6IiBmaWxsPSJub25lIj48L3BhdGg+PGcgZmlsbD0iI2ZmZmZmZiI+PGcgaWQ9InN1cmZhY2UxIj48cGF0aCBkPSJNMTc0LDMzLjUxNTYzbC0xMDgsMTA4bC00My43ODEyNCwtNDMuNzM0MzdsLTQuMjE4NzYsLTQuMjY1NjNsLTguNDg0MzcsOC40ODQzN2w0LjI2NTYzLDQuMjE4NzZsNTIuMjE4NzQsNTIuMjY1NjJsMTE2LjQ4NDM3LC0xMTYuNDg0Mzd6Ij48L3BhdGg+PC9nPjwvZz48L2c+PC9zdmc+);
      position: absolute;
      width: 35px;
      height: 33px;
      border-radius: 500px;
      background: #101321;
      top: -12px;
      right: -16px;
      z-index: 9;
      box-shadow: -2px 2px 15px 0 #101321;
      align-items: center;
      justify-content: center;
      padding-top: 2px; }
    .player-routines .item-selector:hover::before, .player-programs .item-selector:hover::before {
      display: flex; }
    .player-routines .item-selector.selected::before, .player-programs .item-selector.selected::before {
      background: #3b953e;
      box-shadow: -7px 6px 15px 0 rgba(43, 132, 45, 0.5);
      display: flex; }
    .player-routines .item-selector *, .player-programs .item-selector * {
      pointer-events: none; }

.player-programs .empty-programs {
  padding-right: 20px; }
  .player-programs .empty-programs p {
    line-height: 1.4;
    font-size: 14pt; }
  .player-programs .empty-programs a {
    margin-top: 20px;
    background: white;
    color: #223bff;
    display: inline-block;
    padding: 10px 15px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14pt; }

.player-programs .program-block {
  display: inline-flex;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 3px 5px #282d4f;
  transition: all 0.3s ease;
  flex-direction: column;
  background: #3b4063;
  margin-right: 20px; }
  .player-programs .program-block:hover {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
    box-shadow: 0 3px 20px #1e2135; }
  .player-programs .program-block.uncompleted {
    cursor: default;
    background: #6a6b3a;
    opacity: 0.5; }
    .player-programs .program-block.uncompleted:hover {
      -webkit-transform: none;
              transform: none;
      box-shadow: none; }
  .player-programs .program-block h3 {
    font-size: 12pt;
    text-transform: uppercase;
    letter-spacing: 0.3pt;
    font-weight: 600; }

.player-right .section-loader {
  padding: 40px; }

.player-right .routine-view {
  overflow: hidden; }
  .player-right .routine-view .top {
    display: flex;
    position: relative; }
    .player-right .routine-view .top::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      background: transparent;
      background: linear-gradient(to top, black 0%, rgba(125, 185, 232, 0) 100%);
      height: 200px;
      bottom: 0; }
  .player-right .routine-view .content, .player-right .routine-view .image {
    width: 50%;
    height: 400px; }
    @media (min-width: 1160px) {
      .player-right .routine-view .content, .player-right .routine-view .image {
        height: 500px; } }
    @media (min-width: 1600px) {
      .player-right .routine-view .content, .player-right .routine-view .image {
        height: 600px; } }
    .player-right .routine-view .content img, .player-right .routine-view .image img {
      object-fit: cover;
      height: 100%;
      width: 100%; }
    .player-right .routine-view .content .info, .player-right .routine-view .image .info {
      margin-top: 20px;
      display: flex;
      opacity: 0.7; }
      .player-right .routine-view .content .info > div, .player-right .routine-view .image .info > div {
        display: flex;
        align-items: center;
        font-size: 11pt; }
      .player-right .routine-view .content .info svg, .player-right .routine-view .image .info svg {
        margin-right: 7px;
        height: 24px;
        width: 24px; }
        .player-right .routine-view .content .info svg path, .player-right .routine-view .image .info svg path {
          fill: white; }
      .player-right .routine-view .content .info .sets, .player-right .routine-view .image .info .sets {
        margin-right: 25px; }
  .player-right .routine-view .content {
    padding: 50px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline; }
    .player-right .routine-view .content button.play {
      border: 0;
      background: transparent;
      display: flex;
      align-items: center;
      color: #ababab;
      font-size: 10.5pt;
      cursor: pointer;
      position: relative;
      z-index: 2;
      transition: all 0.5s ease; }
      .player-right .routine-view .content button.play * {
        pointer-events: none; }
      .player-right .routine-view .content button.play > div {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        border-radius: 500px;
        height: 36px;
        width: 40px;
        padding-left: 2px;
        position: relative;
        transition: none; }
        .player-right .routine-view .content button.play > div::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          background: #406aff;
          border-radius: 500px;
          transition: all 0.3s ease;
          width: 40px;
          z-index: -1;
          box-shadow: 0 3px 20px 0 #3d57b3; }
        .player-right .routine-view .content button.play > div svg {
          width: 18px;
          height: 18px; }
          .player-right .routine-view .content button.play > div svg path {
            fill: white; }
      .player-right .routine-view .content button.play:hover {
        color: white; }
        .player-right .routine-view .content button.play:hover > div {
          box-shadow: none;
          transition: all 1s ease; }
          .player-right .routine-view .content button.play:hover > div::before {
            width: 155px; }
    .player-right .routine-view .content h2 {
      margin-bottom: 20px;
      font-size: 26pt; }
    .player-right .routine-view .content .rB-cat {
      padding: 5px 10px 4px;
      border-radius: 5px;
      display: inline-block;
      font-size: 12pt;
      margin-right: 8px;
      margin-bottom: 8px;
      color: #fff;
      border: 1px solid #fff; }
  .player-right .routine-view .bottom {
    padding: 40px; }
  .player-right .routine-view .subheader {
    font-weight: 600;
    margin-bottom: 20px;
    color: #444e62; }
  .player-right .routine-view .exercises {
    padding-bottom: 40px;
    margin-bottom: 40px; }
    .player-right .routine-view .exercises .eList .eList-hr {
      background: transparent; }
      .player-right .routine-view .exercises .eList .eList-hr .eE {
        padding-left: 40px;
        padding-right: 40px;
        margin-left: -40px;
        margin-right: -40px;
        border-bottom: 0; }
        .player-right .routine-view .exercises .eList .eList-hr .eE:hover {
          background: #1e2135; }
  .player-right .routine-view .related-container .rB {
    margin-bottom: 20px;
    margin-right: 20px; }
    .player-right .routine-view .related-container .rB h3 {
      color: white; }
    .player-right .routine-view .related-container .rB .rB-cat, .player-right .routine-view .related-container .rB .rB-pic {
      color: white;
      border-color: white; }

.player.hide-right .player-content .left {
  width: 100%; }

.player.hide-right .player-content .right {
  width: 0%; }

.player-exercises {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: #16182b;
  display: flex;
  align-items: center;
  justify-content: center; }
  .player-exercises .play-type {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #15182b;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center; }
    .player-exercises .play-type.auto, .player-exercises .play-type.manual {
      display: none; }
    .player-exercises .play-type button {
      color: rgba(255, 255, 255, 0.8);
      font-weight: 900;
      background: black;
      background: linear-gradient(to bottom left, #406aff 0%, #16182b 70%);
      box-shadow: 0 10px 20px rgba(22, 24, 43, 0.33);
      font-size: 46pt;
      width: 250px;
      height: 250px;
      border: 0;
      border-radius: 500px;
      overflow: hidden;
      transition: all 0.3s ease;
      line-height: 0.65;
      cursor: pointer;
      margin: 0 40px; }
      .player-exercises .play-type button:hover {
        -webkit-transform: scale(1.08);
                transform: scale(1.08);
        box-shadow: 0 15px 20px rgba(22, 24, 43, 0.5); }
  .player-exercises .top-player {
    width: 80%;
    height: 10%;
    position: fixed;
    top: 0;
    left: 10%;
    right: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center; }
    .player-exercises .top-player #prev, .player-exercises .top-player .p-E__countdown {
      position: absolute;
      left: 10%;
      z-index: 99999; }
    .player-exercises .top-player p.startBtn {
      color: #fff;
      opacity: 1;
      padding: 5px 15px 3px;
      font-size: 16pt;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      transition: all 0.3s ease; }
  .player-exercises .carousel-container {
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    position: relative; }
    .player-exercises .carousel-container .close {
      left: calc(55% - 60px);
      position: absolute;
      top: 40px;
      color: white;
      border-radius: 500px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: lowercase;
      opacity: 1;
      line-height: 1;
      cursor: pointer;
      transition: all 0.3s ease;
      z-index: 9999; }
      .player-exercises .carousel-container .close * {
        pointer-events: none; }
      .player-exercises .carousel-container .close svg {
        width: 16px;
        height: 16px;
        opacity: 0.8;
        transition: all 0.3s ease; }
      .player-exercises .carousel-container .close:hover svg {
        opacity: 1; }
    .player-exercises .carousel-container #prev {
      position: absolute;
      left: 40px;
      top: 40px;
      z-index: 9999;
      cursor: pointer;
      display: flex;
      align-items: center;
      opacity: 0.8;
      transition: all 0.3s ease; }
      .player-exercises .carousel-container #prev > div {
        -webkit-transform: rotate(-90deg) translateY(-15px);
                transform: rotate(-90deg) translateY(-15px);
        transition: all 0.3s ease; }
      .player-exercises .carousel-container #prev:hover {
        opacity: 1; }
        .player-exercises .carousel-container #prev:hover > div {
          -webkit-transform: rotate(-90deg) translateY(-20px);
                  transform: rotate(-90deg) translateY(-20px); }
      .player-exercises .carousel-container #prev svg {
        width: 20px;
        height: 20px; }
        .player-exercises .carousel-container #prev svg path {
          transition: all 0.3s ease;
          fill: #dbdde0 !important; }
      .player-exercises .carousel-container #prev:hover svg path {
        fill: #fff !important; }
  .player-exercises .top h2 {
    margin-bottom: 25px;
    font-size: 30pt;
    white-space: normal; }
  .player-exercises .top p {
    font-size: 12pt;
    line-height: 20pt;
    opacity: 0.7; }
  .player-exercises .p-E__container {
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    color: white;
    justify-content: space-between;
    transition: all 0.3s ease;
    background: #1d203c;
    left: 0;
    top: 0;
    overflow: hidden;
    padding: 100px 60px 40px;
    z-index: -1; }
    .player-exercises .p-E__container.active {
      display: inline-flex;
      z-index: 1; }
    .player-exercises .p-E__container .p-E__content {
      padding-right: 60px;
      width: calc(55%);
      display: inline-flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: scroll; }
      .player-exercises .p-E__container .p-E__content .top, .player-exercises .p-E__container .p-E__content .middle {
        position: relative;
        z-index: 2; }
      .player-exercises .p-E__container .p-E__content .middle {
        display: flex;
        align-items: center; }
        .player-exercises .p-E__container .p-E__content .middle .react-countdown-clock {
          margin-right: 20px;
          cursor: pointer; }
        .player-exercises .p-E__container .p-E__content .middle .countdown-clock {
          width: 200px;
          height: 200px;
          border: 5px solid white;
          border-radius: 500px;
          margin-right: 20px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center; }
          .player-exercises .p-E__container .p-E__content .middle .countdown-clock .time {
            font-size: 70pt; }
        .player-exercises .p-E__container .p-E__content .middle .container {
          display: flex;
          flex-direction: column; }
        .player-exercises .p-E__container .p-E__content .middle .pause-info, .player-exercises .p-E__container .p-E__content .middle .sets {
          font-size: 13pt;
          opacity: 0.7;
          margin-bottom: 20px;
          font-weight: 600; }
        .player-exercises .p-E__container .p-E__content .middle .e-amount {
          font-size: 24pt;
          font-weight: 700; }
      .player-exercises .p-E__container .p-E__content .p-E__countdown p {
        display: inline-block;
        border-radius: 5px;
        margin-right: 10px;
        cursor: default;
        padding: 2px 12px 0;
        font-size: 10pt;
        background: transparent;
        opacity: 0.4;
        transition: all 0.3s ease; }
      .player-exercises .p-E__container .p-E__content .p-E__countdown p.active {
        background: #fff;
        opacity: 1;
        color: #4a60ff; }
      .player-exercises .p-E__container .p-E__content .timer {
        height: 65px;
        width: 65px;
        border: 2px solid white;
        border-radius: 500px;
        font-size: 20pt;
        display: inline-flex;
        align-items: center;
        justify-content: center; }
      .player-exercises .p-E__container .p-E__content .p-E__tags p {
        display: inline-block;
        border: 1px solid white;
        border-radius: 5px;
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 2px 12px 0;
        font-size: 11pt;
        cursor: default; }
      .player-exercises .p-E__container .p-E__content .p-E__model {
        color: #fff;
        display: inline-flex;
        border-radius: 20px;
        margin-top: 20px;
        margin-right: 20px;
        opacity: 0.6;
        transition: all 0.3s ease; }
        .player-exercises .p-E__container .p-E__content .p-E__model:hover {
          opacity: 0.9; }
        .player-exercises .p-E__container .p-E__content .p-E__model a {
          display: inline-flex;
          align-items: center;
          line-height: 1; }
        .player-exercises .p-E__container .p-E__content .p-E__model svg {
          fill: #fff;
          width: 16px;
          height: 16px;
          margin-right: 8px;
          margin-bottom: 1px; }
    .player-exercises .p-E__container .p-E__image {
      position: absolute;
      right: 0;
      width: 45%;
      height: 100%;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center; }
      .player-exercises .p-E__container .p-E__image img.video {
        max-width: calc(100% - 40px); }
    .player-exercises .p-E__container .p-E__pics {
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      position: relative;
      width: 50%;
      height: calc(100% + 120px);
      overflow: hidden;
      margin-top: -60px;
      margin-right: -60px; }
      .player-exercises .p-E__container .p-E__pics img {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%; }
    .player-exercises .p-E__container .bottom::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      background: transparent;
      background: linear-gradient(to top, #000 0%, #1d203c 100%);
      height: 30%;
      bottom: 0;
      transition: all 0.3s ease; }
    .player-exercises .p-E__container .bottom:hover::before {
      opacity: 0.7; }
    .player-exercises .p-E__container .bottom:hover .next-txt, .player-exercises .p-E__container .bottom:hover h3 {
      opacity: 0.8; }
      .player-exercises .p-E__container .bottom:hover .next-txt svg, .player-exercises .p-E__container .bottom:hover h3 svg {
        -webkit-transform: rotate(90deg) translateY(-5px);
                transform: rotate(90deg) translateY(-5px); }
    .player-exercises .p-E__container .bottom #next {
      cursor: pointer; }
    .player-exercises .p-E__container .bottom .next-txt {
      font-size: 15pt;
      color: #fff;
      position: relative;
      margin-bottom: 15px;
      z-index: 2;
      opacity: 0.4;
      transition: all 0.3s ease;
      display: flex;
      align-items: center; }
      .player-exercises .p-E__container .bottom .next-txt svg {
        transition: all 0.3s ease;
        width: 20px;
        height: 20px;
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
        margin-left: 10px;
        margin-bottom: 2px; }
        .player-exercises .p-E__container .bottom .next-txt svg path {
          transition: all 0.3s ease;
          fill: #fff; }
    .player-exercises .p-E__container .bottom h3 {
      font-size: 22pt;
      transition: all 0.3s ease;
      font-weight: 600;
      position: relative;
      color: #ffffff;
      z-index: 2;
      opacity: 0.5; }
    .player-exercises .p-E__container.break-container {
      flex-direction: column; }
      .player-exercises .p-E__container.break-container .inner {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: 1200px;
        margin: 0 auto; }
      .player-exercises .p-E__container.break-container .react-countdown-clock {
        position: absolute;
        right: 0;
        z-index: 2;
        top: calc(50% - 300px); }
      .player-exercises .p-E__container.break-container .countdown-clock {
        position: absolute;
        right: 0;
        z-index: 2;
        top: 0;
        width: 600px;
        height: 600px;
        border: 10px solid #406aff;
        border-radius: 500px;
        margin-right: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center; }
        .player-exercises .p-E__container.break-container .countdown-clock .time {
          font-size: 100pt;
          color: #406aff; }
      .player-exercises .p-E__container.break-container .close {
        position: absolute;
        left: 0;
        top: 0;
        text-transform: uppercase;
        letter-spacing: 1pt;
        opacity: 0.6;
        width: auto;
        height: auto;
        margin-left: 0; }
      .player-exercises .p-E__container.break-container h2 {
        font-size: 60pt;
        max-width: 500px;
        position: relative;
        z-index: 3;
        margin-top: 60px;
        color: white;
        font-weight: 900; }

.finished_routine {
  margin: -120px -60px -40px;
  padding: 60px 40px;
  height: calc(100% + 160px);
  width: calc(100% + 120px); }
  .finished_routine .top {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
  .finished_routine .back {
    display: flex;
    width: 100%;
    align-items: center;
    opacity: 0.5;
    transition: all 0.3s ease;
    cursor: pointer;
    margin-bottom: 40px; }
    .finished_routine .back svg {
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
      transition: all 0.3s ease;
      width: 32px;
      height: 32px; }
      .finished_routine .back svg path {
        fill: white; }
    .finished_routine .back p {
      margin-left: 10px;
      font-size: 12pt;
      line-height: 1; }
    .finished_routine .back:hover {
      opacity: 1; }
      .finished_routine .back:hover svg {
        -webkit-transform: rotate(-90deg) translateY(-5px);
                transform: rotate(-90deg) translateY(-5px); }
  .finished_routine .user-info {
    width: 350px;
    display: inline-block; }
    .finished_routine .user-info .img-block {
      width: 80px;
      height: 80px;
      margin-bottom: 40px; }
      .finished_routine .user-info .img-block img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 500px; }
    .finished_routine .user-info h2 {
      font-weight: 400;
      font-size: 26pt;
      line-height: 1.2; }
      .finished_routine .user-info h2 b {
        font-weight: 600;
        font-weight: normal; }
    .finished_routine .user-info p {
      opacity: 0.5;
      margin-top: 60px;
      font-size: 18pt;
      line-height: 1.4; }
      .finished_routine .user-info p span {
        font-size: 12pt;
        display: block;
        margin-top: 10px;
        line-height: 1.5;
        opacity: 0.8; }
    @media (max-height: 900px) {
      .finished_routine .user-info {
        width: 300px; }
        .finished_routine .user-info .img-block {
          width: 60px;
          height: 60px;
          margin-bottom: 30px; }
        .finished_routine .user-info h2 {
          font-size: 23pt; }
        .finished_routine .user-info p {
          margin-top: 45px;
          font-size: 16pt; } }
  .finished_routine .user-stats {
    width: calc(100% - 350px);
    display: inline-block;
    padding-left: 60px;
    display: inline-flex;
    justify-content: space-around; }
    .finished_routine .user-stats .stat-block {
      border: 2px solid #45476d;
      border-radius: 10px;
      padding: 40px 20px 45px;
      width: 250px;
      text-align: center;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .finished_routine .user-stats .stat-block .container:nth-of-type(2) {
        margin-top: 40px; }
      .finished_routine .user-stats .stat-block .number {
        font-size: 70pt; }
        .finished_routine .user-stats .stat-block .number span {
          font-weight: 700; }
      .finished_routine .user-stats .stat-block .title {
        font-size: 16pt; }
      .finished_routine .user-stats .stat-block .info {
        opacity: 0.4;
        font-size: 10pt;
        line-height: 13pt;
        width: 90%;
        margin: 0 auto;
        color: #8689b8; }
      .finished_routine .user-stats .stat-block .share {
        position: absolute;
        bottom: -17px;
        left: calc(50% - 50px);
        width: 100px;
        padding: 7px;
        border-radius: 10px;
        font-size: 11pt;
        letter-spacing: 0.4pt;
        background: #1d203c;
        color: #878ab9;
        border: 2px solid #45476d;
        box-shadow: 0 0 15px 15px #1d203c;
        transition: all 0.3s ease;
        cursor: pointer; }
        .finished_routine .user-stats .stat-block .share:hover {
          -webkit-transform: scale(1.03);
                  transform: scale(1.03); }
      .finished_routine .user-stats .stat-block .share-container {
        position: absolute;
        bottom: -25px;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease;
        padding: 0 10px;
        background: #26294a;
        display: flex;
        border-radius: 5px;
        left: calc(50% - 100px); }
        .finished_routine .user-stats .stat-block .share-container.show {
          bottom: -75px;
          opacity: 1;
          visibility: visible; }
        .finished_routine .user-stats .stat-block .share-container > div {
          margin: 10px 5px;
          cursor: pointer; }
        .finished_routine .user-stats .stat-block .share-container * div {
          height: 26px !important;
          width: 26px !important; }
        .finished_routine .user-stats .stat-block .share-container svg {
          height: 26px;
          width: 26px;
          border-radius: 500px; }
    @media (max-height: 900px) {
      .finished_routine .user-stats {
        width: calc(100% - 300px); } }
  .finished_routine .bottom {
    width: calc(100% + 90px);
    height: calc(100% - 507px + 100px);
    margin: 0 -45px -60px -45px; }
    .finished_routine .bottom::before {
      content: none !important; }
    .finished_routine .bottom .ReactChart {
      pointer-events: none; }
      .finished_routine .bottom .ReactChart .Series > g:nth-of-type(1) path:nth-of-type(1) {
        fill: #3c4880 !important;
        stroke-width: 3px !important;
        stroke: #6979bf !important; }
      .finished_routine .bottom .ReactChart .Series > g:nth-of-type(2) path:nth-of-type(1) {
        fill: #4e5c9a !important;
        stroke-width: 3px !important;
        stroke: #7887c7 !important; }
    @media (max-height: 900px) {
      .finished_routine .bottom {
        height: calc(100% - 435px + 100px); } }

.program {
  margin-top: -35px; }
  .program .weeks {
    white-space: nowrap;
    overflow-x: scroll; }
    .program .weeks button {
      margin-right: 15px;
      margin-bottom: 15px;
      border-radius: 4px;
      font-size: 11pt;
      padding: 7px 15px;
      font-weight: 500;
      background: #3b4063;
      color: #6b6f90;
      border: 0;
      box-shadow: 0 3px 5px #272b46;
      transition: all 0.3s ease;
      cursor: pointer; }
      .program .weeks button:hover {
        background: #43486d; }
      .program .weeks button.active {
        background: #fff;
        color: #292e4f;
        box-shadow: 0 5px 20px #1e2138; }
  .program .week-data {
    display: flex;
    margin-bottom: 30px;
    white-space: nowrap;
    overflow-x: scroll; }
    .program .week-data .week-day {
      background: #3b4063;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 30px;
      transition: all 0.3s ease;
      cursor: pointer;
      text-transform: capitalize; }
      .program .week-data .week-day h3 {
        margin-bottom: 10px;
        font-size: 14pt; }
      .program .week-data .week-day p {
        font-size: 10pt;
        opacity: 0.5; }
      .program .week-data .week-day:hover {
        background: #43486d; }
      .program .week-data .week-day.active {
        background: #fff;
        color: #292e4f;
        box-shadow: 0 5px 20px #1e2138; }
        .program .week-data .week-day.active h3 {
          font-weight: 500; }
        .program .week-data .week-day.active p {
          opacity: 1; }
      .program .week-data .week-day:first-of-type {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px; }
  .program .rest {
    font-size: 16pt;
    background: white;
    color: #292e4f;
    display: inline-flex;
    padding: 10px 20px;
    border-radius: 5px;
    align-items: center; }
    .program .rest span {
      font-size: 30pt;
      margin-left: 8px;
      line-height: 1; }
  .program .week-exercises {
    display: flex;
    flex-wrap: wrap; }
    .program .week-exercises .week-exercise {
      min-width: 200px;
      margin-right: 20px;
      margin-bottom: 20px; }
      .program .week-exercises .week-exercise .img-block {
        width: 100%;
        height: 250px;
        margin-bottom: 15px;
        position: relative; }
        .program .week-exercises .week-exercise .img-block .lazy-load-image-background {
          border-radius: 5px;
          width: 100%;
          height: 250px; }
        .program .week-exercises .week-exercise .img-block img {
          display: block;
          min-width: 200px;
          height: 250px;
          object-fit: cover;
          object-position: center;
          border-radius: 5px;
          border: 2px solid white;
          box-shadow: 0 3px 5px #272b46; }
        .program .week-exercises .week-exercise .img-block p {
          position: absolute;
          bottom: 10px;
          left: 10px;
          background: #406aff;
          color: white;
          border-radius: 5px;
          padding: 4px 10px;
          font-weight: 600;
          font-size: 10pt; }
      .program .week-exercises .week-exercise h3 {
        font-size: 11pt;
        margin-bottom: 10px;
        font-weight: 700;
        width: 200px; }
      .program .week-exercises .week-exercise .tags {
        display: flex;
        flex-wrap: wrap;
        width: 200px; }
        .program .week-exercises .week-exercise .tags .tag {
          padding: 4px 10px;
          border-radius: 5px;
          display: inline-block;
          font-size: 10pt;
          margin-right: 5px;
          margin-bottom: 5px;
          color: white;
          border: 1px solid white; }

.eqList {
  margin: 0 0 60px;
  padding: 0;
  display: flex;
  flex-wrap: wrap; }
  .eqList li {
    position: relative;
    z-index: 1;
    color: white;
    width: 100px;
    margin: 0 20px 20px 0;
    display: inline-block;
    transition: all 0.2s ease-in-out;
    white-space: normal;
    border-radius: 10px;
    flex-direction: column; }
    .eqList li .img-block {
      width: 100px;
      height: 100px;
      margin-bottom: 15px;
      background: white;
      padding: 15px;
      border-radius: 5px; }
      .eqList li .img-block img {
        object-fit: contain;
        width: 100%;
        height: 100%; }
    .eqList li p {
      font-size: 10.5pt;
      margin-bottom: 10px;
      font-weight: 500;
      line-height: 15pt;
      opacity: 0.8; }
    .eqList li a {
      padding: 5px 10px;
      border-radius: 5px;
      display: inline-block;
      font-size: 10pt;
      margin-right: 5px;
      margin-bottom: 5px;
      color: white;
      background: #5d71ff; }
      .eqList li a:hover {
        -webkit-transform: scale(1.05);
                transform: scale(1.05); }

.shop header {
  border-bottom: 1px solid #ececec;
  padding: 50px;
  box-shadow: 0 3px 10px #ececec;
  margin-left: -40px;
  margin-right: -40px;
  margin-top: -40px; }
  .shop header .title {
    text-align: center;
    font-size: 30pt;
    margin: 0 auto 25px; }
  .shop header p {
    font-size: 16pt;
    text-align: center;
    opacity: 0.8;
    max-width: 500px;
    margin: 0 auto;
    line-height: 1.4; }

.shop .shop-items {
  white-space: nowrap;
  overflow-x: scroll;
  margin-bottom: 80px;
  margin-right: -40px;
  margin-left: -40px;
  padding-left: 40px;
  padding-top: 60px; }
  .shop .shop-items p {
    font-size: 14pt;
    text-align: center;
    width: 100%; }
  .shop .shop-items .item {
    margin-right: 30px;
    margin-bottom: 30px;
    display: inline-block; }
    .shop .shop-items .item .img-block {
      width: 300px;
      height: 300px;
      background: white;
      border-radius: 5px;
      display: block;
      padding: 25px;
      border: 2px solid #4a60ff;
      box-shadow: 0 0 10px 0 rgba(68, 68, 181, 0.3); }
      .shop .shop-items .item .img-block img {
        object-fit: contain;
        width: 100%;
        height: 100%; }
      .shop .shop-items .item .img-block:hover {
        -webkit-transform: scale(1.03);
                transform: scale(1.03); }
    .shop .shop-items .item h4 {
      margin-top: 20px;
      font-weight: 700;
      font-size: 14pt; }

.policies h1 {
  font-weight: 800;
  font-size: 34pt;
  letter-spacing: -1pt; }

.policies h3 {
  margin: 20px 0 50px;
  opacity: 0.5;
  font-size: 16pt; }

.policies p {
  margin: 10px 0;
  line-height: 1.4; }

.policies h2 {
  font-size: 22pt;
  margin: 60px 0 15px;
  letter-spacing: -1pt; }

.policies.refund-policy h1 {
  margin-bottom: 40px; }

.download-app {
  text-align: center; }
  .download-app h3 {
    opacity: 0.8;
    font-size: 16pt;
    max-width: 400px;
    margin: 0 auto 50px;
    line-height: 1.5; }
  .download-app h2 {
    font-size: 20pt;
    margin-bottom: 20px; }
  .download-app img {
    max-width: 300px;
    border-radius: 20px;
    box-shadow: 0 5px 20px #c0c6ce; }
    .download-app img:first-of-type {
      margin-right: 30px; }

.premium {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .premium .title {
    display: flex;
    flex-direction: column;
    font-weight: normal;
    text-align: center;
    margin-bottom: 40px;
    text-transform: uppercase;
    line-height: 1.5; }
    .premium .title span {
      color: #ffcd22; }
  .premium .premium-container {
    max-width: 400px;
    margin: 0 auto;
    width: 90%;
    box-shadow: 0 3px 10px #e6ecf1;
    border-radius: 10px;
    padding: 30px;
    border: 1px solid #e6ecf1; }
    .premium .premium-container h3 {
      font-size: 20pt;
      margin-bottom: 10px; }
    .premium .premium-container .price {
      display: flex;
      align-items: flex-end; }
      .premium .premium-container .price h2 {
        line-height: 1;
        font-size: 30pt; }
      .premium .premium-container .price p {
        line-height: 1.9;
        margin-left: 10px; }
    .premium .premium-container span {
      display: block;
      font-size: 10pt;
      margin-top: 5px; }
    .premium .premium-container ul {
      padding: 30px 0;
      margin: 30px 0 40px;
      list-style: none;
      border-top: 1px solid #ececec;
      border-bottom: 1px solid #ececec; }
      .premium .premium-container ul li {
        display: flex;
        align-items: center;
        padding: 7px 0; }
        .premium .premium-container ul li svg {
          width: 20px;
          height: 20px;
          margin-right: 10px; }
          .premium .premium-container ul li svg path {
            fill: #26bd26; }
    .premium .premium-container button {
      background: #406aff;
      color: white;
      border-radius: 20px;
      width: 100%;
      font-size: 12pt;
      border: 0;
      padding: 15px;
      font-weight: 500;
      letter-spacing: 0.8pt;
      text-transform: uppercase;
      cursor: pointer;
      transition: all 0.3s ease; }
      .premium .premium-container button:hover {
        -webkit-transform: translateY(-5px);
                transform: translateY(-5px); }
  .premium .premium-pay {
    opacity: 0;
    visibility: hidden;
    height: 0;
    overflow: hidden;
    position: fixed;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.2s ease;
    background: rgba(255, 255, 255, 0.95); }
    .premium .premium-pay .inner {
      max-width: 400px;
      width: 90%; }
      .premium .premium-pay .inner button {
        background: #4a60ff;
        color: white;
        font-size: 13pt;
        text-transform: capitalize;
        max-width: 400px;
        width: 100%;
        border-radius: 5px;
        padding: 14px 15px 12px;
        cursor: pointer;
        border: 0; }
        .premium .premium-pay .inner button.error {
          background: #f13333; }
    .premium .premium-pay.show {
      height: auto;
      opacity: 1;
      visibility: visible; }

.forgot-password {
  padding-right: 220px;
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .forgot-password input, .forgot-password button {
    width: 320px;
    max-width: 100%;
    text-align: center;
    font-size: 14pt;
    border-radius: 4px;
    padding: 10px 15px;
    transition: all 0.3s ease; }
  .forgot-password input {
    margin: 60px 0 15px;
    border: 1px solid #ececec; }
    .forgot-password input:hover, .forgot-password input:focus {
      box-shadow: -3px 3px 10px #e7e7e7;
      border-color: #d8dce0; }
    .forgot-password input:nth-of-type(2) {
      margin-top: 0px; }
  .forgot-password button {
    background: #223bff;
    border: 1px solid #223bff;
    color: white;
    cursor: pointer; }
    .forgot-password button:hover {
      -webkit-transform: scale(1.03);
              transform: scale(1.03);
      box-shadow: -3px 3px 10px #e0e2e4; }
  .forgot-password span {
    color: #31961d;
    margin-top: 20px;
    font-weight: 500; }

.mobile-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: #f7f9fa;
  border-top: 1px solid #d4dadf;
  box-shadow: 0 -3px 10px 0 rgba(71, 71, 82, 0.08);
  display: none; }
  .mobile-menu ul {
    padding: 0;
    list-style: none;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding: 0 40px; }
    .mobile-menu ul .profile-img {
      width: 26px;
      height: 26px;
      margin-left: 5px;
      margin-right: 0;
      border-radius: 500px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border: 1px solid #4b4e63; }
  .mobile-menu .menuExtend {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    background: #406aff;
    background: linear-gradient(130deg, #406aff 0, #1e48d1);
    transition: all 0.3s ease;
    opacity: 0;
    visibility: hidden;
    flex-wrap: wrap;
    flex-direction: column;
    color: white; }
    .mobile-menu .menuExtend .top {
      padding: 30px;
      width: 100%;
      display: flex;
      justify-content: space-between; }
      .mobile-menu .menuExtend .top img {
        height: 46px;
        -webkit-filter: brightness(0) invert(1);
                filter: brightness(0) invert(1); }
      .mobile-menu .menuExtend .top svg {
        height: 30px;
        width: 30px;
        margin-top: 10px; }
        .mobile-menu .menuExtend .top svg path {
          fill: white; }
    .mobile-menu .menuExtend ul {
      flex-direction: column;
      align-items: baseline;
      height: auto;
      padding: 30px;
      justify-content: flex-start; }
      .mobile-menu .menuExtend ul li a {
        padding: 15px 0;
        display: block; }
        .mobile-menu .menuExtend ul li a p {
          font-size: 12pt;
          margin-bottom: 0 !important; }
    .mobile-menu .menuExtend .copyright {
      padding: 30px; }
      .mobile-menu .menuExtend .copyright .social-media {
        margin-bottom: 20px; }
        .mobile-menu .menuExtend .copyright .social-media a {
          margin-right: 10px; }
          .mobile-menu .menuExtend .copyright .social-media a img {
            height: 20px;
            -webkit-filter: brightness(0) invert(1);
                    filter: brightness(0) invert(1); }
      .mobile-menu .menuExtend .copyright .extra {
        margin-bottom: 10px;
        display: block;
        font-size: 11pt;
        font-weight: 600; }
      .mobile-menu .menuExtend .copyright p {
        font-size: 11pt; }
    .mobile-menu .menuExtend.show {
      opacity: 1;
      visibility: visible;
      overflow: scroll; }

@media (min-width: 901px) {
  .mobile-menu,
  .back-to-list {
    display: none !important; } }

@media (max-width: 900px) {
  h2 {
    font-size: 24pt; }
  .p-r_40 {
    padding-right: 20px; }
  .p-l_40 {
    padding-left: 20px; }
  .header-container {
    background: white;
    border-bottom: 1px solid #e6ecf1;
    box-shadow: none;
    display: none !important; }
    .header-container .top-bar {
      padding: 0 20px; }
      .header-container .top-bar .menu {
        display: none; }
      .header-container .top-bar .top-right {
        width: auto; }
      .header-container .top-bar .profile-li {
        display: none; }
  .left-sidebar {
    display: none !important; }
  .app-container.at_join {
    min-height: auto;
    height: 100vh; }
  .app-container.at_join .app-max {
    min-height: auto; }
  .app-container.at_join .app-max .main-content .content {
    align-items: baseline; }
  .app-container .app-max {
    padding-left: 0;
    max-height: calc(100% - 80px); }
    .app-container .app-max .main-content {
      margin-top: 0; }
      .app-container .app-max .main-content > .content {
        margin-left: 0;
        width: 100%;
        padding: 0 20px; }
  .mobile-menu {
    display: block; }
    .mobile-menu ul {
      padding: 0;
      list-style: none;
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 80px;
      padding: 0 20px; }
      .mobile-menu ul li .link {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        height: 40px; }
        .mobile-menu ul li .link p {
          font-size: 8.5pt;
          opacity: 0.6;
          position: absolute;
          bottom: -3px; }
        .mobile-menu ul li .link svg {
          -webkit-transform: scale(1.3);
                  transform: scale(1.3); }
        .mobile-menu ul li .link .mobile-search svg {
          margin-top: -3px;
          -webkit-transform: scale(0.9);
                  transform: scale(0.9); }
      .mobile-menu ul li .logo-container {
        height: 50px;
        width: 50px;
        display: inline-flex;
        align-items: center;
        position: relative;
        padding-left: 8px; }
        .mobile-menu ul li .logo-container img {
          height: 40px; }
  .rH .rH-section > * {
    padding-left: 0;
    padding-right: 0; }
  .schedule {
    padding: 0;
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px); }
    .schedule .calendly-inline-widget {
      width: 100vw !important; }
  .player .player-top {
    display: none; }
    .player .player-top .left .logo-container {
      position: relative;
      display: inline-flex;
      align-items: center; }
      .player .player-top .left .logo-container::after {
        content: "Beta";
        position: absolute;
        border: 1px solid #b6b6b6;
        border-radius: 3px;
        color: #aaaaaa;
        line-height: 1;
        font-size: 7.5pt;
        padding: 3px 5px;
        left: 60px;
        letter-spacing: 0.5pt;
        text-transform: uppercase; }
    .player .player-top .left .menu {
      display: none; }
  .player .mobile-menu {
    background: #171a31;
    border-color: #20233d;
    box-shadow: 0 -3px 10px 0 rgba(133, 133, 155, 0.08); }
    .player .mobile-menu ul li svg,
    .player .mobile-menu ul li img {
      -webkit-filter: brightness(0) invert(1);
              filter: brightness(0) invert(1); }
  .player .player-content .left {
    width: 100%;
    flex-direction: column;
    padding-left: 20px;
    height: calc(100vh - 80px);
    overflow: scroll; }
    .player .player-content .left > * {
      width: 100%; }
    .player .player-content .left .side-menu ul {
      display: flex;
      margin-top: 20px; }
      .player .player-content .left .side-menu ul li {
        margin-right: 40px; }
    .player .player-content .left .list {
      overflow: visible;
      overflow: initial;
      height: auto; }
    .player .player-content .left .player-routines .item-selector::before {
      content: none;
      display: none; }
  .player .player-content .player-exercises .p-E__container {
    overflow: scroll;
    height: calc(100vh - 80px);
    padding: 0;
    flex-wrap: wrap; }
    .player .player-content .player-exercises .p-E__container .p-E__content,
    .player .player-content .player-exercises .p-E__container .p-E__image {
      width: 100%;
      padding-right: 0; }
    .player .player-content .player-exercises .p-E__container .p-E__content {
      order: 2;
      padding: 40px 20px 30px; }
      .player .player-content .player-exercises .p-E__container .p-E__content .middle {
        margin: 40px 0; }
      .player .player-content .player-exercises .p-E__container .p-E__content .bottom {
        position: relative;
        margin-right: -20px;
        margin-left: -20px; }
        .player .player-content .player-exercises .p-E__container .p-E__content .bottom::before {
          height: 100%; }
    .player .player-content .player-exercises .p-E__container .p-E__image {
      position: static;
      position: initial;
      order: 1;
      height: auto; }
      .player .player-content .player-exercises .p-E__container .p-E__image img.video {
        max-width: 100%;
        width: 100%; }
    .player .player-content .player-exercises .p-E__container.break-container {
      padding: 20px;
      flex-wrap: nowrap; }
      .player .player-content .player-exercises .p-E__container.break-container h2 {
        margin-top: 320px; }
      .player .player-content .player-exercises .p-E__container.break-container .countdown-clock {
        width: 300px !important;
        height: 300px !important;
        top: calc(50% - 220px);
        right: 20px; }
      .player .player-content .player-exercises .p-E__container.break-container .bottom {
        padding-top: 40px;
        padding-left: 0;
        padding-right: 0; }
    .player .player-content .player-exercises .p-E__container .finished_routine--container {
      width: 100%; }
      .player .player-content .player-exercises .p-E__container .finished_routine--container .finished_routine {
        margin: 0;
        width: 100%;
        height: auto;
        padding: 40px 20px;
        flex-wrap: wrap; }
        .player .player-content .player-exercises .p-E__container .finished_routine--container .finished_routine .top .user-stats {
          width: 100%;
          padding-left: 0;
          flex-direction: column;
          margin-top: 40px; }
          .player .player-content .player-exercises .p-E__container .finished_routine--container .finished_routine .top .user-stats .stat-block {
            margin-bottom: 40px;
            width: 100%;
            margin-right: 0; }
            .player .player-content .player-exercises .p-E__container .finished_routine--container .finished_routine .top .user-stats .stat-block .share-container.show {
              z-index: 2; }
  .player .player-content .player-exercises .carousel-container .close {
    right: 20px;
    top: 20px;
    left: auto;
    border-radius: 500px;
    background: #1d2038;
    box-shadow: 0 0 14px #1b1e36;
    width: 40px;
    height: 40px; }
    .player .player-content .player-exercises .carousel-container .close svg {
      width: 20px;
      height: 20px; }
  .player .player-content .player-exercises .carousel-container #prev {
    top: 20px;
    left: 20px;
    border-radius: 500px;
    background: #2f3352;
    box-shadow: 0 0 14px #2f3352;
    width: 40px;
    height: 40px;
    overflow: hidden;
    opacity: 1; }
    .player .player-content .player-exercises .carousel-container #prev > div {
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
      margin-right: 50px;
      margin-left: 5px; }
    .player .player-content .player-exercises .carousel-container #prev svg {
      width: 28px;
      height: 28px; }
  .player .player-content .right {
    width: 100%;
    position: absolute;
    z-index: 9;
    transition: all 0.3s ease;
    right: -100%;
    height: calc(100vh - 80px); }
    .player .player-content .right.open {
      right: 0; }
      .player .player-content .right.open .back-to-list {
        display: inline-flex !important;
        width: 30px;
        height: 30px;
        border-radius: 500px;
        background: #1d2038;
        position: absolute;
        cursor: pointer;
        z-index: 999;
        top: 20px;
        left: 20px;
        box-shadow: 0 0 14px #1b1e36; }
        .player .player-content .right.open .back-to-list svg {
          width: 30px;
          height: 30px; }
          .player .player-content .right.open .back-to-list svg path {
            fill: #ffffff; }
    .player .player-content .right .player-right .content h2 {
      margin-top: 40px;
      font-size: 22pt; }
    .player .player-content .right .player-right .top .content {
      padding: 40px 20px; }
    .player .player-content .right .player-right .bottom {
      padding: 40px 20px; }
  .c {
    height: calc(100vh - 80px); }
  .l .lBanner {
    height: calc(100vh - 80px);
    margin-left: -20px;
    margin-right: -20px;
    padding: 0 20px 20px; }
    .l .lBanner .lBanner-content h1 {
      font-size: 34pt;
      line-height: 34pt; }
    .l .lBanner .lBanner-content h2 {
      font-size: 20.5pt; }
    .l .lBanner .lBanner-content h1, .l .lBanner .lBanner-content h2 {
      max-width: 300px; }
    .l .lBanner .lBanner-content p {
      margin-top: 30px;
      font-size: 12pt; }
  .l .lRoutines {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 0;
    padding-right: 0;
    width: calc(100% + 40px); }
    .l .lRoutines h2 {
      padding: 0 20px; }
    .l .lRoutines .lRoutines-categories {
      padding: 0 20px; }
      .l .lRoutines .lRoutines-categories a {
        font-size: 12pt;
        margin-bottom: 0;
        margin-right: 0; }
  .l .lMusic {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
    width: calc(100% + 40px); }
  .l .lReviews .slider .slider-list {
    height: 230px !important; }
  .l .lBlog {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
    width: calc(100% + 40px); }
    .l .lBlog .blog-top {
      flex-direction: column; }
      .l .lBlog .blog-top a {
        margin-top: 10px; }
    .l .lBlog .blog-preview {
      flex-wrap: wrap; }
      .l .lBlog .blog-preview .article {
        width: 100%;
        margin-bottom: 30px; }
  .l .lNewsletter {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
    width: calc(100% + 40px); }
    .l .lNewsletter #mc_embed_signup_scroll p {
      text-align: center;
      padding: 0 20px; }
  .l footer {
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 80px; }
  .premium {
    padding-left: 0;
    padding-right: 0;
    display: block;
    margin-bottom: 61px; }
    .premium .premium-container {
      width: 100%; }
  .shop .shop-items .item .img-block {
    width: 220px;
    height: 220px; }
  .r > div {
    padding-left: 0;
    padding-right: 0; }
  .r .r-bar {
    padding-bottom: 0; }
    .r .r-bar .r-tabs .r-tab,
    .r .r-bar .r-tabs #r-tab-indicator {
      width: 80px; }
  .r .rR1 hr {
    margin-top: 20px; }
  .r .rR1 .rR1-seperator {
    flex-wrap: wrap; }
    .r .rR1 .rR1-seperator > div {
      width: 100%;
      margin-left: 0; }
    .r .rR1 .rR1-seperator .rR1-price {
      margin-bottom: 10px; }
      .r .rR1 .rR1-seperator .rR1-price .rB {
        -webkit-transform: scale(1);
                transform: scale(1);
        margin-left: 0;
        margin-right: 0;
        width: 100%; }
        .r .rR1 .rR1-seperator .rR1-price .rB h3 {
          font-size: 18pt; }
        .r .rR1 .rR1-seperator .rR1-price .rB .rB-cat {
          font-size: 13pt; }
  .r .rR3 .rR3-block {
    padding: 20px; }
  .r .rR3 .rR3-data,
  .r .rR3 .rR3-info {
    flex-wrap: wrap; }
    .r .rR3 .rR3-data p,
    .r .rR3 .rR3-info p {
      margin-right: 20px; }
    .r .rR3 .rR3-data .add-a-review,
    .r .rR3 .rR3-info .add-a-review {
      margin: 0; }
    .r .rR3 .rR3-data > *,
    .r .rR3 .rR3-info > * {
      margin-bottom: 10px; }
  .j {
    width: 100%;
    height: calc(100% - 170px);
    padding-top: 20px; }
    .j .j-container {
      height: calc(100%);
      margin-bottom: 0;
      flex-wrap: wrap;
      overflow: hidden;
      box-shadow: none; }
      .j .j-container > div {
        width: 100% !important;
        height: 100% !important;
        padding: 30px 20px !important;
        border-radius: 0 !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: all 0.3s ease; }
      .j .j-container .login-signin .signin-email {
        margin-bottom: 0; }
      .j .j-container .login-signin .signup-mobile {
        display: block; }
      .j .j-container .login-signin .modal a {
        right: 50px; }
      .j .j-container .login-signup {
        padding-bottom: 20px; }
        .j .j-container .login-signup .signin-mobile {
          display: block; }
        .j .j-container .login-signup .step {
          width: 100%;
          margin: 0;
          padding: 30px;
          overflow: scroll;
          display: flex;
          flex-direction: column;
          justify-content: center; }
          .j .j-container .login-signup .step input, .j .j-container .login-signup .step button, .j .j-container .login-signup .step .button-raise {
            width: 100% !important;
            padding: 10px !important;
            font-size: 12pt !important; }
  .at_on-boarding .app-max .main-content .content {
    align-items: baseline !important; }
  .at_on-boarding .oB {
    height: calc(100% - 61px);
    width: 100%;
    padding: 20px 0; }
    .at_on-boarding .oB .get-started {
      height: 100%;
      padding: 40px 30px !important;
      overflow: hidden; }
      .at_on-boarding .oB .get-started .getstarted-steps {
        overflow: visible;
        height: 300px;
        max-width: 400px; }
        .at_on-boarding .oB .get-started .getstarted-steps .step {
          width: 100%;
          max-width: 400px;
          flex-direction: column; }
          .at_on-boarding .oB .get-started .getstarted-steps .step .tooltip.fade.showTooltip::before {
            -webkit-transform: translate3d(0, -60px, 0);
                    transform: translate3d(0, -60px, 0); }
          .at_on-boarding .oB .get-started .getstarted-steps .step .step-container {
            margin: 0;
            width: 100%; }
            .at_on-boarding .oB .get-started .getstarted-steps .step .step-container input {
              width: 100% !important;
              border-radius: 5px;
              margin-bottom: 10px; }
            .at_on-boarding .oB .get-started .getstarted-steps .step .step-container .step-units {
              border-radius: 5px;
              margin-right: 10px; }
            .at_on-boarding .oB .get-started .getstarted-steps .step .step-container .step-buttons {
              margin: 0 10px 10px 0 !important; }
              .at_on-boarding .oB .get-started .getstarted-steps .step .step-container .step-buttons span {
                font-size: 13pt; }
            .at_on-boarding .oB .get-started .getstarted-steps .step .step-container .step-textarea {
              font-size: 13pt;
              width: 100%; }
          .at_on-boarding .oB .get-started .getstarted-steps .step .step-arrow {
            display: none; }
          .at_on-boarding .oB .get-started .getstarted-steps .step .step-nav {
            display: flex;
            width: 100%;
            margin-top: 40px;
            justify-content: space-between; }
            .at_on-boarding .oB .get-started .getstarted-steps .step .step-nav .step-arrow {
              background: white;
              height: 49px;
              padding: 10px 10px;
              display: inline-flex;
              border-radius: 5px;
              font-size: 1.2rem;
              align-items: center;
              justify-content: center;
              text-align: center;
              width: calc(50% - 5px);
              cursor: pointer;
              font-weight: 500; }
              .at_on-boarding .oB .get-started .getstarted-steps .step .step-nav .step-arrow.step-arrow-left {
                background: black;
                color: white; }
          .at_on-boarding .oB .get-started .getstarted-steps .step#step-1 .step-nav .step-arrow {
            width: 100%; }
          .at_on-boarding .oB .get-started .getstarted-steps .step.profile-picture .step-container {
            width: 100%; }
  .s, .pS {
    padding-left: 0;
    padding-right: 0; }
  .modal-inset {
    min-width: none;
    max-width: none;
    width: calc(100% - 60px);
    padding: 50px 30px 30px;
    margin: 0 30px; }
  .forgot-password {
    padding-left: 0;
    padding-right: 0; }
    .forgot-password h2 {
      font-size: 26pt;
      text-align: center;
      max-width: 300px; }
    .forgot-password input, .forgot-password button {
      font-size: 18pt; } }

@media (max-width: 680px) {
  .download-app img:first-of-type {
    margin: 0 0 30px 0; } }

@media (max-width: 640px) {
  .s .s-search,
  .s .s-list {
    padding-left: 0;
    padding-right: 0; }
  .s .s_container .content .halfs-group {
    flex-wrap: wrap; }
    .s .s_container .content .halfs-group .group {
      width: 100%;
      margin-bottom: 30px; }
      .s .s_container .content .halfs-group .group:last-child {
        margin-bottom: 0; }
  .player .player-content .right .player-right .routine-view .top {
    flex-direction: column; }
    .player .player-content .right .player-right .routine-view .top > * {
      width: 100%; }
    .player .player-content .right .player-right .routine-view .top .content {
      order: 2; }
    .player .player-content .right .player-right .routine-view .top .image {
      height: 45vh; }
      .player .player-content .right .player-right .routine-view .top .image img {
        object-position: bottom right; }
  .player .player-content .right .player-right .content h2 {
    margin-top: 0px;
    font-size: 26pt; }
  .player .player-content .right .player-right .player-exercises .middle .countdown-clock {
    height: 150px; }
    .player .player-content .right .player-right .player-exercises .middle .countdown-clock .time {
      font-size: 60pt; }
  .player .player-content .right.open .back-to-list {
    width: 50px;
    height: 50px; }
    .player .player-content .right.open .back-to-list svg {
      width: 50px;
      height: 50px; }
  .pS .progress {
    flex-direction: column;
    padding: 20px 0;
    align-items: baseline; }
    .pS .progress .progress-step {
      margin-bottom: 10px; }
      .pS .progress .progress-step:last-child {
        margin-bottom: 0; } }

@media (max-width: 440px) {
  .eList .eE {
    padding-top: 15px;
    padding-bottom: 15px; }
    .eList .eE .eE-info {
      align-items: baseline;
      flex-direction: column; }
      .eList .eE .eE-info h3 {
        margin-bottom: 8px; }
      .eList .eE .eE-info p {
        opacity: 0.7; } }

