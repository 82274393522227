.exercise {
  position: fixed;
  right: -2000px;
  top: 0;
  width: calc(50% - 100px);
  min-width: 500px;
  max-width: 700px;
  z-index: 99;
  background: #172edc;
  height: 100vh;
  padding: 40px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-box-shadow: -5px 0px 50px 0px rgba(0,0,0,0.35);
  -moz-box-shadow: -5px 0px 50px 0px rgba(0,0,0,0.35);
  box-shadow: -5px 0px 50px 0px rgba(0,0,0,0.35);
  .exercise-top {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    > div {
      cursor: pointer; }
    > p {
      margin-left: 60px;
      margin-right: 20px;
      text-transform: uppercase;
      font-family: GT-Medium, sans-serif; }
    .info-button {
      text-transform: capitalize; } }
  .exercise-middle {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
    .video {
      height: 400px; }
    .exercise-amount {
      position: absolute;
      top: 20px;
      left: 20px;
      font-size: 0.8rem;
      font-family: GT-Medium, sans-serif;
      background-color: #213af9;
      padding: 6px 15px 5px;
      border-radius: 5px;
      -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
      -moz-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5); } }
  .exercise-bottom {
    margin-top: 40px;
    font-family: GT-Light, sans-serif; } }
.exercise.show {
  right: 0px; }
