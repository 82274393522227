.left-sidebar {
  width: 170px;
  //background: #fff
  position: fixed;
  left: 40px;
  top: 70px;
  height: calc(100vh - 70px);
  max-height: calc(100vh - 70px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 30px;
  border-right: 1px solid #e6ecf1;
  .copyright {
    margin-top: 40px;
    .social-media {
      img {
        height: 16px;
        width: 16px;
        margin-right: 5px;
        margin-bottom: 10px; } }
    .extra {
      margin-bottom: 5px;
      color: #4250ff;
      font-weight: 700;
      font-size: 8pt;
      display: block; }
    p {
      font-size: 8pt; } } }

//CATEGORIES
.rC {
  padding-top: 40px;
  h3 {
    font-size: 10pt;
    font-weight: 700;
    margin-bottom: 10px;
    text-transform: uppercase; }
  .rC-cat {
    font-size: 10pt;
    border-radius: 5px;
    padding: 5px 0;
    color: #111;
    display: block;
    opacity: 0.6;
    transition: all 0.3s ease;
    &:hover, &.activeSide {
      opacity: 1;
      transform: scale(1.05) translateX(10px); } } }


