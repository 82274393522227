.landing {
  .app-max {
    padding-left: 0;
    .main-content {
      .content {
        margin-left: 0;
        width: 100%;
        padding: 0 40px; } } } }

.l {
  width: 100%;
  .lBanner {
    margin-left: -40px;
    margin-right: -40px;
    height: calc(100vh - 50px);
    background-size: cover;
    background-position: top left;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .lBanner-content {
      margin-bottom: 80px;
      text-align: center;
      h1, h2 {
        text-transform: uppercase; }
      h2 {
        opacity: 0.7;
        font-weight: 500;
        font-size: 24pt;
        margin-left: auto;
        margin-right: auto; }
      h1 {
        font-size: 40pt;
        max-width: 600px;
        line-height: 36pt;
        margin-top: 10px;
        margin-bottom: 30px;
        font-weight: 700;
        margin-left: auto;
        margin-right: auto; }
      p {
        background: #4a60ff;
        display: inline-block;
        border: 2px solid #4a60ff;
        border-radius: 5px;
        padding: 12px 25px;
        font-weight: 500;
        font-size: 14pt;
        color: white;
        cursor: pointer;
        box-shadow: 0 3px 10px 0 #959dd8;
        transition: all 0.3s ease;
        margin-top: 40px;
        &:hover {
          transform: scale(1.05);
          box-shadow: 0 3px 30px 0 #959dd8; } }
      a.two-free {
        p {
          position: relative;
          &:after {
            content: 'Limited time offer.';
            position: absolute;
            bottom: -30px;
            font-size: 10pt;
            left: calc(50% - 50px);
            color: black;
            opacity: 0.7;
            font-weight: normal; } } } }
    .lBanner-scroll {
      position: absolute;
      bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      p {
        font-weight: 500;
        font-size: 12pt; }
      img {
        margin-top: 5px;
        height: 22px;
        width: 22px; } } }
  .lPrograms {
    text-align: center;
    h3, h4 {
      opacity: 0.8; }
    h3 {
      font-size: 16pt; }
    h4 {
      font-size: 13pt;
      margin-top: 5px; }
    h2 {
      font-size: 20pt;
      margin: 50px 0 30px; }
    .lPrograms-options {
      max-width: 600px;
      margin: 0 auto;
      p {
        display: inline-block;
        border: 1px solid #4a60ff;
        border-radius: 5px;
        padding: 12px 16px 10px;
        color: #4a60ff;
        margin: 10px;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
          background: #4a60ff;
          color: white; } } } }
  .lRoutines {
    background: #F7F9FA;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: -40px;
    margin-right: -40px;
    width: calc(100% + 80px);
    text-align: center;
    .rB {
      text-align: left; }
    h2 {
      font-size: 20pt;
      margin-bottom: 20px; }
    .or-you-can {
      opacity: 0.5;
      margin-top: 100px;
      font-size: 16pt;
      margin-bottom: 40px; }
    .lRoutines-categories {
      max-width: 700px;
      margin: 0 auto;
      a {
        display: inline-block;
        border: 1px solid #4a60ff;
        border-radius: 5px;
        padding: 10px 16px 10px;
        color: #4a60ff;
        margin: 10px;
        cursor: pointer;
        transition: all 0.3s ease;
        font-size: 14pt;
        &:hover {
          background: #4a60ff;
          color: white; } } }
    .lRoutines-popular {
      white-space: nowrap;
      overflow-x: scroll;
      padding: 20px; } }
  .lTraining {
    text-align: center;
    > div {
      margin-bottom: 70px;
      max-width: 550px;
      margin-left: auto;
      margin-right: auto; }
    h2 {
      font-size: 20pt;
      margin-bottom: 40px;
      span {
        position: relative;
        display: inline-block;
        margin-right: 7px;
        font-weight: 700; }
      span::before {
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 50px;
        background: #0edc0e;
        display: block;
        position: absolute;
        top: 2px;
        left: -4px;
        transform: rotate(-45deg); } }
    p {
      opacity: 0.6;
      margin-bottom: 25px;
      font-size: 12pt;
      line-height: 18pt;
      b {
        font-weight: 600;
        font-weight: normal; } }
    a {
      border: 1px solid #4a60ff;
      color: #4a60ff;
      padding: 10px 16px 8px;
      border-radius: 5px;
      display: inline-block;
      &:hover {
        background: #4a60ff;
        color: white; } } }
  .lMusic {
    background: #111;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: -40px;
    margin-right: -40px;
    width: calc(100% + 80px);
    text-align: center;
    color: white;
    position: relative;
    h2 {
      font-size: 20pt;
      margin-bottom: 20px; }
    p {
      margin: 20px 0 60px;
      opacity: 0.7;
      font-size: 12pt; }
    iframe {
      border-radius: 10px;
      overflow: hidden;
      border: 2px solid #4a60ff;
      padding: 5px 5px 0;
      width: 90%;
      max-width: 500px; }
    .notes {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: none;
      .note {
        width: 30px;
        height: 30px;
        opacity: 0.15;
        position: absolute; }
      .note:nth-of-type(1) {
        left: 80%;
        top: 50%; }
      .note:nth-of-type(2) {
        left: 80.2%;
        top: 50.2%; }
      .note:nth-of-type(3) {
        left: 20%;
        top: 78%; }
      .note:nth-of-type(4) {
        left: 20.2%;
        top: 78.2%; }
      .note:nth-of-type(5) {
        left: 22%;
        top: 15%;
        transform: rotate(-10deg); }
      .note:nth-of-type(6) {
        left: 22.2%;
        top: 15.2%;
        transform: rotate(-10deg); }
      .note:nth-of-type(7) {
        left: 72%;
        top: 16%;
        transform: rotate(10deg); }
      .note:nth-of-type(8) {
        left: 72.2%;
        top: 16.2%;
        transform: rotate(10deg); }
      .note:nth-of-type(9) {
        left: 14%;
        top: 50%;
        transform: rotate(10deg); }
      .note:nth-of-type(10) {
        left: 14.2%;
        top: 50.2%;
        transform: rotate(10deg); }
      .note:nth-of-type(11) {
        left: 75%;
        top: 80%;
        transform: rotate(10deg); }
      .note:nth-of-type(12) {
        left: 75.2%;
        top: 80.2%;
        transform: rotate(10deg); } } }
  .lReviews {
    background: #F7F9FA;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: -40px;
    margin-right: -40px;
    width: calc(100% + 80px);
    text-align: center;
    h2 {
      font-size: 20pt;
      margin-bottom: 20px; }
    .write-review {
      background: #4a60ff;
      display: inline-block;
      border: 2px solid #4a60ff;
      border-radius: 5px;
      padding: 12px 25px;
      font-weight: 500;
      font-size: 12pt;
      color: white;
      cursor: pointer;
      box-shadow: 0 3px 10px 0 #959dd8;
      transition: all 0.3s ease;
      margin-top: 40px;
      &:hover {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
        box-shadow: 0 3px 30px 0 #959dd8; } }
    .slider {
      padding: 20px 0 60px;
      .slider-control-centerright,
      .slider-control-centerleft {
        display: none; }
      .slider-list {
        height: 180px!important; }
      .slider-slide {
        height: 100%!important;
        .slide {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .stars {
            span {
              background-size: 25px 22px;
              height: 22px;
              width: 125px;
              display: block;
              margin: 20px auto 30px;
              background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAWCAYAAAA1vze2AAAA40lEQVR42rXW3Q2DIBAH8I7Ae18cgREcgQ3qBtgJHEFGcQO6QR2Btz7WDejZXHL1EtIDxOQfHvj4gRjwkvO87tfHvzYxRp4swEDiXrZEHCKuJRIQCeciBOgvQNEtkJEhYwtkZchai3BAHQCKEiP4ab6pc31wPIPIYbb+JMBDVOp17ZgtXRX2s6k94VAH8QWz7+QbT5gVApZ65SNKiKgaZBAiQw2yCJGlBtmEyFaA0N3B8oTcsOR1pgRxbJCJ1U+s3pUg4Wf2Onn806qCFOF3xwxRgsNzxvZdDmIgfeYPRg/RKeQDVyEPzMDbhwwAAAAASUVORK5CYII=); } }
          p {
            max-width: 550px;
            margin: 0 auto;
            line-height: 1.6; } } } } }
  .lBlog {
    padding-left: 40px;
    padding-right: 40px;
    margin-left: -40px;
    margin-right: -40px;
    width: calc(100% + 80px);
    .blog-top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
      align-items: baseline;
      h2 {
        text-transform: uppercase;
        font-size: 20pt; }
      a {
        display: inline-block;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          height: 1px;
          background: #111;
          bottom: -3px;
          transition: all 0.2s ease; }
        &:hover {
          &::after {
            bottom: -5px; } } } }
    .blog-preview {
      display: flex;
      justify-content: space-between;
      .article {
        width: calc(33% - 20px);
        overflow: hidden;
        .article-img {
          width: 100%;
          height: 220px;
          border-radius: 5px;
          overflow: hidden;
          .blur {
            width: 100%;
            height: 100%; }
          img {
            object-fit: cover;
            width: 100%;
            height: 100%; } }
        h3 {
          font-weight: 600;
          margin: 20px 0 10px;
          font-size: 14pt; }
        p {
          opacity: 0.65;
          font-size: 11pt;
          line-height: 17pt; }
        a {
          display: flex;
          align-items: center;
          color: #4a60ff;
          margin-top: 20px;
          font-size: 11pt;
          svg {
            width: 12px;
            height: 12px;
            fill: #4a60ff;
            transform: rotate(180deg);
            margin-left: 6px;
            transition: all 0.3s ease; }
          &:hover {
            svg {
              transform: rotate(180deg) translateX(-5px); } } } } } }
  .lNewsletter {
    padding-left: 40px;
    padding-right: 40px;
    margin-left: -40px;
    margin-right: -40px;
    width: calc(100% + 80px);
    background: #F7F9FA;
    border-bottom: 1px solid #4a60ff;
    #mc_embed_signup_scroll {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      max-width: 500px;
      margin: 0 auto;
      position: relative;
      .block {
        display: flex;
        margin-top: 25px;
        width: 100%;
        border: 1px solid #4a60ff;
        border-radius: 500px;
        overflow: hidden; }
      label {
        font-weight: 700;
        text-align: center;
        font-size: 20pt;
        text-transform: capitalize; }
      p {
        opacity: 0.7;
        font-size: 13pt;
        margin-top: 15px;
        margin-bottom: 20px; }
      input[type='email'] {
        width: 70%;
        background: white;
        border: 0;
        padding: 10px 20px;
        font-size: 12pt;
        color: #4a60ff;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        box-shadow: none;
        border-right: 1px solid #4a60ff;
        height: 40px; }
      input[type='email']::placeholder {
        color: #4a60ff;
        text-transform: capitalize; }
      .clear {
        width: 30%; }
      input[type='submit'] {
        width: 100%;
        background: #4a60ff;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        color: white;
        padding: 10px;
        font-size: 12pt;
        border: 0;
        height: 40px; } } }
  footer {
    background: #406aff;
    margin-left: -40px;
    margin-right: -40px;
    display: flex;
    justify-content: center; }
  .lFooter {
    padding-left: 40px;
    padding-right: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    max-width: 600px;
    background: #406aff;
    color: white;
    margin: 0 auto;
    p {
      display: inline-block;
      font-size: 10pt; }
    .extra {
      color: #acc0ff;
      font-weight: 600;
      margin-left: 15px;
      font-size: 10pt; }
    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      filter: invert(1); }
    .footer-left {
       margin-bottom: 10px; }
    .footer-extra {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      ul {
        padding: 0;
        list-style: none;
        margin: 40px 80px 0 0;
        h3 {
          font-weight: 800;
          color: #fff;
          font-size: 11pt;
          margin-bottom: 20px; }
        a {
          display: block;
          margin: 10px 0;
          font-size: 11pt;
          opacity: 0.8;
          transition: all 0.3s ease;
          &:hover {
            opacity: 1; } } } } } }
