.header-container {
  height: 70px;
  width: 100%;
  top: 0;
  transition: all 0.3s ease;
  z-index: 9;
  background: #F7F9FA;
  position: fixed;
  border-bottom: 1px solid #d4dadf;
  box-shadow: 0 3px 10px 0 rgba(71,71,82,.08);
  .top-bar {
    > div:first-child {
      display: flex; } }
  .top-bar {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    // &.scrolled
    //   box-shadow: 0 1px 0px 0px rgba(236, 236, 236, 0.8)
    //// background: white
    .logo-container {
      height: 50px;
      width: 50px;
      display: inline-flex;
      align-items: center;
      position: relative;
      &::after {
        // content: 'LiveOff'
        position: absolute;
        // border: 1px solid #888888
        border-radius: 3px;
        color: #505050;
        line-height: 1;
        // font-size: 7.5pt
        font-size: 14pt;
        font-weight: 600;
        color: #000;
        padding: 3px 5px;
        left: 60px;
        // letter-spacing: 0.5pt
 }        // text-transform: uppercase
      img {
        height: 36px; } }
    .menu {
      li {
        a {
          text-transform: uppercase;
          font-size: 10pt;
          opacity: 0.6;
          transition: all 0.3s ease;
          &:hover {
            opacity: 0.8; } }
        a.activeLink {
          opacity: 1; } }
      #menu-player {
        a {
          color: #406aff;
          opacity: 1; } }
      #menu-premium {
        background: #406aff;
        padding: 5px 10px 4px;
        border-radius: 10px;
        a {
          opacity: 1;
          color: #fff;
          line-height: 1;
          font-size: 9pt;
          font-weight: 600; } } }
    form {
      position: relative;
      color: #111;
      width: calc(100% - 130px);
      tags {
        margin-left: 45px;
        max-width: 320px;
        display: flex;
        tag > div::before {
          background: #ffffff;
          border: 1px solid #efefef; } }
      img {
        position: absolute;
        top: calc(50% - 15px);
        left: 0;
        opacity: 0.2;
        height: 30px; } }
    .left-side {
      width: 100px; }
    .top-right {
      display: flex;
      align-items: center;
      a {
        position: relative;
        display: flex; }
      .cartBtn.notEmpty::before {
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 500px;
        background: #ff4a48;
        position: absolute;
        right: 7px; }
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px; }
      .profile-img {
        width: 30px;
        height: 30px;
        margin-left: 5px;
        margin-right: 0;
        border-radius: 500px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border: 1px solid #d5d6de; }
      .profile-img.guest-profile {
        background-position: center 5px; } }

    ul {
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;
      align-items: center;
      li.profile-li {
        margin-left: 40px; }
      li {
        margin-left: 20px;
        a {
          display: flex;
          align-items: center;
          color: #0c0d46;
          p {
            font-size: 0.9rem;
            margin-left: 5px; } }
        .signup-btn {
          background: #4a60ff;
          color: white;
          border-radius: 5px;
          padding: 6px 12px;
          font-size: 10pt;
          font-weight: 700;
          text-transform: uppercase;
          box-shadow: 0 3px 5px 0 #bac0ec; } }
      .points {
        border-radius: 500px;
        border: 1px solid #406aff;
        padding: 2px 7px;
        margin-left: 20px;
        p {
          font-size: 8pt;
          color: #406aff;
          font-weight: 500;
          span {
            font-weight: 400;
            font-size: 7.5pt; } } } } } }
