.eqList {
  margin: 0 0 60px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  li {
    position: relative;
    z-index: 1;
    color: white;
    width: 100px;
    margin: 0 20px 20px 0;
    display: inline-block;
    transition: all 0.2s ease-in-out;;
    white-space: normal;
    border-radius: 10px;
    flex-direction: column;
    .img-block {
      width: 100px;
      height: 100px;
      margin-bottom: 15px;
      background: white;
      padding: 15px;
      border-radius: 5px;
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
    p {
      font-size: 10.5pt;
      margin-bottom: 10px;
      font-weight: 500;
      line-height: 15pt;
      opacity: 0.8;
    }
    a {
      padding: 5px 10px;
      border-radius: 5px;
      display: inline-block;
      font-size: 10pt;
      margin-right: 5px;
      margin-bottom: 5px;
      color: white;
      background: #5d71ff;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
}