.ask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  padding: 20px;
  .ask-container {
    background-color: #1a2dc2;
    border-radius: 5px;
    width: 600px;
    height: auto;
    margin-bottom: 70px;
    padding: 40px;
    min-height: 420px;
    position: relative;
    .close-ask {
      position: absolute;
      right: 30px;
      top: 20px;
      cursor: pointer; }
    .ask-block {
      margin-bottom: 20px;
      .ask-block-title {
        text-transform: uppercase;
        font-family: GT-Regular, Poppins, sans-serif;
        font-size: 0.95rem;
        margin-bottom: 3px;
        letter-spacing: 0.05rem;
        span {
          text-transform: lowercase;
          font-size: 0.7rem; } }
      .input {
        width: 100%;
        background-color: rgb(21, 37, 155); }
      .textarea-description {
        height: 100px;
        font-size: 1.2rem;
        font-family: GT-Medium, sans-serif; }
      .input-tags {
        display: none; }
      tags {
        height: 50px;
        border-radius: 5px;
        border: 0;
        background-color: rgba(0, 0, 0, 0.2);
        color: white;
        font-family: GT-Medium, sans-serif;
        font-size: 1.2rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 4px 15px 0;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        width: 100%;
        tag {
          margin-bottom: 7px; } }
      tags > div {
        width: 100%;
        input {
          width: 100%; } }
      input.placeholder {
        height: 50px;
        background-color: transparent; } }
    .ask-block-end {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .ask-private {
        display: flex;
        align-items: center;
        font-family: Poppins, sans-serif;
        .ask-private-btn {
          background-color: rgba(0, 0, 0, 0.2);
          border: 0;
          width: 23px;
          height: 20px;
          border-radius: 5px;
          cursor: pointer;
          position: relative; }
        .ask-private-btn:after {
          background-image: url(/assets/check.png);
          position: absolute;
          top: -5px;
          left: 4px;
          width: 23px;
          height: 20px; }
        .ask-private-btn.checked:after {
          content: ''; }
        p {
          font-size: 0.75rem;
          font-weight: 500;
          margin-left: 10px; } }
      .ask-button {
        background-color: #0a124e; } } } }
.ask.hideAsk {
  display: none; }
