.player {
  color: white;
  background: #282d4f;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99999;
  overflow: hidden;
  box-shadow: 0 0 80px 0 rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease-in;
  .section-loader {
    .line {
      background: #4a60ff; } }
  .player-top {
    padding: 15px 40px;
    text-align: center;
    background: #1b1e36;
    box-shadow: 0 3px 50px 0 #1b1e36;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    z-index: 99;
    .left, .right {
      display: flex;
      align-items: center; }
    .logo {
      filter: brightness(0) invert(1);
      height: 36px; }
    .img-block {
      height: 36px;
      width: 36px;
      .profile-img {
        width: 30px;
        height: 30px;
        margin-left: 5px;
        margin-right: 0;
        border-radius: 500px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border: 1px solid #4b4e63; } }

    p, a {
      text-transform: uppercase;
      letter-spacing: 1pt;
      font-size: 11pt;
      color: #fff;
      display: inline-block; }
    .signup-btn {
      background: #4a60ff;
      color: white;
      border-radius: 5px;
      padding: 6px 12px;
      font-size: 10pt;
      font-weight: 700; }
    ul {
      padding: 0;
      margin: 0 0 0 82px;
      list-style: none;
      display: flex;
      li {
        opacity: 0.4;
        cursor: pointer;
        position: relative;
        transition: all 0.2s ease;
        margin-right: 30px;
        &::after {
          position: absolute;
          content: '';
          height: 3px;
          width: 100%;
          left: 0;
          bottom: -24px;
          background: transparent; }
        &.active, &:hover {
          opacity: 1; }
        &.active::after {
          background: #406aff; } } } }
  .player-content {
    display: flex;
    .left,
    .right {
      height: calc(100vh - 52px);
      overflow: hidden;
      transition: all 0.3s ease; }
    .left {
      width: 45%;
      padding-left: 40px;
      display: flex; }
    .right {
      width: 55%;
      background: #111322;
      box-shadow: -3px 0 50px 0 #1b1e36;
      overflow: scroll; }
    .side-menu {
      width: 200px;
      margin-top: 60px;
      ul {
        padding: 0;
        margin: 50px 0 0;
        list-style: none;
        li {
          font-size: 13pt;
          margin: 20px 0;
          letter-spacing: 0.3pt;
          opacity: 0.4;
          cursor: pointer;
          transition: all 0.2s ease;
          &.active, &:hover {
            opacity: 1; } } } }
    .list {
      width: calc(100% - 200px);
      overflow: scroll;
      height: 100%;
      padding-top: 60px;
      .search-list {
        position: relative;
        background: #222744;
        width: 100%;
        margin-top: 10px;
        input {
          background: transparent;
          width: 100%;
          border: 0;
          outline: none;
          padding: 10px 10px 10px 40px;
          font-size: 10pt;
          color: white;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          &::placeholder {
            color: #ffffff;
            opacity: 0.2; } }
        .search-icon {
          position: absolute;
          left: 10px;
          top: calc(50% - 10px);
          svg {
            width: 20px;
            height: 20px;
            opacity: 0.4;
            path {
              fill: #fff; } } } } } } }


.player-routines, .player-programs {
  margin-top: 50px;
  padding-bottom: 70px;
  .section-loader {
    margin-top: 30px; }
  .dropdown {
    margin-bottom: 30px;
    .t {
      color: #646a92;
      font-size: 100%; }
    > div {
      font-size: 11pt;
      letter-spacing: 0.3pt;
      font-weight: 600;
      display: flex;
      align-items: center;
      text-transform: capitalize;
      svg {
        margin-left: 8px;
        height: 14px;
        width: 14px;
        path {
          fill: #fff; } } } }
  .rB {
    margin-bottom: 30px;
    &:hover {
      transform: none; }
    h3 {
      color: white; }
    .rB-cat, .rB-pic {
      color: white;
      border-color: white; } }
  .item-selector {
    cursor: pointer;
    position: relative;
    margin-right: 40px;
    width: 200px;
    display: inline-block;
    &::before {
      display: none;
      content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMTgiIGhlaWdodD0iMTgiCnZpZXdCb3g9IjAgMCAxOTIgMTkyIgpzdHlsZT0iIGZpbGw6IzAwMDAwMDsiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxwYXRoIGQ9Ik0wLDE5MnYtMTkyaDE5MnYxOTJ6IiBmaWxsPSJub25lIj48L3BhdGg+PGcgZmlsbD0iI2ZmZmZmZiI+PGcgaWQ9InN1cmZhY2UxIj48cGF0aCBkPSJNMTc0LDMzLjUxNTYzbC0xMDgsMTA4bC00My43ODEyNCwtNDMuNzM0MzdsLTQuMjE4NzYsLTQuMjY1NjNsLTguNDg0MzcsOC40ODQzN2w0LjI2NTYzLDQuMjE4NzZsNTIuMjE4NzQsNTIuMjY1NjJsMTE2LjQ4NDM3LC0xMTYuNDg0Mzd6Ij48L3BhdGg+PC9nPjwvZz48L2c+PC9zdmc+);
      position: absolute;
      width: 35px;
      height: 33px;
      border-radius: 500px;
      background: #101321;
      top: -12px;
      right: -16px;
      z-index: 9;
      box-shadow: -2px 2px 15px 0 #101321;
      align-items: center;
      justify-content: center;
      padding-top: 2px; }
    &:hover::before {
      display: flex; }
    &.selected::before {
      background: #3b953e;
      box-shadow: -7px 6px 15px 0 rgba(43, 132, 45, 0.50);
      display: flex; }
    * {
      pointer-events: none; } } }

.player-programs {
  .empty-programs {
    padding-right: 20px;
    p {
      line-height: 1.4;
      font-size: 14pt; }
    a {
      margin-top: 20px;
      background: white;
      color: $blue;
      display: inline-block;
      padding: 10px 15px;
      border-radius: 5px;
      font-weight: 600;
      font-size: 14pt; } }
  .program-block {
    display: inline-flex;
    border-radius: 4px;
    padding: 20px;
    box-shadow: 0 3px 5px #282d4f;
    transition: all 0.3s ease;
    flex-direction: column;
    background: #3b4063;
    margin-right: 20px;
    &:hover {
      transform: translateY(-3px);
      box-shadow: 0 3px 20px #1e2135; }

    &.uncompleted {
      cursor: default;
      background: #6a6b3a;
      opacity: 0.5;
      &:hover {
        transform: none;
        box-shadow: none; } }

    h3 {
      font-size: 12pt;
      text-transform: uppercase;
      letter-spacing: 0.3pt;
      font-weight: 600; } } }

.player-right {
  .section-loader {
    padding: 40px; }
  .routine-view {
    overflow: hidden;
    .top {
      display: flex;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        background: transparent;
        background: linear-gradient(to top, rgba(0, 0, 0,1) 0%,rgba(125,185,232,0) 100%);
        height: 200px;
        bottom: 0; } }
    .content, .image {
      width: 50%;
      height: 400px;
      @media (min-width: 1160px) {
        height: 500px; }
      @media (min-width: 1600px) {
        height: 600px; }
      img {
        object-fit: cover;
        height: 100%;
        width: 100%; }
      .info {
        margin-top: 20px;
        display: flex;
        opacity: 0.7;
        > div {
          display: flex;
          align-items: center;
          font-size: 11pt; }
        svg {
          margin-right: 7px;
          height: 24px;
          width: 24px;
          path {
            fill: white; } }
        .sets {
          margin-right: 25px; } } }
    .content {
      padding: 50px 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: baseline;
      button.play {
        border: 0;
        background: transparent;
        display: flex;
        align-items: center;
        color: #ababab;
        font-size: 10.5pt;
        cursor: pointer;
        position: relative;
        z-index: 2;
        transition: all 0.5s ease;
        * {
          pointer-events: none; }
        > div {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          border-radius: 500px;
          height: 36px;
          width: 40px;
          padding-left: 2px;
          position: relative;
          transition: none;
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            background: #406aff;
            border-radius: 500px;
            transition: all 0.3s ease;
            width: 40px;
            z-index: -1;
            box-shadow: 0 3px 20px 0 #3d57b3; }
          svg {
            width: 18px;
            height: 18px;
            path {
              fill: white; } } }
        &:hover {
          color: white;
          > div {
            box-shadow: none;
            transition: all 1s ease;
            &::before {
              width: 155px; } } } }
      h2 {
        margin-bottom: 20px;
        font-size: 26pt; }
      .rB-cat {
        padding: 5px 10px 4px;
        border-radius: 5px;
        display: inline-block;
        font-size: 12pt;
        margin-right: 8px;
        margin-bottom: 8px;
        color: #fff;
        border: 1px solid #fff; } }

    .bottom {
      padding: 40px; }
    .subheader {
      font-weight: 600;
      margin-bottom: 20px;
      color: #444e62; }
    .exercises {
      padding-bottom: 40px;
      margin-bottom: 40px;
      .eList {
        .eList-hr {
          background: transparent;
          .eE {
            padding-left: 40px;
            padding-right: 40px;
            margin-left: -40px;
            margin-right: -40px;
            border-bottom: 0;
            &:hover {
              background: #1e2135; } } } } }
    .related-container {
      .rB {
        margin-bottom: 20px;
        margin-right: 20px;
        h3 {
          color: white; }
        .rB-cat, .rB-pic {
          color: white;
          border-color: white; } } } } }

.player.hide-right {
  .player-content {
    .left {
      width: 100%; }
    .right {
      width: 0%; } } }

.player-exercises {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: #16182b;
  display: flex;
  align-items: center;
  justify-content: center;
  .play-type {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #15182b;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    &.auto, &.manual {
      display: none; }
    button {
      color: rgba(255, 255, 255, 0.8);
      font-weight: 900;
      background: black;
      background: linear-gradient(to bottom left, #406aff 0%, #16182b 70%);
      box-shadow: 0 10px 20px rgba(22, 24, 43, 0.33);
      font-size: 46pt;
      width: 250px;
      height: 250px;
      border: 0;
      border-radius: 500px;
      overflow: hidden;
      transition: all 0.3s ease;
      line-height: 0.65;
      cursor: pointer;
      margin: 0 40px;
      &:hover {
        transform: scale(1.08);
        box-shadow: 0 15px 20px rgba(22, 24, 43, 0.5); } } }
  .top-player {
    width: 80%;
    height: 10%;
    position: fixed;
    top: 0;
    left: 10%;
    right: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    #prev, .p-E__countdown {
      position: absolute;
      left: 10%;
      z-index: 99999; }
    p.startBtn {
      color: #fff;
      opacity: 1;
      padding: 5px 15px 3px;
      font-size: 16pt;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      transition: all 0.3s ease; } }
  .carousel-container {
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    position: relative;
    .close {
      left: calc(55% - 60px);
      position: absolute;
      top: 40px;
      color: white;
      border-radius: 500px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: lowercase;
      opacity: 1;
      line-height: 1;
      cursor: pointer;
      transition: all 0.3s ease;
      z-index: 9999;
      * {
        pointer-events: none; }
      svg {
        width: 16px;
        height: 16px;
        opacity: 0.8;
        transition: all 0.3s ease; }
      &:hover {
        svg {
          opacity: 1; } } }
    #prev {
      position: absolute;
      left: 40px;
      top: 40px;
      z-index: 9999;
      cursor: pointer;
      display: flex;
      align-items: center;
      opacity: 0.8;
      transition: all 0.3s ease;
      > div {
        transform: rotate(-90deg) translateY(-15px);
        transition: all 0.3s ease; }
      &:hover {
        opacity: 1;
        > div {
          transform: rotate(-90deg) translateY(-20px); } }
      svg {
        width: 20px;
        height: 20px;
        path {
          transition: all 0.3s ease;
          fill: #dbdde0!important; } }
      &:hover {
        svg {
          path {
            fill: #fff!important; } } } } }
  .top {
    h2 {
      margin-bottom: 25px;
      font-size: 30pt;
      white-space: normal; }
    p {
      font-size: 12pt;
      line-height: 20pt;
      opacity: 0.7; } }
  .p-E__container {
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    color: white;
    justify-content: space-between;
    transition: all 0.3s ease;
    background: #1d203c;
    left: 0;
    top: 0;
    overflow: hidden;
    padding: 100px 60px 40px;
    z-index: -1;
    &.active {
      display: inline-flex;
      z-index: 1; }
    .p-E__content {
      padding-right: 60px;
      width: calc(55%);
      display: inline-flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: scroll;
      .top, .middle {
        position: relative;
        z-index: 2; }
      .middle {
        display: flex;
        align-items: center;
        .react-countdown-clock {
          margin-right: 20px;
          cursor: pointer; }
        .countdown-clock {
          width: 200px;
          height: 200px;
          border: 5px solid white;
          border-radius: 500px;
          margin-right: 20px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          .time {
            font-size: 70pt; } }
        .container {
          display: flex;
          flex-direction: column; }
        .pause-info, .sets {
          font-size: 13pt;
          opacity: 0.7;
          margin-bottom: 20px;
          font-weight: 600; }
        .e-amount {
          font-size: 24pt;
          font-weight: 700; } }
      .p-E__countdown {
        p {
          display: inline-block;
          border-radius: 5px;
          margin-right: 10px;
          cursor: default;
          padding: 2px 12px 0;
          font-size: 10pt;
          background: transparent;
          opacity: 0.4;
          transition: all 0.3s ease; }
        p.active {
          background: #fff;
          opacity: 1;
          color: #4a60ff; } }
      .timer {
        height: 65px;
        width: 65px;
        border: 2px solid white;
        border-radius: 500px;
        font-size: 20pt;
        display: inline-flex;
        align-items: center;
        justify-content: center; }
      .p-E__tags {
        p {
          display: inline-block;
          border: 1px solid white;
          border-radius: 5px;
          margin-right: 10px;
          margin-bottom: 10px;
          padding: 2px 12px 0;
          font-size: 11pt;
          cursor: default; } }
      .p-E__model {
        color: #fff;
        display: inline-flex;
        border-radius: 20px;
        margin-top: 20px;
        margin-right: 20px;
        opacity: 0.6;
        transition: all 0.3s ease;
        &:hover {
          opacity: 0.9; }
        a {
          display: inline-flex;
          align-items: center;
          line-height: 1; }
        svg {
          fill: #fff;
          width: 16px;
          height: 16px;
          margin-right: 8px;
          margin-bottom: 1px; } } }
    .p-E__image {
      position: absolute;
      right: 0;
      width: 45%;
      height: 100%;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      img.video {
        max-width: calc(100% - 40px); } }
    .p-E__pics {
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      position: relative;
      width: 50%;
      height: calc(100% + 120px);
      overflow: hidden;
      margin-top: -60px;
      margin-right: -60px;
      img {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%; } }
    .bottom {
      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        background: transparent;
        background: linear-gradient(to top, #000 0%, #1d203c 100%);
        height: 30%;
        bottom: 0;
        transition: all 0.3s ease; }
      &:hover {
        &::before {
          opacity: 0.7; }
        .next-txt, h3 {
          opacity: 0.8;
          svg {
            transform: rotate(90deg) translateY(-5px); } } }
      #next {
        cursor: pointer; }
      .next-txt {
          font-size: 15pt;
          color: #fff;
          position: relative;
          margin-bottom: 15px;
          z-index: 2;
          opacity: 0.4;
          transition: all 0.3s ease;
          display: flex;
          align-items: center;
          svg {
            transition: all 0.3s ease;
            width: 20px;
            height: 20px;
            transform: rotate(90deg);
            margin-left: 10px;
            margin-bottom: 2px;
            path {
              transition: all 0.3s ease;
              fill: #fff; } } }
      h3 {
        font-size: 22pt;
        transition: all 0.3s ease;
        font-weight: 600;
        position: relative;
        color: #ffffff;
        z-index: 2;
        opacity: 0.5; } }
    &.break-container {
      flex-direction: column;
      .inner {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: 1200px;
        margin: 0 auto; }
      .react-countdown-clock {
        position: absolute;
        right: 0;
        z-index: 2;
        top: calc(50% - 300px); }
      .countdown-clock {
        position: absolute;
        right: 0;
        z-index: 2;
        top: 0;
        width: 600px;
        height: 600px;
        border: 10px solid #406aff;
        border-radius: 500px;
        margin-right: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        .time {
          font-size: 100pt;
          color: #406aff; } }
      .close {
        position: absolute;
        left: 0;
        top: 0;
        text-transform: uppercase;
        letter-spacing: 1pt;
        opacity: 0.6;
        width: auto;
        height: auto;
        margin-left: 0; }
      h2 {
        font-size: 60pt;
        max-width: 500px;
        position: relative;
        z-index: 3;
        margin-top: 60px;
        color: white;
        font-weight: 900; } } } }

.finished_routine {
  margin: -120px -60px -40px;
  padding: 60px 40px;
  height: calc(100% + 160px);
  width: calc(100% + 120px);
  // background: #101220
  .top {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
  .back {
    display: flex;
    width: 100%;
    align-items: center;
    opacity: 0.5;
    transition: all 0.3s ease;
    cursor: pointer;
    margin-bottom: 40px;
    svg {
      transform: rotate(-90deg);
      transition: all 0.3s ease;
      width: 32px;
      height: 32px;
      path {
        fill: white; } }
    p {
      margin-left: 10px;
      font-size: 12pt;
      line-height: 1; }
    &:hover {
      opacity: 1;
      svg {
        transform: rotate(-90deg) translateY(-5px); } } }
  .user-info {
    width: 350px;
    display: inline-block;
    .img-block {
      width: 80px;
      height: 80px;
      margin-bottom: 40px;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 500px; } }
    h2 {
      font-weight: 400;
      font-size: 26pt;
      line-height: 1.2;
      b {
        font-weight: 600;
        font-weight: normal; } }
    p {
      opacity: 0.5;
      margin-top: 60px;
      font-size: 18pt;
      line-height: 1.4;
      span {
        font-size: 12pt;
        display: block;
        margin-top: 10px;
        line-height: 1.5;
        opacity: 0.8; } }
    @media (max-height: 900px) {
      width: 300px;
      .img-block {
        width: 60px;
        height: 60px;
        margin-bottom: 30px; }
      h2 {
        font-size: 23pt; }
      p {
        margin-top: 45px;
        font-size: 16pt; } } }
  .user-stats {
    width: calc(100% - 350px);
    display: inline-block;
    padding-left: 60px;
    display: inline-flex;
    justify-content: space-around;
    .stat-block {
      border: 2px solid #45476d;
      border-radius: 10px;
      padding: 40px 20px 45px;
      width: 250px;
      text-align: center;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .container {
        &:nth-of-type(2) {
          margin-top: 40px; } }
      .number {
        font-size: 70pt;
        span {
          font-weight: 700; } }
      .title {
        font-size: 16pt; }
      .info {
        opacity: 0.4;
        font-size: 10pt;
        line-height: 13pt;
        width: 90%;
        margin: 0 auto;
        color: #8689b8; }
      .share {
        position: absolute;
        bottom: -17px;
        left: calc(50% - 50px);
        width: 100px;
        padding: 7px;
        border-radius: 10px;
        font-size: 11pt;
        letter-spacing: 0.4pt;
        background: #1d203c;
        color: #878ab9;
        border: 2px solid #45476d;
        box-shadow: 0 0 15px 15px #1d203c;
        transition: all 0.3s ease;
        cursor: pointer;
        &:hover {
          transform: scale(1.03); } }
      .share-container {
        position: absolute;
        bottom: -25px;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease;
        padding: 0 10px;
        background: #26294a;
        display: flex;
        border-radius: 5px;
        left: calc(50% - 100px);
        &.show {
          bottom: -75px;
          opacity: 1;
          visibility: visible; }
        > div {
          margin: 10px 5px;
          cursor: pointer; }
        * div {
          height: 26px!important;
          width: 26px!important; }
        svg {
          height: 26px;
          width: 26px;
          border-radius: 500px; } } }
    @media (max-height: 900px) {
      width: calc(100% - 300px); } }
  .bottom {
    width: calc(100% + 90px);
    height: calc(100% - 507px + 100px);
    margin: 0 -45px -60px -45px;
    &::before {
      content: none!important; }
    .ReactChart {
      pointer-events: none;
      .Series {
        > g:nth-of-type(1) {
          path:nth-of-type(1) {
            fill: rgb(60, 72, 128) !important;
            stroke-width: 3px !important;
            stroke: #6979bf !important; } }
        > g:nth-of-type(2) {
          path:nth-of-type(1) {
            fill: rgb(78, 92, 154) !important;
            stroke-width: 3px !important;
            stroke: rgb(120, 135, 199) !important; } } } }
    @media (max-height: 900px) {
      height: calc(100% - 435px + 100px); } } }

.false_completion {}
