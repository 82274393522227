.schedule {
  width: 100%;
  h2 {
    margin-bottom: 40px;
    display: none; }
  .calendly-inline-widget {
    border-radius: 30px;
    overflow: hidden;
    position: relative;
    width: 700px!important;
    height: calc(100vh - 60px)!important;
    max-width: 100%;
    margin: 0 auto; } }

