.mobile-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: #f7f9fa;
  border-top: 1px solid #d4dadf;
  box-shadow: 0 -3px 10px 0 rgba(71, 71, 82, 0.08);
  display: none;
  ul {
    padding: 0;
    list-style: none;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding: 0 40px;
    .profile-img {
      width: 26px;
      height: 26px;
      margin-left: 5px;
      margin-right: 0;
      border-radius: 500px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border: 1px solid #4b4e63;
    }
  }
  .menuExtend {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    background: #406aff;
    background: linear-gradient(130deg, #406aff 0, rgb(30, 72, 209));
    transition: all 0.3s ease;
    opacity: 0;
    visibility: hidden;
    flex-wrap: wrap;
    flex-direction: column;
    color: white;
    .top {
      padding: 30px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      img {
        height: 46px;
        filter: brightness(0) invert(1);
      }
      svg {
        height: 30px;
        width: 30px;
        margin-top: 10px;
        path {
          fill: white;
        }
      }
    }
    ul {
      flex-direction: column;
      align-items: baseline;
      height: auto;
      padding: 30px;
      justify-content: flex-start;
      li {
        a {
          padding: 15px 0;
          display: block;
          p {
            font-size: 12pt;
            margin-bottom: 0!important;
          }
        }
      }
    }
    .copyright {
      padding: 30px;
      .social-media {
        margin-bottom: 20px;
        a {
          margin-right: 10px;
          img {
            height: 20px;
            filter: brightness(0) invert(1);
          }
        }
      }
      .extra {
        margin-bottom: 10px;
        display: block;
        font-size: 11pt;
        font-weight: 600;
      }
      p {
        font-size: 11pt;
      }
    }
    &.show {
      opacity: 1;
      visibility: visible;
      overflow: scroll;
    }
  }
}

// Large Screen
@media (min-width: 901px) {
  .mobile-menu,
  .back-to-list {
    display: none !important;
  }
}
// Medium Screen
@media (max-width: 900px) {
  h2 {
    font-size: 24pt;
  }
  .p-r_40 {
    padding-right: 20px;
  }
  .p-l_40 {
    padding-left: 20px;
  }
  .header-container {
    background: white;
    border-bottom: 1px solid #e6ecf1;
    box-shadow: none;
    display: none!important;
    .top-bar {
      padding: 0 20px;
      .menu {
        display: none;
      }
      .top-right {
        width: auto;
      }
      .profile-li {
        display: none;
      }
    }
  }
  .left-sidebar {
    display: none !important;
  }
  .app-container {
    &.at_join {
      min-height: auto;
      height: 100vh;
    }
    &.at_join .app-max {
      min-height: auto;
    }
    &.at_join .app-max .main-content .content {
      align-items: baseline;
    }
    .app-max {
      padding-left: 0;
      max-height: calc(100% - 80px);
      .main-content {
        margin-top: 0;
        > .content {
          margin-left: 0;
          width: 100%;
          padding: 0 20px;
        }
      }
    }
  }
  .mobile-menu {
    display: block;
    ul {
      padding: 0;
      list-style: none;
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 80px;
      padding: 0 20px;
      li {
        .link {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          position: relative;
          height: 40px;
          p {
            font-size: 8.5pt;
            opacity: 0.6;
            position: absolute;
            bottom: -3px;
          }
          svg {
            transform: scale(1.3);
          }
          .mobile-search {
            svg {
              margin-top: -3px;
              transform: scale(0.9);
            }
          }
        }
        .logo-container {
          height: 50px;
          width: 50px;
          display: inline-flex;
          align-items: center;
          position: relative;
          padding-left: 8px;
          img {
            height: 40px;
          }
        }
      }
    }
  }
  .rH {
    .rH-section {
      > * {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .schedule {
    padding: 0;
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px);
    .calendly-inline-widget {
      width: 100vw !important;
    }
  }
  .player {
    .player-top {
      display: none;
      .left {
        .logo-container {
          position: relative;
          display: inline-flex;
          align-items: center;
          &::after {
            content: "Beta";
            position: absolute;
            border: 1px solid #b6b6b6;
            border-radius: 3px;
            color: #aaaaaa;
            line-height: 1;
            font-size: 7.5pt;
            padding: 3px 5px;
            left: 60px;
            letter-spacing: 0.5pt;
            text-transform: uppercase;
          }
        }
        .menu {
          display: none;
        }
      }
    }
    .mobile-menu {
      background: #171a31;
      border-color: #20233d;
      box-shadow: 0 -3px 10px 0 rgba(133, 133, 155, 0.08);
      ul {
        li {
          svg,
          img {
            filter: brightness(0) invert(1);
          }
        }
      }
    }
    .player-content {
      .left {
        width: 100%;
        flex-direction: column;
        padding-left: 20px;
        height: calc(100vh - 80px);
        overflow: scroll;
        > * {
          width: 100%;
        }
        .side-menu {
          ul {
            display: flex;
            margin-top: 20px;
            li {
              margin-right: 40px;
            }
          }
        }
        .list {
          overflow: initial;
          height: auto;
        }
        .player-routines {
          .item-selector::before {
            content: none;
            display: none;
          }
        }
      }

      .player-exercises {
        .p-E__container {
          overflow: scroll;
          height: calc(100vh - 80px);
          padding: 0;
          flex-wrap: wrap;
          .p-E__content,
          .p-E__image {
            width: 100%;
            padding-right: 0;
          }
          .p-E__content {
            order: 2;
            padding: 40px 20px 30px;
            .middle {
              margin: 40px 0;
            }
            .bottom {
              position: relative;
              margin-right: -20px;
              margin-left: -20px;
              &::before {
                height: 100%;
              }
            }
          }
          .p-E__image {
            position: initial;
            order: 1;
            height: auto;
            img.video {
              max-width: 100%;
              width: 100%;
            }
          }
          &.break-container {
            padding: 20px;
            flex-wrap: nowrap;
            h2 {
              margin-top: 320px;
            }
            .countdown-clock {
              width: 300px !important;
              height: 300px !important;
              top: calc(50% - 220px);
              right: 20px;
            }
            .bottom {
              padding-top: 40px;
              padding-left: 0;
              padding-right: 0;
            }
          }
          .finished_routine--container {
            width: 100%;
            .finished_routine {
              margin: 0;
              width: 100%;
              height: auto;
              padding: 40px 20px;
              flex-wrap: wrap;
              .top {
                .user-stats {
                  width: 100%;
                  padding-left: 0;
                  flex-direction: column;
                  margin-top: 40px;
                  .stat-block {
                    margin-bottom: 40px;
                    width: 100%;
                    margin-right: 0;
                    .share-container.show {
                      z-index: 2;
                    }
                  }
                }
              }
            }
          }
        }
        .carousel-container {
          .close {
            right: 20px;
            top: 20px;
            left: auto;
            border-radius: 500px;
            background: #1d2038;
            box-shadow: 0 0 14px #1b1e36;
            width: 40px;
            height: 40px;
            svg {
              width: 20px;
              height: 20px;
            }
          }
          #prev {
            top: 20px;
            left: 20px;
            border-radius: 500px;
            background: #2f3352;
            box-shadow: 0 0 14px #2f3352;
            width: 40px;
            height: 40px;
            overflow: hidden;
            opacity: 1;
            > div {
              transform: rotate(-90deg);
              margin-right: 50px;
              margin-left: 5px;
            }
            svg {
              width: 28px;
              height: 28px;
            }
          }
        }
      }
      .right {
        width: 100%;
        position: absolute;
        z-index: 9;
        transition: all 0.3s ease;
        right: -100%;
        height: calc(100vh - 80px);
        &.open {
          right: 0;
          .back-to-list {
            display: inline-flex !important;
            width: 30px;
            height: 30px;
            border-radius: 500px;
            background: #1d2038;
            position: absolute;
            cursor: pointer;
            z-index: 999;
            top: 20px;
            left: 20px;
            box-shadow: 0 0 14px #1b1e36;
            svg {
              width: 30px;
              height: 30px;
              path {
                fill: #ffffff;
              }
            }
          }
        }
        .player-right {
          .content {
            h2 {
              margin-top: 40px;
              font-size: 22pt;
            }
          }
          .top {
            .content {
              padding: 40px 20px;
            }
          }
          .bottom {
            padding: 40px 20px;
          }
        }
      }
    }
  }
  .c {
    height: calc(100vh - 80px); // bottom menu
  }
  .l {
    .lBanner {
      height: calc(100vh - 80px); // bottom menu
      margin-left: -20px;
      margin-right: -20px;
      padding: 0 20px 20px;
      .lBanner-content {
        h1 {
          font-size: 34pt;
          line-height: 34pt;
        }
        h2 {
          font-size: 20.5pt;
        }
        h1, h2 {
          max-width: 300px;
        }
        p {
          margin-top: 30px;
          font-size: 12pt;
        }
      }
    }
    .lRoutines {
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 0;
      padding-right: 0;
      width: calc(100% + 40px);
      h2 {
        padding: 0 20px;
      }
      .lRoutines-categories {
        padding: 0 20px;
        a {
          font-size: 12pt;
          margin-bottom: 0;
          margin-right: 0;
        }
      }
    }
    .lMusic {
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px;
      width: calc(100% + 40px);
    }
    .lReviews {
      .slider .slider-list {
        height: 230px!important;
      }
    }
    .lBlog {
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px;
      width: calc(100% + 40px);
      .blog-top {
        flex-direction: column;
        a {
          margin-top: 10px;
        }
      }
      .blog-preview {
        flex-wrap: wrap;
        .article {
          width: 100%;
          margin-bottom: 30px;
        }
      }
    }
    .lNewsletter {
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px;
      width: calc(100% + 40px);
      #mc_embed_signup_scroll {
        p {
          text-align: center;
          padding: 0 20px;
        }
      }
    }
    footer {
      margin-left: -20px;
      margin-right: -20px;
      margin-bottom: 80px;
    }
  }
  .premium {
    padding-left: 0;
    padding-right: 0;
    display: block;
    margin-bottom: 61px;
    .premium-container {
      width: 100%;
    }
  }
  .shop {
    .shop-items {
      .item {
        .img-block {
          width: 220px;
          height: 220px;
        }
      }
    }
  }
  .r {
    > div {
      padding-left: 0;
      padding-right: 0;
    }
    .r-bar {
      padding-bottom: 0;
      .r-tabs {
        .r-tab,
        #r-tab-indicator {
          width: 80px;
        }
      }
    }
    .rR1 {
      hr {
        margin-top: 20px;
      }
      .rR1-seperator {
        flex-wrap: wrap;
        > div {
          width: 100%;
          margin-left: 0;
        }
        .rR1-price {
          margin-bottom: 10px;
          .rB {
            transform: scale(1);
            margin-left: 0;
            margin-right: 0;
            width: 100%;
            h3 {
              font-size: 18pt;
            }
            .rB-cat {
              font-size: 13pt;
            }
          }
        }
      }
    }
    .rR3 {
      .rR3-block {
        padding: 20px;
      }
      .rR3-data,
      .rR3-info {
        flex-wrap: wrap;
        p {
          margin-right: 20px;
        }
        .add-a-review {
          margin: 0;
        }
        > * {
          margin-bottom: 10px;
        }
      }
    }
  }
  .j {
    width: 100%;
    height: calc(100% - 170px);
    padding-top: 20px;
    .j-container {
      height: calc(100%);
      margin-bottom: 0;
      flex-wrap: wrap;
      overflow: hidden;
      box-shadow: none;
      > div {
        width: 100%!important;
        height: 100%!important;
        padding: 30px 20px!important;
        border-radius: 0!important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: all 0.3s ease;;
      }
      .login-signin {
        .signin-email {
          margin-bottom: 0;
        }
        .signup-mobile {
          display: block;
        }
        .modal a {
          right: 50px;
        }
      }

      .login-signup {
        padding-bottom: 20px;
        .signin-mobile {
          display: block;
        }
        .step {
          width: 100%;
          margin: 0;
          padding: 30px;
          overflow: scroll;
          display: flex;
          flex-direction: column;
          justify-content: center;
          input, button, .button-raise {
            width: 100%!important;
            padding: 10px!important;
            font-size: 12pt!important;
          }
        }
      }
    }
  }
  .at_on-boarding {
    .app-max .main-content .content {
      align-items: baseline!important;
    }
    .oB {
      height: calc(100% - 61px);
      width: 100%;
      padding: 20px 0;
      .get-started {
        height: 100%;
        padding: 40px 30px!important;
        overflow: hidden;
        .getstarted-steps {
          overflow: visible;
          height: 300px;
          max-width: 400px;
          .step {
            width: 100%;
            max-width: 400px;
            flex-direction: column;
            .tooltip.fade.showTooltip::before {
              transform: translate3d(0, -60px, 0);
            }
            .step-container {
              margin: 0;
              width: 100%;
              input {
                width: 100%!important;
                border-radius: 5px;
                margin-bottom: 10px;
              }
              .step-units {
                border-radius: 5px;
                margin-right: 10px;
              }
              .step-buttons {
                margin: 0 10px 10px 0!important;
                span {
                  font-size: 13pt;
                }
              }
              .step-textarea {
                font-size: 13pt;
                width: 100%;
              }
            }
            .step-arrow {
              display: none;
            }
            .step-nav {
              display: flex;
              width: 100%;
              margin-top: 40px;
              justify-content: space-between;
              .step-arrow {
                background: white;
                height: 49px;
                padding: 10px 10px;
                display: inline-flex;
                border-radius: 5px;
                font-size: 1.2rem;
                align-items: center;
                justify-content: center;
                text-align: center;
                width: calc(50% - 5px);
                cursor: pointer;
                font-weight: 500;
                &.step-arrow-left {
                  background: black;
                  color: white
                }
              }
            }
            &#step-1 .step-nav .step-arrow {
              width: 100%;
            }
            &.profile-picture {
              .step-container {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .s, .pS {
    padding-left: 0;
    padding-right: 0;
  }
  .modal-inset {
      min-width: none;
      max-width: none;
      width: calc(100% - 60px);
      padding: 50px 30px 30px;
      margin: 0 30px;
  }
  .forgot-password {
    padding-left: 0;
    padding-right: 0;

    h2 {
      font-size: 26pt;
      text-align: center;
      max-width: 300px;
    }

    input, button {
      font-size: 18pt;
    }
  }
}

// For small download
@media (max-width: 680px) {
  .download-app {
    img:first-of-type {
      margin: 0 0 30px 0;
    }
  }
}

// Small Screen
@media (max-width: 640px) {
  .s {
    .s-search,
    .s-list {
      padding-left: 0;
      padding-right: 0;
    }
    .s_container {
      .content {
        .halfs-group {
          flex-wrap: wrap;
          .group {
            width: 100%;
            margin-bottom: 30px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  .player {
    .player-content {
      .right {
        .player-right {
          .routine-view {
            .top {
              flex-direction: column;
              > * {
                width: 100%;
              }
              .content {
                order: 2;
              }
              .image {
                height: 45vh;
                img {
                  object-position: bottom right;
                }
              }
            }
          }
          .content {
            h2 {
              margin-top: 0px;
              font-size: 26pt;
            }
          }
          .player-exercises {
            .middle {
              .countdown-clock {
                height: 150px;
                .time {
                  font-size: 60pt;
                }
              }
            }
          }
        }
        &.open {
          .back-to-list {
            width: 50px;
            height: 50px;
            svg {
              width: 50px;
              height: 50px;
            }
          }
        }
      }
    }
  }
  .pS {
    .progress {
      flex-direction: column;
      padding: 20px 0;
      align-items: baseline;
      .progress-step {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
// XSmall Screen
@media (max-width: 440px) {
  .eList {
    .eE {
      padding-top: 15px;
      padding-bottom: 15px;
      .eE-info {
        align-items: baseline;
        flex-direction: column;
        h3 {
          margin-bottom: 8px;
        }
        p {
          opacity: 0.7;
        }
      }
    }
  }
}
