.rB {
  position: relative;
  z-index: 1;
  color: white;
  width: 200px;
  margin-right: 15px;
  display: inline-block;
  transition: all 0.2s ease-in-out;
  white-space: normal;
  border-radius: 10px;
  flex-direction: column;
  > a {
    display: inline-flex;
    border-radius: 5px;
    justify-content: space-between;
    flex-direction: column;
    color: #111;
    > span {
      width: 100%;
      height: 100%; } }
  > a:first-child {
    width: 200px;
    height: 250px;
    margin-bottom: 20px; }
  h3 {
    font-size: 11pt;
    margin-bottom: 10px;
    font-weight: 700; }
  .rB-cat {
    padding: 5px 10px 4px;
    border-radius: 5px;
    display: inline-block;
    font-size: 10pt;
    margin-right: 5px;
    margin-bottom: 5px;
    color: #4a60ff;
    border: 1px solid #4a60ff; }
  small {
    font-size: 10pt;
    opacity: 0.6;
    margin-top: 30px;
    display: none; }
  .rB-pic {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    z-index: 1;
    border: 2px solid #4a60ff;
    box-shadow: 0 0 10px 0 rgba(68, 68, 181, 0.3); }
  .on-top {
    z-index: 2; } }
.rB:hover {
  transform: scale(1.03); }
