.forgot-password {
  padding-right: 220px;
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  input, button {
    width: 320px;
    max-width: 100%;
    text-align: center;
    font-size: 14pt;
    border-radius: 4px;
    padding: 10px 15px;
    transition: all 0.3s ease;
  }

  input {
    margin: 60px 0 15px;
    border: 1px solid #ececec;

    &:hover, &:focus {
      box-shadow: -3px 3px 10px #e7e7e7;
      border-color: #d8dce0;
    }

    &:nth-of-type(2) {
      margin-top: 0px;
    }
  }

  button {
    background: $blue;
    border: 1px solid $blue;
    color: white;
    cursor: pointer;

    &:hover {
      transform: scale(1.03);
      box-shadow: -3px 3px 10px #e0e2e4;
    }
  }

  span {
    color: rgb(49, 150, 29);
    margin-top: 20px;
    font-weight: 500;
  }
}