.download-app {
  text-align: center;
  h3 {
    opacity: 0.8;
    font-size: 16pt;
    max-width: 400px;
    margin: 0 auto 50px;
    line-height: 1.5;
  }
  h2 {
    font-size: 20pt;
    margin-bottom: 20px;
  }
  img {
    max-width: 300px;
    border-radius: 20px;
    box-shadow: 0 5px 20px #c0c6ce;
    &:first-of-type {
      margin-right: 30px;
    }
  }
}