.review {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  padding: 20px;
  .review-container {
    background-color: #1a2dc2;
    border-radius: 5px;
    width: 600px;
    height: auto;
    margin-bottom: 70px;
    padding: 40px;
    min-height: 420px;
    position: relative;
    .close-review {
      position: absolute;
      right: 30px;
      top: 20px;
      cursor: pointer; }
    h2 {
      font-family: Poppins, sans-serif;
      font-weight: 500;
      margin-top: -20px;
      margin-bottom: 30px;
      span {
        font-weight: 600; } }
    .review-block {
      margin-bottom: 20px;
      .textarea {
        font-family: Poppins, sans-serif;
        font-size: 1rem; }
      .review-block-title {
        font-family: GT-Regular, Poppins, sans-serif;
        font-size: 0.95rem;
        margin-bottom: 3px;
        letter-spacing: 0.05rem;
        span {
          text-transform: lowercase;
          font-size: 0.7rem; } }
      .input {
        width: 100%;
        background-color: rgb(21, 37, 155);
        font-family: Poppins,sans-serif;
        font-weight: 500; }
      .textarea-description {
        height: 100px;
        font-size: 1.2rem;
        font-family: GT-Medium, sans-serif; }
      .input-tags {
        display: none; }
      tags {
        height: 50px;
        border-radius: 5px;
        border: 0;
        background-color: rgba(0, 0, 0, 0.2);
        color: white;
        font-family: GT-Medium, sans-serif;
        font-size: 1.2rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 4px 15px 0;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        width: 100%;
        tag {
          margin-bottom: 7px; } }
      tags > div {
        width: 100%;
        input {
          width: 100%; } }
      input.placeholder {
        height: 50px;
        background-color: transparent; } }
    .review-block-end {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .review-private {
        display: flex;
        align-items: center;
        font-family: Poppins, sans-serif;
        i {
          font-size: 1.4rem;
          margin-right: 10px;
          transition: all 0.3s ease;
          cursor: pointer; }
        i:hover {
          transform: translateY(-3px);
          color: rgb(241, 233, 36); }
        i.yellow-star {
          color: rgb(241, 233, 36); }
        p {
          font-size: 0.75rem;
          font-weight: 500;
          margin-left: 10px; } }
      .review-button {
        background-color: #0a124e;
        font-size: 1rem;
        padding: 6px 15px;
        cursor: pointer;
        border-radius: 5px;
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        font-weight: 500;
        font-family: Poppins; } } } }
.review.hideReview {
  display: none; }
