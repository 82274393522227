.categories {
  .category {
    .category-top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      a {
        display: flex;
        align-items: center; }
      p {
        display: inline-block;
        text-transform: uppercase;
        font-size: 0.8rem;
        margin-right: 10px; }
      .category-title {
        font-weight: 500;
        display: flex;
        align-items: center;
        color: #4258ff;
        span {
          font-size: 0.8rem;
          background: #e0e1e6;
          border-radius: 5px;
          padding: 3px 12px;
          font-style: normal;
          font-weight: 500;
          display: inline-block;
          margin-left: 20px;
          color: #6e6e80;
          border: 1px solid #d8d2d2; } } } } }
.categories > .category {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  background: rgb(247, 249, 250);
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05); }
.categories > .category:first-child {
  margin-top: 0px; }
