.items {
  .contain {
    margin-bottom: 20px; }
  .scroll-for-more {
    display: inline-flex;
    text-transform: uppercase;
    font-size: 0.8rem;
    align-items: center;
    img {
      margin-left: 10px;
      margin-bottom: 1.5px; } }
  .item-section:hover {
    .item-header {
      opacity: 1; } }
  .item-header {
    font-family: Poppins, GT-Regular, sans-serif;
    text-transform: uppercase;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 0.8;
    font-weight: 500;
    transition: all 0.3s ease; }
  .emptyPrograms {
    font-family: Poppins, sans-serif;
    border-radius: 5px;
    padding: 10px;
    background: rgba(0, 0, 0, 0.1);
    font-size: 0.9rem;
    margin-top: 10px; }
  .item-programs {
    .program-block {
      display: inline-flex;
      background: #3149fb;
      border-radius: 5px;
      padding: 12px 15px;
      margin-right: 15px;
      align-items: center;
      justify-content: space-between;
      .img-wrapper {
        background: #1127d3;
        border-radius: 5px;
        padding: 5px;
        height: 46px;
        width: 50px;
        margin-right: 15px;
        display: inline-flex;
        align-items: center;
        justify-content: center; }
      .program-type, .program-length {
        font-family: GT-Bold, sans-serif;
        text-transform: uppercase;
        margin-right: 2px;
        text-shadow: 0px 3px 10px rgba(0, 0, 0, 0.7);
        font-size: 1.2rem; }
      .program-length {
        font-size: 0.9rem;
        font-family: GT-Medium-Italic, sans-serif;
        margin-top: 6px; } } }
  .item-routines {
    .emptyPrograms {
      margin-top: 0; }
    .item-header {
      margin-bottom: 10px; }
    .items-cat {
      margin-bottom: 20px; }
    .cat-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      opacity: 0.8;
      transition: all 0.3s ease;
      &:hover {
        opacity: 1; }
      h3 {
        background: #2f47fb;
        border-radius: 5px;
        font-family: Poppins, sans-serif;
        text-transform: capitalize;
        color: white;
        font-weight: 500;
        font-size: 1rem;
        padding: 10px 20px;
        line-height: 1rem;
        cursor: default;
        span {
          color: #8592fb; } } }
    .rt {
      padding-top: 5px;
      .routine-block-wrapper {
        width: calc(45% - 14px); } } } }
