.c {
  .cS {
    display: flex;
    .cS-continue {
      background: #fff;
      border-radius: 5px;
      margin-bottom: 7px;
      padding: 9px 12px 8px;
      color: #4a60ff;
      border: 1px solid #4a60ff;
      display: block;
      text-align: center;
      transition: all 0.2s ease-in-out;
      font-size: 11pt;
      &:hover {
        background: #f5faff; } }
    .cS-checkout {
      background: #4a60ff;
      border-radius: 5px;
      margin-bottom: 7px;
      padding: 9px 0px 8px;
      color: #fff;
      border: 1px solid #4a60ff;
      text-align: center;
      font-size: 11pt;
      width: 100%;
      display: block;
      cursor: pointer; }
    .points-eligible {
      margin-top: 10px;
      font-size: 9pt;
      cursor: pointer;
      color: #406aff;
      border-bottom: 1px solid #406aff; }
    .cS-total {
      margin: 50px 0 15px 0;
      display: flex;
      justify-content: space-between;
      b {
        font-weight: 700;
        font-weight: normal;
        letter-spacing: 0.3pt; } } }
  .cTop {
    h2 {
      margin-bottom: 30px; }
    p {
      margin-bottom: 20px;
      font-size: 11pt;
      line-height: 16pt;
      a {
        color: #5367ff;
        text-transform: capitalize; } }
    p.imp, a {}
    > p:last-child {
      margin-bottom: 0; } }
  .cItem:last-child {
    border-bottom: none; }
  .cItem {
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    border-bottom: 1px solid #ececec;
    flex-direction: column;
    > * {
      width: 100%; }
    .cItem-top {
      display: flex;
      justify-content: space-between;
      align-items: center; }
    h3 {
      font-weight: 700;
      font-size: 14pt;
      text-transform: capitalize; }
    .cItem-cat {
      padding: 4px 10px 4px;
      border-radius: 5px;
      display: inline-block;
      font-size: 10pt;
      margin-right: 5px;
      margin-top: 10px;
      color: #4a60ff;
      border: 1px solid #4a60ff;
      cursor: default; }
    .cItem-right {
      display: inline-flex;
      align-items: center;
      .cItem-price {
        font-size: 14pt;
        margin-right: 30px; }
      .cItem-remove {
        position: relative;
        cursor: pointer; }
      svg {
        height: 16px;
        width: 16px;
        margin-left: 10px;
        cursor: pointer;
        pointer-events: none; } }
    .cItem-info {
      background: #f7f9fa;
      padding: 10px 10px 0;
      border-radius: 5px;
      border: 1px solid #e1e9ec;
      margin-top: 20px;
      .info-container {
        margin-bottom: 10px;
        border-bottom: 1px solid #e2e8ea;
        padding-bottom: 10px;
        span {
          font-size: 9pt;
          text-transform: uppercase;
          margin-bottom: 5px;
          display: block; }
        p {
          font-weight: 700;
          text-transform: capitalize; }
        .cItem-cat {
          margin-bottom: 10px;
          margin-top: 0; } }
      .info-container:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0; } } } }

.cCheckout {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  height: 0;
  overflow: hidden;
  position: fixed;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.2s ease;
  background: rgba(255, 255, 255, 0.95);
  #dropin-container {
    width: 400px;
    .braintree-sheet {
      border: 2px solid #4a60ff;
      border-radius: 5px; } }
  #submit-button {
    background: #4a60ff;
    color: white;
    font-size: 13pt;
    text-transform: capitalize;
    width: 400px;
    border-radius: 5px;
    padding: 14px 15px 12px;
    cursor: pointer;
    border: 0; } }
.cCheckout.show {
  height: auto;
  opacity: 1;
  visibility: visible;
  pointer-events: auto; }

.cTY {
  width: 100%;
  .cTY-top {
    p {
      margin-top: 20px; } }
  .cTY-purchased {
    margin-bottom: 40px;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #4a60ff; }
  .receipt {
    background: #f7f9fa;
    margin-left: 40px;
    margin-right: 40px;
    border-radius: 5px;
    max-width: 500px; }
  .cItem {
    margin: 0 0 30px; }
  .t-container {
    p {
      padding: 10px 0;
      border-bottom: 1px solid #ececec;
      b {
        font-weight: 700;
        margin-left: 10px; } } } }
