.question {
  padding-bottom: 50px;
  .mainQ {
    font-family: Poppins, GT-Bold, sans-serif;
    font-weight: 600;
    margin-bottom: 30px;
    line-height: 2.2rem; }
  .question-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    background: #1728bf;
    padding: 10px 25px;
    > div {
      display: flex; }
    .asked-by {
      display: flex;
      font-family: Poppins, sans-serif;
      align-items: center;
      font-size: 0.9rem;
      margin-right: 40px;
      > div, > a {
        display: flex;
        align-items: center; }
      .profile-img {
        border-radius: 500px;
        width: 25px;
        height: 25px;
        margin: 0 10px; }
      .username {
        font-weight: 700;
        font-size: 0.8rem; } }
    .comments, .views {
      display: flex;
      align-items: center;
      margin-left: 25px;
      p {
        font-family: Poppins, sans-serif;
        font-weight: 700;
        font-size: 0.9rem;
        margin-left: 10px; } }
    .follow {
      display: flex;
      align-items: center;
      cursor: pointer;
      p {
        font-family: Poppins, sans-serif;
        font-weight: 500;
        font-size: 0.9rem;
        margin-left: 10px; } } }
  .question-description {
    margin: 30px 0;
    p {
      font-family: Poppins, sans-serif;
      font-size: 0.9rem;
      margin-bottom: 15px;
      line-height: 1.2rem; } }
  .question-tag-share {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    .routine-tags {
      margin: 0; }
    .share-container {
      display: flex;
      align-items: center;
      p {
        font-family: GT-Bold, sans-serif;
        margin-right: 15px; }
      img {
        margin-left: 5px;
        cursor: pointer; } } }
  .post-comment {
    .textarea {
      height: 70px;
      font-size: 0.9rem;
      line-height: 1rem; }
    .info-button {
      cursor: pointer;
      background-color: #0a124e;
      font-family: GT-Bold, sans-serif;
      padding: 8px 15px;
      margin-top: 6px;
      p {
        font-size: 0.9rem; } } }
  .conversation {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    font-family: Poppins, sans-serif;
    p {
      font-size: 0.9rem;
      line-height: 1.1rem; }
    .post-reply {
      max-height: 0;
      overflow: hidden;
      padding-left: 40px;
      margin-top: 10px;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      textarea {
        height: 70px;
        font-size: 0.9rem; }
      .reply-post {
        margin-top: 0; }
      > .inline-block {
        cursor: pointer; }
      > .inline-block span {
        display: none; }
      > .inline-block * {
        pointer-events: none; } }
    .post-reply.openReplyBox {
      max-height: 2000px; }
    .reply-info {
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 5px;
      background: #223af5;
      padding: 5px 15px;
      .reply-user {
        a {
          display: flex;
          align-items: center; }
        img {
          border-radius: 500px;
          width: 25px;
          height: 25px;
          margin: 0 10px; } }
      .reply-btn, .reply-user {
        display: flex;
        align-items: center; }
      .reply-btn {
        cursor: pointer;
        * {
          pointer-events: none; }
        span {
          display: none; }
        p {
          font-size: 0.8rem;
          margin-left: 5px; } }
      .reply-user {
        p {
          font-size: 0.7rem; }
        .username {
          font-weight: 700; } } }
    .subreplies {
      padding-left: 40px;
      margin-top: 20px;
      .reply {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        > p {
          width: 100%; } }
      p {
        font-size: 0.8rem;
        line-height: 1rem; } } }
  .delete-reply, .delete-comment {
    background: rgba(#a40c0c, 0.54);
    border-radius: 500px;
    display: inline-flex;
    margin-left: 10px;
    align-items: center;
    justify-content: center;
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    width: 20px;
    height: 20px;
    padding-right: 0.5px;
    padding-top: 1px;
    transition: all 0.3s ease;
    cursor: pointer; }
  .delete-reply:after, .delete-comment:after {
    content: "\D7"; }
  .delete-reply:hover, .delete-comment:hover {
    background: #a50c0c;
    -webkit-box-shadow: 0px 0px 10px 0px rgb(228, 6, 6);
    -moz-box-shadow: 0px 0px 10px 0px rgb(228, 6, 6);
    box-shadow: 0px 0px 10px 0px rgb(228, 6, 6); }
  #disqus_thread {
    margin-top: 10px; } }
