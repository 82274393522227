.custom-program {
  .program-build {
    width: 100%;
    .contain {
      margin-bottom: 15px; }
    .expand-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .toggleArrow {
        margin-left: 10px;
        width: 18px;
        cursor: pointer;
        transition: all 0.3s ease; }
      .toggleArrow.flipArrow {
        transform: rotate(180deg); } }
    h1 {
      text-transform: uppercase;
      font-family: GT-Medium-Italic, sans-serif; }
    small {
      margin: 30px 0 40px 0;
      display: block;
      font-size: 0.8rem;
      font-family: Poppins, GT-Regular, sans-serif; }
    h2 {
      font-family: Poppins, sans-serif;
      font-size: 1.1rem;
      font-weight: 500; }
    p {
      font-family: Poppins, sans-serif;
      font-size: 0.9rem;
      display: flex;
      align-items: center;
      img {
        margin-left: 10px;
        width: 18px;
        margin-bottom: 2px;
        cursor: pointer; } }
    .personal-info {
      overflow: hidden;
      max-height: 0;
      transition: all 0.5s ease;
      .basicinfo {
        margin-top: 30px; } }
    .personal-info.opened {
      max-height: 2000px; }
    p.bolder {
      font-weight: 600;
      margin-bottom: 10px; }
    .focus-area, .infobtn {
      cursor: default; }
    .infobtn {
      display: inline-flex;
      border-radius: 5px;
      margin-bottom: 8px;
      margin-right: 8px; }
    .description, .answer {
      line-height: 40px;
      padding: 0 12px;
      margin-bottom: 0; }
    .description {
      background: #172686;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px; }
    .answer {
      font-weight: 500;
      background: #3a4cf9;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      font-size: 1.1rem;
      img {
        width: auto;
        margin: 0;
        cursor: default; } }
    .divider {
      width: 100%;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.08);
      margin: 20px 0; }
    .build-step {
      margin-bottom: 40px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > .inline-block {
        display: block;
        width: 100%; }
      .step-section-half {
        margin-top: 20px;
        width: 48%;
        display: inline-block; }
      .step-section-full {
        margin-top: 20px;
        width: 100%;
        display: inline-block; }
      .step-section-title {
        text-transform: uppercase;
        letter-spacing: 0.06rem;
        font-size: 0.95rem;
        margin-bottom: 3px; }
      .option, .input {
        margin-right: 10px; }
      .option {
        border: 1px solid rgba(0, 0, 0, 0.0); }
      .option.active-option {
        border: 1px solid rgba(0, 0, 0, 0.3);
        background-color: rgba(0, 0, 0, 0.4); }
      .purchase-button {
        margin-top: 30px; }
      .purchase-program {
        display: inline-block; } } }
  .program-cost {
    position: fixed;
    right: calc(50% - 56px - 370px);
    bottom: 40px;
    .program-cost-title {
      text-transform: uppercase;
      font-family: GT-Medium, sans-serif;
      margin-bottom: 20px; }
    .cost-block {
      font-family: GT-Medium, sans-serif;
      margin-bottom: 10px;
      small {
        font-family: GT-Regular, sans-serif;
        font-size: 0.7rem;
        margin: 10px 0px 10px 5px; } }
    .cost-total {
      font-family: GT-Medium, sans-serif;
      margin-top: 30px; } } }


// custom form styling
.input-name {
  width: 100%; }
.input-height, .input-weight {
  width: 40%;
  min-width: 110px; }
