.alert {
  position: fixed;
  left: -600px;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 3px;
  width: auto;
  bottom: 20px;
  max-width: 600px;
  -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 999;
  p {
    font-family: Poppins, GT-Regular, sans-serif;
    font-weight: 500;
    color: rgb(9, 19, 60); } }
.alert.alert-show {
  left: 15px; }
