.program {
  margin-top: -35px;

  .weeks {
    white-space: nowrap;
    overflow-x: scroll;

    button {
      margin-right: 15px;
      margin-bottom: 15px;
      border-radius: 4px;
      font-size: 11pt;
      padding: 7px 15px;
      font-weight: 500;
      background: #3b4063;
      color: #6b6f90;
      border: 0;
      box-shadow: 0 3px 5px #272b46;
      transition: all 0.3s ease;
      cursor: pointer;

      &:hover {
        background: #43486d;
      }

      &.active {
        background: #fff;
        color: #292e4f;
        box-shadow: 0 5px 20px #1e2138;
      }
    }
  }

  .week-data {
    display: flex;
    margin-bottom: 30px;
    white-space: nowrap;
    overflow-x: scroll;

    .week-day {
      background: #3b4063;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 30px;
      transition: all 0.3s ease;
      cursor: pointer;
      text-transform: capitalize;

      h3 {
        margin-bottom: 10px;
        font-size: 14pt;
      }

      p {
        font-size: 10pt;
        opacity: 0.5;
      }

      &:hover {
        background: #43486d;
      }

      &.active {
        background: #fff;
        color: #292e4f;
        box-shadow: 0 5px 20px #1e2138;

        h3 {
          font-weight: 500;
        }

        p {
          opacity: 1;
        }
      }

      &:first-of-type {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
  }

  .rest {
    font-size: 16pt;
    background: white;
    color: #292e4f;
    display: inline-flex;
    padding: 10px 20px;
    border-radius: 5px;
    align-items: center;

    span {
      font-size: 30pt;
      margin-left: 8px;
      line-height: 1;
    }
  }

  .week-exercises {
    display: flex;
    flex-wrap: wrap;

    .week-exercise {
      min-width: 200px;
      margin-right: 20px;
      margin-bottom: 20px;

      .img-block {
        width: 100%;
        height: 250px;
        margin-bottom: 15px;
        position: relative;

        .lazy-load-image-background {
          border-radius: 5px;
          width: 100%;
          height: 250px;
        }

        img {
          display: block;
          min-width: 200px;
          height: 250px;
          object-fit: cover;
          object-position: center;
          border-radius: 5px;
          border: 2px solid white;
          box-shadow: 0 3px 5px #272b46;
        }

        p {
          position: absolute;
          bottom: 10px;
          left: 10px;
          background: #406aff;
          color: white;
          border-radius: 5px;
          padding: 4px 10px;
          font-weight: 600;
          font-size: 10pt;
        }
      }

      h3 {
        font-size: 11pt;
        margin-bottom: 10px;
        font-weight: 700;
        width: 200px;
      }

      .tags {
        display: flex;
        flex-wrap: wrap;
        width: 200px;

        .tag {
          padding: 4px 10px;
          border-radius: 5px;
          display: inline-block;
          font-size: 10pt;
          margin-right: 5px;
          margin-bottom: 5px;
          color: white;
          border: 1px solid white;
        }
      }
    }
  }
}
