.ReactTags__tags {
  margin-left: 50px;
  overflow: hidden; }

.ReactTags__selected {
  display: flex;
  align-items: center; }

.ReactTags__tagInput {
  display: inline-block;
  margin-left: 10px; }

input.ReactTags__tagInputField {
  border: 0;
  background: transparent;
  font-size: 12pt;
  font-family: 'SF', sans-serif; }

input.ReactTags__tagInputField::placeholder {
  color: #CBCCCD; }

span.ReactTags__tag {
  font-size: 12pt;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background: white;
  padding: 5px;
  margin-right: 10px;
  font-family: 'SF', sans-serif; }

a.ReactTags__remove {
  margin-left: 5px; }
