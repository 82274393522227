.s {
  width: 100%;
  .s-search {
    input {
      width: 100%;
      font-size: 16pt;
      padding: 20px 20px;
      border-radius: 5px;
      border: 1px solid #4a60ff;
      color: #4a60ff;
      box-shadow: 5px 5px 5px 0px rgba(151, 141, 255, 0.1);
      transition: all 0.3s ease; }
    input:focus {
      box-shadow: 10px 10px 10px 0px rgba(151, 141, 255, 0.1); }
    input::placeholder {
      color: rgba(85, 68, 255, 0.4); } }
  .rB {
    margin-bottom: 40px;
    text-align: left; }
  .s-list {
    .empty-search {
      max-width: 100%;
      overflow: scroll; } } }
