.cart-thankyou {
  font-family: Poppins, sans-serif;
  .top {
    display: flex;
    align-items: center;
    margin-bottom: 60px;
    img {
      margin-right: 40px; }
    h1 {
      font-weight: 500; }
    p {
      font-size: 0.95rem; } }
  .bottom {
    display: flex;
    align-items: center;
    .receipt {
      background: #fff;
      width: 350px;
      height: auto;
      border-radius: 5px;
      margin-right: 50px;
      min-width: 350px;
      padding: 30px;
      h2 {
        color: #2037ef;
        font-weight: 600;
        font-size: 1.4rem;
        margin-bottom: 30px;
        line-height: 1.4rem; }
      .receipt-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: black;
        font-size: 0.8rem;
        margin: 5px 0; }
      p.receipt-items {
        color: black;
        font-weight: 600;
        font-size: 0.9rem;
        margin: 20px 0 10px; }
      hr {
        margin: 20px 0; }
      .receipt-block {
        text-transform: capitalize;
        h3 {
          font-weight: 600;
          font-size: 1rem; } } }
    .ready {
      margin-bottom: 50px;
      h1 {
        font-weight: 500;
        line-height: 2.6rem;
        font-size: 1.6rem; }
      .routines-button {
        font-family: Poppins, sans-serif;
        font-size: 1.2rem;
        background-color: #0b1456;
        padding: 12px 40px;
        height: auto;
        margin: 10px 0 40px;
        cursor: pointer;
        img {
          height: auto;
          margin-right: 10px; } }
      > p {
        max-width: 400px;
        i {
          font-family: Poppins, sans-serif!important; }
        u {
          border-bottom: 1px solid white;
          line-height: 0.8rem;
          text-decoration: none; } } } } }
