.r {
  width: 100%;
  .r-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .r-back svg {
      height: 30px;
      width: 30px;
      cursor: pointer; }
    .r-tabs {
      border-radius: 5px;
      background: #F7F9FA;
      position: relative;
      padding: 5px;
      .r-tab {
        font-size: 11pt;
        width: 100px;
        display: inline-block;
        text-align: center;
        padding: 12px 0;
        cursor: pointer;
        position: relative;
        color: #555; }
      .r-tab.active {
        color: #406aff; }
      #r-tab-indicator {
        position: absolute;
        border-radius: 5px;
        top: 5px;
        bottom: 5px;
        width: 100px;
        left: 5px;
        transition: all 0.3s ease;
        border: 1px solid #406aff; } } } }
.rR1 {
  .rR1-seperator {
    display: flex;
    .rR1-price {
      position: relative;
      margin-top: 30px;
      .price {
        position: absolute;
        top: -40px;
        right: -25px;
        background: white;
        border-radius: 500px;
        border: 1px solid #ececec;
        padding: 5px;
        z-index: 1;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14pt; } }
    .rB {
      transform: scale(1.15);
      margin-left: 15px; }
    .rR1-info {
      width: calc(100% - 300px);
      max-width: 700px;
      margin-left: 40px;
      margin-bottom: 30px;
      .on-top {
        margin-bottom: 20px;
        h3 {
          font-size: 16pt;
          margin-bottom: 10px;
          font-weight: 700; }
        .rB-cat {
          padding: 5px 10px 4px;
          border-radius: 5px;
          display: inline-block;
          font-size: 10pt;
          margin-right: 5px;
          margin-bottom: 5px;
          color: #4a60ff;
          border: 1px solid #4a60ff; } }
      .routine-stats {
        p {
          display: inline-flex;
          align-items: center;
          margin-right: 30px;
          svg {
            margin-right: 10px; } } }
      .routine-info {
        margin: 20px 0;
        p {
          margin-bottom: 20px;
          line-height: 16pt; } }
      .rR1-btns {
        display: flex;
        align-items: center;
        padding: 0;
        background: transparent;
        border: 0;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
          transform: translateY(-5px); }
        p {
          display: inline-flex;
          align-items: center;
          margin-right: 15px;
          border-radius: 5px;
          padding: 10px 15px;
          cursor: pointer;
          transition: all 0.3s ease;
          svg {
            margin-right: 10px; } }
        .routine-price {
          padding: 0;
          margin: 0;
          font-size: 14pt;
          color: #406aff;
          cursor: default; }
        .add-to-wishlist {
          color: #f19db4;
          border: 1px solid #f4e2e7;
          box-shadow: -2px 2px 5px 0px rgba(244, 226, 231, 0.50);
          background: #fbf4f6;
          &:hover {
            color: #f18aa6;
            border: 1px solid #f1d6dd;
            box-shadow: -2px 2px 5px 0px rgba(243, 212, 221, 0.5);
            background: #f9edf0;
            svg {
              fill: #f18aa6; } }
          svg {
            fill: #f19db4; } }
        .add-to-cart {
          color: #111;
          border: 1px solid #ececec;
          box-shadow: -2px 2px 5px 0px rgba(236, 236, 236, 0.50);
          background: #fff;
          padding: 15px 20px;
          &:hover {
            border: 1px solid #e4e4e4;
            box-shadow: -2px 2px 5px 0px rgba(226, 226, 226, 0.5);
            background: #f4f8f9; }
          svg {
            fill: #111;
            height: 16px;
            width: 16px; } }
        .add-to-cart.added {
          color: #fff;
          border: 1px solid #406aff;
          box-shadow: -2px 2px 5px 0px rgba(236, 236, 236, 0.5);
          background: #406aff;
          svg {
            fill: #fff; } }
        .play-routine {
          background: #406aff;
          color: white;
          border: 1px solid #ececec;
          box-shadow: -2px 2px 5px 0px rgba(236, 236, 236, 0.50);
          padding: 15px 20px 13px;
          svg {
            fill: #fff;
            height: 16px;
            width: 16px; } } } } }
  hr {
    margin: 40px 0 20px 0; } }
.eList {
  position: relative;
  margin-bottom: 40px;
  .eList-hr {
    background: #f7f9fa;
    margin-top: 20px; }
  .locked {
    background: linear-gradient(to bottom, rgba(125, 185, 232, 0) 0%, white 90%);
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #406aff;
      border-radius: 5px;
      padding: 20px;
      font-weight: 600;
      font-size: 12pt;
      text-transform: capitalize;
      line-height: 15pt;
      text-align: center;
      svg {
        margin-bottom: 5px;
        height: 24px;
        width: 24px;
        fill: #406aff !important; } } }
  .eE:last-child {
    border-bottom: 0; }
  .eE {
    padding-top: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ececec;
    padding-bottom: 20px;
    cursor: default;
    padding-left: 20px;
    padding-right: 20px;
    transition: all 0.2s ease;
    &.blur {
      filter: blur(5px); }
    &:hover {
      background: #eceef1; }
    .eE-image {
      position: relative;
      z-index: 1;
      width: 113px;
      height: 200px;
      border-radius: 10px;
      border: 2px solid #f8fb2b;
      box-shadow: 0 0 10px 0 rgba(118, 128, 30, 0.3);
      margin-left: 5px;
      display: none;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 10px; } }
    .eE-info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      h3 {
        font-weight: 600;
        font-size: 14pt; } } } }

.rR2, .rR3 {
  h2 {
    font-size: 22pt;
    span {
      font-weight: 800;
      font-style: italic; } } }

.rR2 {
  .rB {
    margin-bottom: 40px; } }

.rR3 {
  .rR3-block {
    background: #f6f9fa;
    padding: 40px;
    border-bottom: 1px solid #ececec;
    transition: all 0.3s ease;
    &:hover {
      background: #eceef1; } }
  .rR3-block:last-child {
    border-bottom: 0; }
  .rR3-title {
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 5px; }
  .rR3-review {
    font-size: 12pt;
    line-height: 20pt;
    margin: 5px 0 15px; }
  .rR3-info {
    display: flex;
    align-items: flex-end;
    > * {
      display: inline-block;
      margin-right: 10px;
      font-size: 11pt; }
    .rR3-user {
      color: #384ef3; }
    svg {
      height: 22px;
      width: 22px;
      margin-right: 2px; } }
  .rR3-data {
    display: flex;
    align-items: center;
    .add-a-review {
      display: inline-flex;
      align-items: center;
      border-radius: 5px;
      padding: 5px 10px;
      cursor: pointer;
      transition: all 0.3s ease;
      color: #111;
      border: 1px solid #ececec;
      box-shadow: -2px 2px 5px 0px rgba(236, 236, 236, 0.50);
      background: #fff;
      font-size: 10pt;
      margin-left: 20px;
      &:hover {
        border: 1px solid #e4e4e4;
        box-shadow: -2px 2px 5px 0px rgba(226, 226, 226, 0.5);
        background: #f4f8f9; }
      svg {
        fill: #111;
        margin-right: 10px;
        width: 20px;
        height: 20px; } } }
  .overall-rating {
    position: relative;
    height: 30px;
    width: 160px;
    margin-right: 20px;
    .empty-stars, .full-stars {
      position: absolute;
      display: flex;
      svg {
        min-height: 30px;
        min-width: 30px;
        height: 30px;
        width: 30px;
        margin-right: 2px; } }
    .full-stars {
      width: 50%;
      overflow: hidden; } } }
.review-add {
  opacity: 0;
  visibility: hidden;
  height: 0;
  overflow: hidden;
  position: fixed;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.2s ease;
  background: rgba(255, 255, 255, 0.95);
  .review-container {
    max-width: 400px;
    width: 90%;
    border: 2px solid #406aff;
    border-radius: 5px;
    transition: all 0.3s ease;
    background: #fff;
    margin-top: 200px;
    h3 {
      padding: 20px;
      font-size: 26pt;
      font-weight: 700; }
    .review-group {
      margin: 0 20px 10px;
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      input, textarea {
        border: 0;
        resize: none;
        padding: 10px;
        font-size: 12pt;
        border-bottom: 1px solid #406aff; }
      textarea {
        height: 120px; } }
    .stars {
      flex-direction: row;
      align-items: center;
      span {
        margin-left: 20px;
        font-size: 10pt;
        opacity: 0.6; }
      svg {
        width: 36px;
        height: 36px;
        fill: rgb(253, 248, 135);
        margin-right: 0;
        transition: all 0.3s ease;
        stroke: none;
        cursor: pointer;
        * {
          pointer-events: none; } }
      svg:hover, svg.yellow-star {
        fill: rgb(241, 233, 36);
        transform: translateY(-3px); } }
    .submit-review {
      border: 2px solid #111;
      background: white;
      color: #111;
      padding: 12px 10px 10px;
      border-radius: 5px;
      text-align: center;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.2s ease;
      &:hover {
        background: #111;
        color: white; } } } }
.review-add.show {
  height: auto;
  opacity: 1;
  visibility: visible;
  .review-container {
    margin-top: 0; } }
