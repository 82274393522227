.oB {
  width: 750px;
  .get-started {
    padding: 40px 40px 10px!important;
    min-height: 0!important;
    height: 400px;
    background: #4a60ff;
    border-radius: 10px;
    -webkit-box-shadow: -3px 7px 20px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: -3px 7px 20px 2px rgba(0, 0, 0, 0.05);
    box-shadow: -3px 7px 20px 2px rgba(0, 0, 0, 0.05);
    position: relative;
    text-align: center;
    width: 100%!important;
    .getstarted-inner {
      > h2 {
        color: white;
        font-size: 16pt;
        margin: 0 auto 10px;
        max-width: 400px; }
      > p {
        color: #80b0ff;
        font-size: 11pt; }
      .getstarted-steps {
        margin: 40px auto 50px;
        height: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;
        #step-2 {
          left: 670px; }
        #step-3 {
          left: 1340px; }
        #step-4 {
          left: 2010px;
          .step-container {
            justify-content: center;
            .step-buttons {
              margin: 7px;
              span {
                text-align: center;
                justify-content: center; } } } }
        #step-5 {
          left: 2680px; }
        #step-6 {
          left: 3350px;
          input.step-inputs {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px; }
          span::before {
            transform: translate3d(0, -56px, 0); } }
        .profile-picture {
          .step-container {
            flex-direction: column;
            width: 60%;
            .filepond--wrapper {
              width: 100%; } }
          .filepond {
            width: 170px;
            height: 170px;
            padding: 0;
            overflow: hidden; } }

        .step {
          position: absolute;
          width: 670px;
          top: 0;
          left: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s ease;
          .tooltip.fade:after,
          .tooltip.fade:before {
            transform: translate3d(0,-30px,0);
            transition: all .15s ease-in-out; }
          .tooltip.fade.showTooltip:after,
          .tooltip.fade.showTooltip:before {
            opacity: 1;
            transform: translate3d(0,-50px,0); }
          .step-nav {
            display: none; }
          .step-arrow {
            cursor: pointer;
            transition: all 0.3s ease; }
          .step-arrow-right:hover {
            transform: translateX(4px); }
          .step-arrow-left:hover {
            transform: translateX(-4px); }
          .step-container {
            margin: 0 20px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .step-inputs {
              background: #d1d7ff;
              border: 0;
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
              font-size: 1.2rem;
              padding: 9px 20px;
              color: black;
              height: 49px;
              -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.10);
              -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.10);
              box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.10); }
            .step-inputs::placeholder {
              color: #9da1ff; }
            input[name="height"], input[name="weight"] {
              width: 160px; }
            .step-units {
              height: 49px;
              border: 0;
              padding: 10px 10px;
              font-size: 1.2rem;
              background: #1c286b;
              color: rgba(255, 255, 255, 0.3);
              width: 60px;
              transition: all 0.3s ease;
              cursor: pointer;
              -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.10);
              -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.10);
              box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.10); }
            .step-units.selected, .step-units:hover {
              background: #162158;
              color: white; }
            .step-units:last-of-type {
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px; }
            .step-buttons {
              border: 0;
              display: flex;
              align-items: center;
              background: transparent;
              opacity: 0.5;
              color: white;
              font-size: 11pt;
              cursor: pointer;
              padding: 0;
              span {
                padding: 6px 12px 5px;
                display: flex;
                height: 36px;
                align-items: center;
                border-radius: 5px;
                color: #fff;
                border: 1px solid white; } }
            .step-buttons.selected, .step-buttons:hover {
              opacity: 1; }
            .step-buttons.step-button-middle {
              margin: 6px 12px; }
            .step-textarea {
              background: #fff;
              border: 0;
              resize: none;
              color: #4a60ff;
              border-radius: 5px;
              padding: 15px;
              font-size: 11pt;
              width: 300px;
              height: 150px;
              line-height: 1.3rem;
              -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.10);
              -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.10);
              box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.10); }
            .step-textarea::placeholder {
              color: rgba(85, 68, 255, 0.6); } } } } } } }
