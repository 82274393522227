.premium {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .title {
    display: flex;
    flex-direction: column;
    font-weight: normal;
    text-align: center;
    margin-bottom: 40px;
    text-transform: uppercase;
    line-height: 1.5;
    span {
      // font-weight: bold;
      color: #ffcd22;
    }
  }
  .premium-container {
    max-width: 400px;
    margin: 0 auto;
    width: 90%;
    box-shadow: 0 3px 10px #e6ecf1;
    border-radius: 10px;
    padding: 30px;
    border: 1px solid #e6ecf1;
    h3 {
      font-size: 20pt;
      margin-bottom: 10px;
    }
    .price {
      display: flex;
      align-items: flex-end;
      h2 {
        line-height: 1;
        font-size: 30pt;
      }
      p {
        line-height: 1.9;
        margin-left: 10px;
      }
    }
    span {
      display: block;
      font-size: 10pt;
      margin-top: 5px;
    }
    ul {
      padding: 30px 0;
      margin: 30px 0 40px;
      list-style: none;
      border-top: 1px solid #ececec;
      border-bottom: 1px solid #ececec;
      li {
        display: flex;
        align-items: center;
        padding: 7px 0;
        svg {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          path {
            fill: rgb(38, 189, 38);
          }
        }
      }
    }
    button {
      background: #406aff;
      color: white;
      border-radius: 20px;
      width: 100%;
      font-size: 12pt;
      border: 0;
      padding: 15px;
      font-weight: 500;
      letter-spacing: 0.8pt;
      text-transform: uppercase;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        transform: translateY(-5px);
      }
    }
  }
  .premium-pay {
    opacity: 0;
    visibility: hidden;
    height: 0;
    overflow: hidden;
    position: fixed;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.2s ease;
    background: rgba(255, 255, 255, 0.95);
    .inner {
      max-width: 400px;
      width: 90%;
      button {
        background: #4a60ff;
        color: white;
        font-size: 13pt;
        text-transform: capitalize;
        max-width: 400px;
        width: 100%;
        border-radius: 5px;
        padding: 14px 15px 12px;
        cursor: pointer;
        border: 0;
        &.error {
          background: #f13333;
        }
      }
    }
    &.show {
      height: auto;
      opacity: 1;
      visibility: visible;
    }
  }
}
