.p, .pG {
  text-align: center;
  h3, h4 {
    opacity: 0.8; }
  h3 {
    font-size: 16pt; }
  h4 {
    font-size: 13pt;
    margin-top: 5px; }
  h2 {
    font-size: 20pt;
    margin: 50px 0 30px; }
  .lPrograms-options {
    max-width: 600px;
    margin: 0 auto;
    p {
      display: inline-block;
      border: 1px solid #4a60ff;
      border-radius: 5px;
      padding: 12px 16px 10px;
      color: #4a60ff;
      margin: 10px;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover, &.active {
        background: #4a60ff;
        color: white; } } }
  .goal-list {
    list-style: none;
    margin: 40px auto 0;
    padding: 0;
    max-width: 600px;
    width: 100%;
    li {
      margin-bottom: 20px;
      text-align: left;
      box-shadow: 0 3px 10px 0 rgba(68, 68, 181, 0.2);
      background: white;
      padding: 30px 20px;
      border-radius: 5px;
      transition: all 0.3s ease;
      &:hover {
        transform: translateY(-10px); }
      a {
        display: block; }
      h3 {
        color: #4a60ff;
        margin-bottom: 15px;
        font-weight: 700; }
      p {
        line-height: 1.5; } } }

  .user-programs {
    margin-top: 60px;
    background: #F7F9FA;
    padding: 30px;
    h2 {
      font-size: 20pt;
      margin: 0;
      text-transform: capitalize; }
    .legend {
      padding-left: 15px;
      margin: 15px 0 30px;
      p {
        display: inline-block;
        position: relative;
        margin: 10px 30px;
        color: #6d828c;
        &:after {
          content: '';
          position: absolute;
          height: 6px;
          width: 6px;
          border-radius: 500px;
          background: #4a60ff;
          left: -15px;
          top: 4px; }
        &.progress:after {
          background: #dbc422; } } } } }
.pS {
  h2 {
    margin-bottom: 40px; }
  .pS-step {
    .optionsMsg {
      visibility: hidden;
      opacity: 0;
      margin-left: -20px;
      transition: all 0.3s ease;
      padding: 8px 10px;
      border-radius: 3px;
      font-size: 10pt; }
    .optionsMsg.show {
      visibility: visible;
      opacity: 1;
      margin-left: 0;
      background: #ff4a48;
      color: white; }
    .createAccountBtn {
      display: inline-block;
      margin-top: 1em;
      background: #4a60ff;
      color: white;
      border-radius: 5px;
      padding: 10px 16px;
      font-size: 11pt;
      font-weight: 700;
      box-shadow: 0 3px 5px 0 #bac0ec; }
    .nextBtn {
      color: #4a60ff;
      display: flex;
      align-items: center;
      margin-right: 15px;
      margin-bottom: 7px;
      margin-top: 20px;
      padding: 10px 0;
      cursor: pointer;
      transition: all 0.3s ease;
      width: 80%;
      border-left: 0;
      border-right: 0;
      font-weight: 700;
      font-size: 14pt;
      &:hover {
        img {
          transform: translateX(5px); } }
      img {
        margin-left: 7px;
        padding-top: 3px;
        opacity: 0.6;
        transition: all 0.3s ease; } }
    .addToCart {
      color: white;
      background: #4a60ff;
      width: auto;
      display: inline-flex;
      padding: 10px 20px;
      border-radius: 3px;
      font-size: 14pt;
      font-weight: 700; } }
  .progress {
    background: white;
    color: #111;
    border: 1px solid #ececec;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 7px;
    padding: 10px 15px;
    transition: all 0.3s ease;
    width: 100%;
    border-left: 0;
    border-right: 0;
    margin-top: 60px;
    .progress-step {
      font-size: 10pt;
      opacity: 0.6;
      transition: all 0.3s ease;
      cursor: pointer;
      img {
        height: 20px;
        margin-left: 7px;
        padding-top: 3px;
        opacity: 0.8;
        display: none; } }
    .progress-step.active {
      font-weight: 700;
      opacity: 1; } }
  .pI {
    position: relative;
    p {
      line-height: 22pt;
      width: 80%;
      margin-bottom: 20px;
      font-size: 14pt; }
    div > p:last-of-type {
      margin-bottom: 0; }
    img {
      position: absolute;
      width: 50px;
      left: calc(30%);
      top: -90px; } }
  .pC {
    .pC-inputContainer {
      display: inline-flex;
      width: 50%;
      padding-right: 40px;
      margin-bottom: 40px;
      flex-direction: column;
      span {
        font-size: 8pt;
        font-weight: 700;
        text-transform: uppercase;
        opacity: 0.6; }
      input, textarea {
        border: 0;
        font-size: 12pt;
        margin-top: 10px;
        padding-bottom: 6px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        transition: all 0.3s ease;
        max-width: 80%;
        line-height: 16pt;
        &:focus {
          border-bottom: 1px solid rgba(0, 0, 0, 1); }
        &:disabled {
          border-bottom: 0;
          color: #111;
          resize: none; } } } } }

#program-confirm {
  h2 {
    display: flex;
    align-items: center; }
  .btn {
    display: inline-flex;
    align-items: center;
    border: 1px solid;
    border-radius: 7px;
    padding: 4px 10px 2.5px 9pt;
    margin-left: 40px;
    color: rgba(255, 255, 255, 0.80);
    cursor: pointer;
    transition: all 0.5s ease;
    font-size: 10pt;
    font-family: 700;
    text-transform: uppercase;
    letter-spacing: 0.8pt;
    position: relative;
    &:hover {
      color: rgba(255, 255, 255, 1);
      transform: translateX(2px); } }
  .btn.edit {
    border-color: #54f;
    box-shadow: 0 0 15px 0 rgba(84, 69, 255, 0.25);
    background: #54f; }
  .btn.save {
    border-color: #ff4a48;
    box-shadow: 0 0 15px 0 rgba(255, 74, 72, 0.25);
    background: #ff4a48; }
  .btn::after {
    content: 'Information saved.';
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;
    position: absolute;
    left: 80px;
    width: 200px;
    text-transform: capitalize;
    letter-spacing: normal; }
  .btn.saved::after {
    opacity: 1;
    visibility: visible;
    color: #0ee05d;
    font-size: 10pt;
    margin-left: 15px; } }

.pO {
  .o-container {
    margin-bottom: 40px;
    .o-option {
      font-family: 700;
      font-size: 12pt;
      margin-bottom: 15px; }
    .o-btn {
      display: inline-flex;
      margin-right: 10px;
      padding: 7px 10px 5px;
      border: 1px solid #4a60ff;
      border-radius: 5px;
      align-items: center;
      font-size: 10pt;
      color: #4a60ff;
      cursor: pointer;
      transition: all 0.2s ease; }
    .o-btn.active,
    .o-btn:hover {
      background: #4a60ff;
      color: white; }
    textarea {
      border: 0;
      border-bottom: 1px solid #4a60ff;
      font-size: 10pt;
      resize: vertical;
      width: 80%; }
    .breakdown-container {
      .breakdown.total {
        display: flex;
        margin-top: 20px;
        border-top: 1px solid #ececec;
        padding-top: 20px;
        width: 80%; }
      .breakdown {
        display: inline-flex;
        margin-right: 50px;
        p {
          font-size: 10pt;
          color: #555; }
        b {
          margin-left: 5px;
          color: #111; } } } } }
.pR {
  .r-row {
    flex-wrap: wrap;
    display: flex;
    .r-container.w-100 {
      width: calc(100% - 20px);
      display: flex;
      margin-right: 0; }
    .r-container {
      display: inline-flex;
      margin-right: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #ececec;
      padding-bottom: 20px;
      width: calc(50% - 20px);
      padding-right: 20px;
      p {
        color: #555;
        line-height: 16pt;
        b {
          color: #111;
          display: flex;
          padding-top: 10px; }
        b.cap {
          text-transform: capitalize; } } } } }
