.login {
  max-width: 800px;
  min-width: 320px;
  padding: 80px 15px 40px;
  margin: 0px auto 0;
  .login-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      max-width: 70px; }
    ul {
      display: flex;
      align-items: center;
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        margin-left: 40px;
        font-size: 0.85rem; } } }
  .login-container {
    border-radius: 10px;
    background: #1d33cd;
    width: 100%;
    height: 380px;
    margin-top: 40px;
    -webkit-box-shadow: 0px 10px 5px 0px rgba(0,51,153,0.35);
    -moz-box-shadow: 0px 10px 5px 0px rgba(0,51,153,0.35);
    box-shadow: 0px 10px 20px 0px rgba(0, 51, 153, 0.26);
    display: flex;
    .login-signin {
      height: 100%;
      width: 63%;
      padding: 80px 120px;
      text-align: center;
      h2 {
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 2.2rem;
        margin-bottom: 20px;
        span {
          font-weight: 600;
          color: #ffcd22; } }
      .signin-email {
        border-radius: 500px;
        background: #e5eaff;
        padding: 7px;
        cursor: pointer;
        margin-bottom: 10px;
        p {
          color: #1d33cd;
          font-size: 0.95rem;
          font-weight: 500; } }
      .facebook-login {
        border-radius: 500px;
        background: #3a4cf9;
        padding: 7px;
        cursor: pointer;
        margin-bottom: 8px;
        font-size: 0.95rem;
        width: 100%;
        border: 0;
        color: #dbe1fd;
        font-weight: 500; }
      .fb-disclaimer {
        font-size: 0.8rem;
        color: #ccd1ff; } }
    .login-signup {
      background: #3a4cf9;
      height: 100%;
      width: 37%;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      overflow: hidden;
      position: relative;
      padding: 15px;
      .step {
        position: absolute;
        margin: 35px;
        width: 215px;
        top: 0;
        left: 0;
        bottom: 0;
        transition: all 0.3s ease; }
      #step-1 {
        text-align: center;
        p {
          font-weight: 700;
          line-height: 1.2rem; }
        h2 {
          margin: 20px 0;
          font-weight: 500; }
        div.button-raise {
          display: block;
          width: 80%;
          margin: 10px auto;
          background: transparent;
          color: white;
          border-radius: 500px;
          padding: 7px 5px 5px;
          font-size: 0.9rem;
          font-weight: 500;
          border: 1px solid;
          border-color: rgba(255, 255, 255, 0.30); }
        div.button-raise.active, div.button-raise:hover {
          border-color: white; }
        div.button-raise.chosen {
          border-color: rgb(33, 45, 171);
          background: rgba(0, 0, 0, 0.25); } }
      #step-2 {
        left: 285px;
        text-align: center;
        h2 {
          display: flex;
          align-items: center;
          line-height: 0;
          font-size: 1.4rem;
          font-weight: 500;
          justify-content: space-evenly;
          img {
            margin-right: 10px;
            cursor: pointer; } }
        p {
          margin: 15px 0 30px;
          font-size: 0.8rem; }
        input {
          display: block;
          width: 80%;
          margin: 10px auto;
          background: transparent;
          color: white;
          border-radius: 500px;
          padding: 7px 5px 5px;
          font-size: 1rem;
          font-weight: 500;
          border: 1px solid rgba(255, 255, 255, 0.3);
          text-align: center;
          transition: all 0.3s ease;
          -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1); }
        input::placeholder {
          color: rgba(255, 255, 255, 0.6); }
        input.active, input:hover, input:focus {
          border-color: white; }
        button {
          height: auto;
          font-size: 1rem;
          width: 80%;
          border-radius: 500px;
          padding: 7px 5px 7px;
          font-weight: 500;
          margin-top: 6px; }
        button.disabled {
          color: rgba(255, 255, 255, 0.38);
          background-color: rgba(56, 69, 197, 0.67);
          cursor: default;
          -ms-transform: none!important;
          -webkit-transform: none!important;
          transform: none!important;
          -webkit-box-shadow: none!important;
          box-shadow: none!important; } }
      #step-3 {
        left: 570px;
        h2 {
          display: flex;
          align-items: center;
          line-height: 0;
          font-size: 1.4rem;
          font-weight: 500;
          margin-bottom: 20px;
          img {
            margin-right: 10px;
            cursor: pointer; } }
        p {
          margin: 3px 0 10px;
          font-size: 0.65rem;
          text-align: center;
          opacity: 0.7; }
        input[name='name'] {
          text-transform: capitalize; }
        input {
          display: block;
          width: 100%;
          margin: 7px auto;
          background: transparent;
          color: white;
          border-radius: 5px;
          padding: 6px 10px 4px;
          font-size: 0.9rem;
          border: 1px solid rgba(255, 255, 255, 0.3);
          transition: all 0.3s ease;
          -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1); }
        input::placeholder {
          color: rgba(255, 255, 255, 0.6); }
        input.active, input:hover, input:focus {
          border-color: white; }
        input.error {
          border-color: #f13333; }
        button {
          font-size: 1rem;
          width: 100%;
          font-weight: 500;
          height: auto;
          padding: 10px 10px; }
        .tooltip.fade:after,
        .tooltip.fade:before {
          transform: translate3d(0,-10px,0);
          transition: all .15s ease-in-out; }
        .tooltip.fade.showTooltip:after,
        .tooltip.fade.showTooltip:before {
          opacity: 1;
          transform: translate3d(0,-35px,0); } }

      .step-indicators {
        position: absolute;
        bottom: 10px;
        text-align: center;
        display: block;
        width: calc(100% - 30px);
        .indicator {
          height: 8px;
          width: 8px;
          background: white;
          border-radius: 500px;
          display: inline-block;
          margin: 0 3px;
          opacity: 0.2; }
        .indicator.active {
          opacity: 1; } } } }

 }  // MODAL
.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #3535ff;
  opacity: 0;
  visibility: hidden;
  z-index: 40;
  -moz-transition: opacity 0.25s ease 0s, visibility 0.35s linear;
  -o-transition: opacity 0.25s ease 0s, visibility 0.35s linear;
  -webkit-transition: opacity 0.25s ease, visibility 0.35s linear;
  -webkit-transition-delay: 0s, 0s;
  transition: opacity 0.25s ease 0s, visibility 0.35s linear;
  &.state-show {
    opacity: .7;
    visibility: visible;
    -moz-transition-delay: 0s;
    -o-transition-delay: 0s;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    -moz-transition-duration: 0.2s, 0s;
    -o-transition-duration: 0.2s, 0s;
    -webkit-transition-duration: 0.2s, 0s;
    transition-duration: 0.2s, 0s; } }

.modal-frame {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 50;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -moz-box-align: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  width: 100%;
  text-align: center;
  visibility: hidden;
  color: black;
  &.state-appear {
    visibility: visible;
    .modal-inset {
      -moz-animation: modalComeIn 0.25s ease;
      -webkit-animation: modalComeIn 0.25s ease;
      animation: modalComeIn 0.25s ease;
      visibility: visible; }
    .modal-body {
      opacity: 1;
      -moz-transform: translateY(0) scale(1, 1);
      -ms-transform: translateY(0) scale(1, 1);
      -webkit-transform: translateY(0) scale(1, 1);
      transform: translateY(0) scale(1, 1); } }
  &.state-leave {
    visibility: visible;
    .modal-inset {
      -moz-animation: modalHeadOut 0.35s ease 0.1s;
      -webkit-animation: modalHeadOut 0.35s ease 0.1s;
      animation: modalHeadOut 0.35s ease 0.1s;
      visibility: visible; }
    .modal-body {
      opacity: 0;
      -moz-transition-delay: 0s;
      -o-transition-delay: 0s;
      -webkit-transition-delay: 0s;
      transition-delay: 0s;
      -moz-transition-duration: 0.35s;
      -o-transition-duration: 0.35s;
      -webkit-transition-duration: 0.35s;
      transition-duration: 0.35s;
      -moz-transition-timing-function: ease;
      -o-transition-timing-function: ease;
      -webkit-transition-timing-function: ease;
      transition-timing-function: ease;
      -moz-transform: translateY(25px);
      -ms-transform: translateY(25px);
      -webkit-transform: translateY(25px);
      transform: translateY(25px); } } }

@-moz-document url-prefix() {
  .modal-frame {
    height: calc(100% - 55px); } }


.modal {
  display: block;
  vertical-align: middle;
  text-align: center; }

.modal-inset {
  position: relative;
  padding: 60px;
  background-color: #1a2dc2;
  min-width: 320px;
  min-height: 126px;
  max-width: 400px;
  margin: auto;
  border-radius: 5px;
  visibility: hidden;
  -moz-box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.1);
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  .close {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    opacity: .4;
    &:hover {
      opacity: 1; } } }

.modal-body {
  margin: auto;
  opacity: 0;
  -moz-transform: translateY(0) scale(0.8, 0.8);
  -ms-transform: translateY(0) scale(0.8, 0.8);
  -webkit-transform: translateY(0) scale(0.8, 0.8);
  transform: translateY(0) scale(0.8, 0.8);
  -moz-transition-property: opacity, -moz-transform;
  -o-transition-property: opacity, -o-transform;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  -moz-transition-duration: 0.25s;
  -o-transition-duration: 0.25s;
  -webkit-transition-duration: 0.25s;
  transition-duration: 0.25s;
  -moz-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  h3 {
    font-weight: 700;
    padding-bottom: 22px;
    display: block;
    color: #ffcd22;
    font-size: 2rem;
    line-height: 2rem;
    text-align: left; }
  input {
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #606fef;
    background: transparent;
    border-radius: 5px;
    padding: 7px 10px;
    color: white;
    font-size: 1rem;
    transition: all 0.3s ease; }
  input:focus {
    border-color: white; }
  input::placeholder {
    color: #606fef; }
  button {
    border-radius: 5px;
    background: #3d51ec;
    color: white;
    font-size: 1rem;
    font-weight: 500;
    border: 0;
    width: 100%;
    padding: 7px 10px; }
  p.errMsg {
    margin-top: 10px;
    color: #ff7575;
    opacity: 0;
    visibility: hidden; }
  p.errMsg.show {
    opacity: 1;
    visibility: visible; }
  .ps {
    font-size: 12px;
    opacity: .3; } }

@-webkit-keyframes modalComeIn {
  0% {
    visibility: hidden;
    opacity: 0;
    -moz-transform: scale(0.8, 0.8);
    -ms-transform: scale(0.8, 0.8);
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8); }

  65.5% {
    -moz-transform: scale(1.03, 1.03);
    -ms-transform: scale(1.03, 1.03);
    -webkit-transform: scale(1.03, 1.03);
    transform: scale(1.03, 1.03); }

  100% {
    visibility: visible;
    opacity: 1;
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); } }


@-moz-keyframes modalComeIn {
  0% {
    visibility: hidden;
    opacity: 0;
    -moz-transform: scale(0.8, 0.8);
    -ms-transform: scale(0.8, 0.8);
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8); }

  65.5% {
    -moz-transform: scale(1.03, 1.03);
    -ms-transform: scale(1.03, 1.03);
    -webkit-transform: scale(1.03, 1.03);
    transform: scale(1.03, 1.03); }

  100% {
    visibility: visible;
    opacity: 1;
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); } }


@keyframes modalComeIn {
  0% {
    visibility: hidden;
    opacity: 0;
    -moz-transform: scale(0.8, 0.8);
    -ms-transform: scale(0.8, 0.8);
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8); }

  65.5% {
    -moz-transform: scale(1.03, 1.03);
    -ms-transform: scale(1.03, 1.03);
    -webkit-transform: scale(1.03, 1.03);
    transform: scale(1.03, 1.03); }

  100% {
    visibility: visible;
    opacity: 1;
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); } }


@-webkit-keyframes modalHeadOut {
  0% {
    visibility: visible;
    opacity: 1;
    -moz-transform: translateY(0) scale(1, 1);
    -ms-transform: translateY(0) scale(1, 1);
    -webkit-transform: translateY(0) scale(1, 1);
    transform: translateY(0) scale(1, 1); }

  100% {
    visibility: hidden;
    opacity: 0;
    -moz-transform: translateY(35px) scale(0.97, 0.97);
    -ms-transform: translateY(35px) scale(0.97, 0.97);
    -webkit-transform: translateY(35px) scale(0.97, 0.97);
    transform: translateY(35px) scale(0.97, 0.97); } }


@-moz-keyframes modalHeadOut {
  0% {
    visibility: visible;
    opacity: 1;
    -moz-transform: translateY(0) scale(1, 1);
    -ms-transform: translateY(0) scale(1, 1);
    -webkit-transform: translateY(0) scale(1, 1);
    transform: translateY(0) scale(1, 1); }

  100% {
    visibility: hidden;
    opacity: 0;
    -moz-transform: translateY(35px) scale(0.97, 0.97);
    -ms-transform: translateY(35px) scale(0.97, 0.97);
    -webkit-transform: translateY(35px) scale(0.97, 0.97);
    transform: translateY(35px) scale(0.97, 0.97); } }


@keyframes modalHeadOut {
  0% {
    visibility: visible;
    opacity: 1;
    -moz-transform: translateY(0) scale(1, 1);
    -ms-transform: translateY(0) scale(1, 1);
    -webkit-transform: translateY(0) scale(1, 1);
    transform: translateY(0) scale(1, 1); }

  100% {
    visibility: hidden;
    opacity: 0;
    -moz-transform: translateY(35px) scale(0.97, 0.97);
    -ms-transform: translateY(35px) scale(0.97, 0.97);
    -webkit-transform: translateY(35px) scale(0.97, 0.97);
    transform: translateY(35px) scale(0.97, 0.97); } }
