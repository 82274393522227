.rH {
  .rH-section:not(:first-child) {
    margin-bottom: 40px; }
  .rH-section {
    .rH-section-routines {
      white-space: nowrap;
      overflow-x: scroll;
      .rB {
        margin-bottom: 0; }
      &.list {
        white-space: normal;
        .rB {
          margin-bottom: 15px; } } }
    .subheader {
      opacity: 0.6;
      margin-top: 20px;
      font-size: 14pt; }
    .rH-section-more {
      a {
        display: flex;
        align-items: center;
        img {
          width: 30px;
          height: 30px;
          margin-left: 5px;
          transition: all 0.3s;
          padding-top: 7px;
          padding-bottom: 2px;
          margin-bottom: 2px; } } }
    .rH-section-more:hover {
      img {
        -webkit-transform: translateX(5px);
        -moz-transform: translateX(5px);
        -ms-transform: translateX(5px);
        -o-transform: translateX(5px);
        transform: translateX(5px); } } } }
