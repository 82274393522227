.policies {
  h1 {
    font-weight: 800;
    font-size: 34pt;
    letter-spacing: -1pt;
  }
  h3 {
    margin: 20px 0 50px;
    opacity: 0.5;
    font-size: 16pt;
  }
  p {
    margin: 10px 0;
    line-height: 1.4;
  }
  h2 {
    font-size: 22pt;
    margin: 60px 0 15px;
    letter-spacing: -1pt;
  }
  &.refund-policy {
    h1 {
      margin-bottom: 40px;
    }
  }
}