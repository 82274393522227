$blue: #223bff;
$light_blue: #4cd9f7;
$white: #fff;
$black: #000;
$grey: #595959;
$dark: #242633;

$icon_blue: #86A7F3;
$icon_light_blue: #CCDAFC;

$DARK_MODE_COLOR: #222431;

@import url('/assets/font/inter.css');

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

*:focus {
  outline: none!important; }

::-webkit-scrollbar {
  width: 0; }

html, body {
  background: #fff;
  color: #111;
  font-family: 'inter', sans-serif; }

input, button {
  font-family: 'inter', sans-serif;
  -webkit-appearance: none; }

html, body, h1, h2, h3, h4, h5, h6, p, span, a {
  margin: 0;
  padding: 0;
  font-weight: normal; }
p {
  font-weight: 400;
  font-size: 11pt; }
h2 {
  font-weight: 700;
  font-size: 30pt; }
hr {
  margin: 30px 40px 40px;
  border-color: white;
  opacity: 0.3; }
.small {
  font-size: 0.8rem; }

a {
  text-decoration: none;
  color: inherit;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }

.p-l_40 {
  padding-left: 40px; }
.p-r_40 {
  padding-right: 40px; }
.p-tb_40 {
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100%; }
.p-tb_80 {
  padding-top: 80px;
  padding-bottom: 80px;
  width: 100%; }

.disabledLink {
  pointer-events: none!important; }
.comingSoon {
  background: #406aff;
  padding: 8px 12px;
  border-radius: 10px;
  color: #fff;
  line-height: 1;
  font-size: 11pt;
  font-weight: 700;
  margin-top: 30px;
  display: inline-block; }

.behind {
  z-index: -1;
  bottom: -10px;
  left: 10px;
  position: absolute;
  opacity: 0.3; }

.hidden {
  display: none!important; }
.shown {
  display: block; }
.inline-block {
  display: inline-block; }
.background {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.contain {
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px!important;
  background: rgb(247, 249, 250);
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
  padding: 20px; }


.tooltip {
  position: relative;
  display: block; }
.tooltip:before,
.tooltip:after {
  display: block;
  opacity: 0;
  pointer-events: none;
  position: absolute; }
.tooltip:before {
  background: #d64545;
  border-radius: 5px;
  color: #ffffff;
  content: attr(data-title);
  font-size: 13px;
  padding: 5px 5px 3px;
  top: 0;
  white-space: nowrap; }

.horizontal-scroll {
  list-style-type: none;
  white-space: nowrap;
  overflow-x: auto;
  padding-bottom: 10px;
  padding-top: 10px;
  display: block!important; }

.app-container {
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  .app-right {
    width: calc(100% - 80px); }
  .app-max {
    max-width: 100%;
    min-width: 320px;
    min-height: calc(100% - 70px);
    height: calc(100% - 70px);
    max-height: calc(100% - 70px);
    overflow-y: scroll;
    padding-left: 40px;
    .main-content {
      display: flex;
      position: relative;
      height: 100%;
      margin-top: 70px;
      > .content {
        min-width: 0;
        padding-right: 0;
        display: flex;
        margin-left: 170px;
        width: calc(100% - 170px);
        flex-wrap: wrap;
        .main-container {
          margin-top: 30px;
          padding: 85px 30px 30px;
          min-height: calc(100vh - 30px);
          width: calc(100% - 130px); } } } } }

.app-container.at_join, .app-container.at_on-boarding {
  .app-max {
    height: 100vh;
    max-height: 100vh;
    padding-left: 0;
    .main-content {
      margin-top: 0;
      .content {
        width: 100%;
        margin-left: 0;
        align-items: center;
        justify-content: center; } } } }

.cR-title {
  display: flex;
  align-items: center;
  .r-back svg {
    height: 30px;
    width: 30px;
    cursor: pointer;
    margin-right: 20px; } }

.noLeft {
  .app-max {
    padding-left: 0; }
  .left-sidebar {
    display: none!important; }
  .app-container .app-max .main-content > .content {
    margin-left: 0;
    width: 100%; } }

.page-not-found {
  text-align: center;
  padding-right: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1 {
    font-weight: bold;
    margin-bottom: 30px; }
  a {
    color: #406aff;
    text-transform: capitalize; } }
