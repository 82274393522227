.pB {
  width: 300px;
  height: 200px;
  border-radius: 5px;
  z-index: 1;
  margin: 20px 10px;
  box-shadow: 0 3px 10px 0 rgba(68, 68, 181, 0.3);
  display: inline-block;
  background: white;
  position: relative;
  text-align: left;
  transition: all 0.3s ease;
  background-position: center right;
  background-repeat: no-repeat;
  background-image: url("/assets/program-ready.jpg");
  overflow: hidden;
  &:hover {
    transform: scale(1.02); }
  &.yellow {
    box-shadow: 0 3px 10px 0 rgba(175, 170, 17, 0.3);
    background-image: url("/assets/program-notready.jpg"); }
  .pB-container {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    color: white;
    background: rgba(0, 0, 0, 0.2);
    .made-for {
      font-weight: 700;
      font-size: 9pt;
      opacity: 1;
      font-style: italic; }
    h3 {
      text-transform: capitalize;
      font-weight: 700;
      opacity: 1; }
    p {
      opacity: 0.8;
      margin-top: 10px;
      font-size: 12pt;
      font-weight: 700; } } }



