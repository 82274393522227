.routine-reviews {
  margin-top: 10px;
  .reviews-top {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    h1 {
      font-family: Poppins, sans-serif;
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      font-weight: 500;
      span {
        display: inline-flex;
        padding: 6px 5px 5px;
        border-radius: 500px;
        background: #0e1861;
        align-items: center;
        text-align: center;
        justify-content: center;
        margin-left: 20px;
        font-size: 0.8rem;
        width: 30px;
        height: 30px;
        font-weight: 600; } }
    .rating {
      i {
        margin-left: 5px; }
      .yellow-star {
        color: rgb(241, 233, 36); } } }
  .empty-reviews {
    font-family: Poppins, sans-serif; } }
