.side {
  width: 80px;
  //background: #fff
  height: 100vh;
  padding-bottom: 20px;
  overflow-y: scroll;
  text-align: center;
  border-right: 1px solid #ececec;
  .copyright {
    font-size: 0.75rem;
    color: rgba(255, 255, 255, 0.45); }
  .logo-container {
    height: 70px; }
  .logo {
    height: 40px;
    cursor: pointer;
    margin-top: 16px;
 }    //filter: brightness(0) invert(1)
  .other-menu {
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    a {
      margin-right: 5px;
      font-size: 0.75rem;
      color: #939ff7;
      line-height: 1rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease; }
    a:after {
      content: "\B7";
      font-size: 1.2rem;
      padding-left: 5px; }
    > a:last-child:after {
      content: ""; }
    a:hover {
      color: white; }
    a:hover:after {
      color: #787998; } }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 50px;
    margin-bottom: 30px;
    li {
      margin-bottom: 25px;
      height: 42px;
      overflow: hidden;
      .soon {
        position: absolute;
        color: white;
        font-size: 7pt;
        background: #ff426e;
        border-radius: 20px;
        padding: 5px;
        z-index: 2;
        text-transform: capitalize; }
      .link {
        position: relative;
        display: block;
        height: 32px;
        svg {
          fill: #111;
          height: 32px;
          width: 32px;
          transition: all 0.3s ease; }
        > p {
          font-size: 8.5pt;
          align-items: center;
          color: #3640ff;
          opacity: 0;
          transition: all 0.3s ease;
          padding: 0 10px; } }
      a.activeLink::after {
        content: '';
        position: absolute;
        width: 10px;
        height: 2px;
        background: #405bff;
        bottom: -4px;
        border-radius: 500px;
        left: calc(50% - 5.5px);
        box-shadow: 0px 0px 3px 0px rgba(39, 65, 255, 0.7); } }
    #nav-cart .hasCart::before {
      content: '';
      position: absolute;
      width: 4.2px;
      height: 4.3px;
      background: #f9355e;
      top: 3px;
      border-radius: 500px;
      right: 30%;
      box-shadow: 0px 0px 5px 0px #f9355e; }

    li:hover {
      a {
        svg {
          transform: translateY(-32px);
          opacity: 0; }
        > p {
          transform: translateY(-28px);
          opacity: 1; } } } } }
