.shop {
  header {
    border-bottom: 1px solid #ececec;
    padding: 50px;
    box-shadow: 0 3px 10px #ececec;
    margin-left: -40px;
    margin-right: -40px;
    margin-top: -40px;
    .title {
      text-align: center;
      font-size: 30pt;
      margin: 0 auto 25px;
    }
    p {
      font-size: 16pt;
      text-align: center;
      opacity: 0.8;
      max-width: 500px;
      margin: 0 auto;
      line-height: 1.4;
    }
  }
  
  .shop-items {
    white-space: nowrap;
    overflow-x: scroll;
    margin-bottom: 80px;
    margin-right: -40px;
    margin-left: -40px;
    padding-left: 40px;
    padding-top: 60px;
    p {
      font-size: 14pt;
      text-align: center;
      width: 100%;
    }
    .item {
      margin-right: 30px;
      margin-bottom: 30px;
      display: inline-block;
      .img-block {
        width: 300px;
        height: 300px;
        background: white;
        border-radius: 5px;
        display: block;
        padding: 25px;
        border: 2px solid #4a60ff;
        box-shadow: 0 0 10px 0 rgba(68, 68, 181, 0.3);
        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
        &:hover {
          transform: scale(1.03);
        }
      }
      h4 {
        margin-top: 20px;
        font-weight: 700;
        font-size: 14pt;
      }
    }
  }
}