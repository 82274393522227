.loader {
  position: absolute;
  top: calc(50% - 56px);
  left: calc((50% - 44px)); }

.line {
  animation: expand 1s ease-in-out infinite;
  border-radius: 50px;
  display: inline-block;
  transform-origin: center center;
  margin: 0 3px;
  width: 5px;
  height: 35px; }


.line:nth-child(1) {
  background: #4258ff; }


.line:nth-child(2) {
  animation-delay: 180ms;
  background: #3643ff; }


.line:nth-child(3) {
  animation-delay: 360ms;
  background: #4258ff; }


.line:nth-child(4) {
  animation-delay: 540ms;
  background: #3643ff; }

.section-loader {
  .line {
    height: 5px;
    margin: 0 4px; } }

@keyframes expand {
  0% {
    transform: scale(1); }

  25% {
    transform: scale(2); } }
