.community {
  font-family: Poppins, sans-serif;
  .community-top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .ask-button, .category-menu {
      background-color: #1a2dc2;
      font-size: 1rem;
      padding: 6px 15px;
      cursor: pointer;
      border-radius: 5px;
      text-align: center;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      font-weight: 500;
      img {
        margin-left: 5px; } }
    .community-filters {
      display: flex;
      position: relative;
      p {
        margin-right: 20px;
        font-size: 0.95rem;
        font-weight: 500;
        cursor: pointer;
        position: relative; }
      > p:last-of-type {
        margin-right: 0; } }
    .community-filters:after,
    .community-filters p:after {
      width: 100%;
      height: 6px;
      content: '';
      position: absolute;
      bottom: -10px;
      border-radius: 5px;
      left: 0; }
    .community-filters:after {
      background: #1a2ec7; }
    .community-filters p:after {
      background: white;
      z-index: 1;
      height: 6px;
      opacity: 0;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease; }
    .community-filters p.active-filter:after {
      opacity: 1; } }
  .community-feed {
    margin-top: 60px;
    .feed-block {
      background: rgba(0, 0, 0, 0.12);
      border-radius: 5px;
      padding: 10px 15px 15px;
      margin-bottom: 15px;
      position: relative;
      -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
      .feed-block-top {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        p {
          font-weight: 500;
          font-size: 1.2rem; } }
      .feed-block-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .feed-block-tags {
          margin: 0; }
        .feed-block-info {
          display: flex;
          align-items: center;
          div {
            display: flex;
            align-items: center; }
          div:first-of-type {
            margin-right: 20px; }
          p {
            display: inline-block;
            margin-left: 6px;
            font-weight: 600;
            font-size: 0.8rem; } }
        .feed-block-posted {
          display: flex;
          align-items: center;
          p {
            font-size: 0.7rem;
            opacity: 0.8;
            display: inline-block;
            margin-right: 10px; }
          img {
            border-radius: 500px;
            height: 30px;
            width: 30px;
            margin-bottom: 3px; } } } } } }
