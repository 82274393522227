.progress-bar {
  width: 100%;
  height: 7px;
  background: #3366ff;
  border-radius: 10px;
  -webkit-box-shadow: 0px 7px 20px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 7px 20px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 7px 20px 2px rgba(0, 0, 0, 0.05);
  position: absolute;
  top: 0;
  left: 0;
  .progress-bar-completed {
    height: 7px;
    background: #d6b85c;
    border-radius: 10px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; } }
