.blog {
  .blog-filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 80px; }
  .blog-articles {
    .article-block-wrapper {
      width: 100%;
      position: relative;
      margin-bottom: 25px;
      border-radius: 5px;
      padding: 0;
      @media (min-width: 1100px) {
        width: calc(50% - 25px);
        margin-right: 12.5px;
        margin-left: 12.5px; } }
    .article-block-wrapper:nth-child(even) {
      @media (min-width: 1100px) {
 } }        //margin-left: 5px
    .article-block-img {
      opacity: 0.8;
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      width: 100%; }
    .article-block-type {
      position: absolute;
      top: 10px;
      left: 10px;
      background: rgba(255, 255, 255, 0.6);
      border-radius: 5px;
      padding: 5px; }
    .article-block {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      flex-direction: column;
      padding: 30px;
      font-family: Poppins, san-serif;
      p {
        font-size: 0.875rem;
        line-height: 1.4;
        margin-bottom: 15px;
        opacity: 0.8; }
      small {
        font-size: 0.8rem;
        line-height: 1.1rem;
        opacity: 0.8;
        .blog-content {
          display: inline;
          p {
            display: inline; } } }
      h3 {
        line-height: 2rem;
        font-size: 1.7rem;
        margin-bottom: 12px;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        &:hover {
          text-decoration: underline; } }
      .article-block-date {
        display: block; } } } }
