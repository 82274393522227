.j {
  width: 800px;
  .j-top {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 20px;
    img {
      max-height: 50px; }
    ul {
      display: flex;
      align-items: center;
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        margin-left: 30px;
        font-size: 10pt; } } }
  .j-container {
    border-radius: 10px;
    background: #4a60ff;
    width: 100%;
    max-width: 800px;
    height: 380px;
    margin-bottom: 30px;
    box-shadow: 0px 10px 20px 0px rgba(85, 68, 255, 0.26);
    display: flex;
    color: white;
    .login-signin {
      height: 100%;
      width: 63%;
      padding: 80px 120px;
      text-align: center;
      background: #3b4fe0;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      h2 {
        font-size: 1.4rem;
        line-height: 2.2rem;
        margin-bottom: 40px;
        span {
          color: #ffcd22; } }
      .signin-email {
        border-radius: 500px;
        background: #e5eaff;
        padding: 15px 7px;
        cursor: pointer;
        margin-bottom: 10px;
        p {
          color: #1d33cd;
          font-size: 1rem; } }
      .signup-mobile {
        display: none;
        border-radius: 500px;
        background: #1c3388;
        padding: 15px 7px;
        cursor: pointer;
        margin-top: 15px;
        margin-bottom: 30px;
        p {
          color: white;
          font-size: 1rem; } }
      .facebook-login {
        border-radius: 500px;
        background: #5165f5;
        padding: 8px 7px 7px;
        cursor: pointer;
        margin-bottom: 8px;
        font-size: 0.95rem;
        width: 100%;
        border: 0;
        color: #dbe1fd; }
      .fb-disclaimer {
        font-size: 0.8rem;
        color: #ccd1ff; }
      .modal {
        position: relative;
        a {
          text-decoration: underline;
          position: absolute;
          bottom: 20px;
          right: 25px;
          color: white;
          font-size: 9.5pt;
          opacity: 0.6; } } }
    .login-signup {
      background: #406aff;
      height: 100%;
      width: 37%;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      overflow: hidden;
      position: relative;
      padding: 15px;
      .signin-mobile {
        display: none;
        position: absolute;
        top: 20px;
        left: calc(50% - 50px);
        z-index: 2; }
      .step {
        position: absolute;
        margin: 35px;
        width: 215px;
        top: 0;
        left: 0;
        bottom: 0;
        transition: all 0.3s ease; }
      #step-1 {
        text-align: center;
        p {
          line-height: 1.2rem;
          font-weight: 600; }
        h2 {
          margin: 20px 0;
          font-size: 16pt; }
        div.button-raise {
          display: block;
          width: 80%;
          margin: 10px auto;
          background: transparent;
          color: white;
          border-radius: 500px;
          padding: 7px 5px 5px;
          font-size: 0.9rem;
          border: 1px solid;
          border-color: rgba(255, 255, 255, 0.30); }
        div.button-raise.active, div.button-raise:hover {
          border-color: white; }
        div.button-raise.chosen {
          border-color: rgb(33, 45, 171);
          background: rgba(0, 0, 0, 0.25); } }
      #step-2 {
        left: 285px;
        text-align: center;
        h2 {
          display: flex;
          align-items: center;
          line-height: 0;
          justify-content: center;
          font-size: 16pt;
          img {
            margin-right: 10px;
            cursor: pointer;
            width: 28px;
            height: 28px; } }
        p {
          margin: 15px 0 30px;
          font-size: 0.8rem; }
        input {
          display: block;
          width: 80%;
          margin: 10px auto;
          background: transparent;
          color: white;
          border-radius: 500px;
          padding: 7px 5px 5px;
          font-size: 1rem;
          border: 1px solid rgba(255, 255, 255, 0.3);
          text-align: center;
          transition: all 0.3s ease;
          -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1); }
        input::placeholder {
          color: rgba(255, 255, 255, 0.6); }
        input.active, input:hover, input:focus {
          border-color: white; }
        button {
          height: auto;
          font-size: 1rem;
          width: 80%;
          border-radius: 500px;
          padding: 7px 5px 7px;
          margin-top: 6px;
          background-color: #3b4fe0;
          border: 0;
          color: white;
          cursor: pointer; }
        button.disabled {
          color: rgba(255, 255, 255, 0.38);
          background-color: rgba(51, 64, 206, 0.5);
          cursor: default;
          -ms-transform: none!important;
          -webkit-transform: none!important;
          transform: none!important;
          -webkit-box-shadow: none!important;
          box-shadow: none!important; } }
      #step-3 {
        left: 570px;
        h2 {
          display: flex;
          align-items: center;
          line-height: 0;
          margin-bottom: 20px;
          font-size: 16pt;
          justify-content: center;
          img {
            margin-right: 10px;
            cursor: pointer;
            width: 28px;
            height: 28px; } }
        p {
          margin: 3px 0 10px;
          font-size: 0.65rem;
          text-align: center;
          opacity: 0.7; }
        input[name='name'] {
          text-transform: capitalize; }
        input {
          display: block;
          width: 100%;
          margin: 7px auto;
          background: transparent;
          color: white;
          border-radius: 5px;
          padding: 6px 10px 4px;
          font-size: 0.9rem;
          border: 1px solid rgba(255, 255, 255, 0.3);
          transition: all 0.3s ease;
          -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1); }
        input::placeholder {
          color: rgba(255, 255, 255, 0.6); }
        input.active, input:hover, input:focus {
          border-color: white; }
        input.error {
          border-color: #f13333; }
        button {
          font-size: 1rem;
          width: 100%;
          height: auto;
          padding: 10px 10px;
          border: 0;
          border-radius: 500px;
          cursor: pointer;
          background-color: #fff; }
        .tooltip.fade:after,
        .tooltip.fade:before {
          transform: translate3d(0,-10px,0);
          transition: all .15s ease-in-out; }
        .tooltip.fade.showTooltip:after,
        .tooltip.fade.showTooltip:before {
          opacity: 1;
          transform: translate3d(0,-25px,0); } }

      .step-indicators {
        position: absolute;
        bottom: 10px;
        text-align: center;
        display: block;
        width: calc(100% - 30px);
        .indicator {
          height: 8px;
          width: 8px;
          background: white;
          border-radius: 500px;
          display: inline-block;
          margin: 0 3px;
          opacity: 0.2; }
        .indicator.active {
          opacity: 1; } } } }

  // MODAL
  .modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: #000;
    opacity: 0;
    visibility: hidden;
    z-index: 40;
    -moz-transition: opacity 0.25s ease 0s, visibility 0.35s linear;
    -o-transition: opacity 0.25s ease 0s, visibility 0.35s linear;
    -webkit-transition: opacity 0.25s ease, visibility 0.35s linear;
    -webkit-transition-delay: 0s, 0s;
    transition: opacity 0.25s ease 0s, visibility 0.35s linear;
    &.state-show {
      opacity: .7;
      visibility: visible;
      -moz-transition-delay: 0s;
      -o-transition-delay: 0s;
      -webkit-transition-delay: 0s;
      transition-delay: 0s;
      -moz-transition-duration: 0.2s, 0s;
      -o-transition-duration: 0.2s, 0s;
      -webkit-transition-duration: 0.2s, 0s;
      transition-duration: 0.2s, 0s; } }

  .modal-frame {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 50; } }
